const flangeSelection = {
  designTemperature: "",
  designTemperaturemin: "-29",
  designPressure: "",
  designPressureExt: "FV",
  elementDiameter: "",
  FlangeMaterial: "",
  FlangeMaterialCode: "",
  flangeSelection: "",
  MaterialGrade: "",
  FlangeClass: "",
  maxPressure: "",
  schedule: "",
  flangeSize: "",
  innerDiameter: "",
  terminalBoxSize: "",
  outerTubeLimitDiameter: "",
  totalHoles: "",
  pitch: "",
  baffleOD: "",
  baffleHolesOD: "",
  lastHoleCtoC: "",
  centreLine: "",
  firstLine: "",
  secondLine: "",
  thirdLine: "",
  fourthLine: "",
  fifthLine: "",
  sixthLine: "",
  seventhLine: "",
  eighthLine: "",
  ninthLine: "",
  tenthLine: "",
  eleventhLine: "",
  twelfthLine: "",
  tubesheetsize: "",
  shellThickness: "",
  mannualThickness: "",
  baffleWeight: "",
  gaskets: "",
  // minimumBendingDiameter: "",
  // thirtyt: "",
  // fortyfives: "",
  // sixtyt: "",
  // ninetys: "",
};

export default flangeSelection;
