import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";

function PriceEdit(props) {
  const { id } = useParams();
  const [priceName, setPriceName] = useState("");
  const [price, setPrice] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchPriceData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/bom-prices/${id}`);
      if (response.status === 200) {
        const data = response.data.data;
        setPriceName(data?.name);
        setPrice(data?.price);
      }
    } catch (error) {
      showErrorToast("Error fetching price data");
      navigate("/price");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPriceData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!priceName.trim() || !price.trim()) {
      showErrorToast("Item Name and Item Price cannot be empty");
      return;
    }

    setIsLoading(true);

    try {
      await axiosPrivate.put(`/bom-prices/${id}`, {
        name: priceName,
        price: price,
      });
      showSuccessToast("Item updated successfully");
      navigate("/price");
    } catch (error) {
      showErrorToast("Error updating Item ");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit Price</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mb-30">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Item Name"
                            value={priceName}
                            onChange={(e) => setPriceName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Item Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/price">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceEdit;
