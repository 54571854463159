import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import { useState } from "react";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";

const Dashboard = () => {
  // Retrieve user data from local storage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const navigate = useNavigate();

  // State variables for count and recent requests
  const [count, setCount] = useState();
  const { isLoading, setIsLoading } = useLoader();

  // Function to fetch count data
  const fetchCount = async () => {
    // try {
    //   setIsLoading(true); // Set isLoading to true before fetching data
    //   const response = await axiosPrivate.get("count");
    //   if (response.status === 200) {
    //     setCount(response.data?.data);
    //   }
    // } catch (error) {
    //   if (error.response?.status !== 404) {
    //     showErrorToast(error.message);
    //   }
    // } finally {
    //   setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    // }
  };

  useEffect(() => {
    // Fetch count data only if userData is available
    if (userData && userData.userId && userData.userName) {
      fetchCount();
    }
  }, []);

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          {/* Main content */}
          <div className="content pt-3 pr-3 pl-3">
            {/* Additional sections */}
            <div
              className="section-body"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <div className="container-fluid">
                <div className="row clearfix row-deck">
                  {/* Section for Jobs */}
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="card-body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default Dashboard;
