import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";

const SchedulesEdit = (props) => {
  const { id } = useParams();
  const [ScheduleName, setScheduleName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [flangesize, setFlangeSize] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchScheduleData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/schedules/${id}`);
      if (response.status === 200) {
        const data = response.data.data[0];
        setScheduleName(data?.name || "");
        setSchedule(data?.sch || "");
        setFlangeSize(data?.flange_size || "");
      }
    } catch (error) {
      showErrorToast("Error fetching schedule data");
      //   navigate('/schedules');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchScheduleData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!ScheduleName.trim() || !schedule.trim()) {
      showErrorToast("Schedule Name and Schedule cannot be empty");
      return;
    }

    setIsLoading(true);

    try {
      await axiosPrivate.put(`/schedules/${id}`, {
        name: ScheduleName,
        sch: schedule,
        flange_size: flangesize,
      });
      showSuccessToast("Schedule updated successfully");
      navigate("/schedules");
    } catch (error) {
      showErrorToast("Error updating schedule");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit Schedule</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mb-30">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Schedule Name"
                            value={ScheduleName}
                            onChange={(e) => setScheduleName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Schedule"
                            value={schedule}
                            onChange={(e) => setSchedule(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Flange Size"
                            value={flangesize}
                            onChange={(e) => setFlangeSize(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/schedules">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SchedulesEdit;
