import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
const headers = [
  { name: "Role", field: "name", sortable: true },
  { name: "Created at", field: "created_at", sortable: false },
  { name: "ACTION", field: "action", sortable: false },
];

const Roles = () => {
  const [rolesData, setRolesData] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const { isLoading, setIsLoading } = useLoader();
  const { authPermissions } = usePermissions();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    if (roleId) {
      setDeleteConfirmationVisible(true);
    }
  }, [roleId]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("roles");
      if (response.status === 200) {
        setRolesData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const roleDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`roles/${id}`);
      if (response.status === 200) {
        showSuccessToast("Role deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setRoleId(null);
    }
  };

  const rolesListData = rolesData.map((value, index) => {
    const buttons = [];

    if (authPermissions?.includes("Roles-Update")) {
      buttons.push(
        <Link
          key={`editButton_${value.id}`}
          to={`/roles/edit/${value.id}`}
          className="btn btn-link"
          style={{ border: "none", background: "none", padding: "6px" }}
          title="Edit"
        >
          <FaEdit color="green" size={13} />
        </Link>
      );
    }

    if (authPermissions?.includes("Roles-Update")) {
      buttons.push(
        <Link
          key={`userButton_${value.id}`}
          to={`/roles/permissions/${value.id}`}
          className="btn btn-link"
          style={{ border: "none", background: "none", padding: "6px" }}
          title="Permission"
        >
          <FaUserAlt color="#232323" size={13} />
        </Link>
      );
    }

    // if (authPermissions?.includes("Roles-Delete")) {
    //   buttons.push(
    //     <button
    //       key={`deleteButton_${value.id}`}
    //       onClick={() => roleDeleteHandler(value.id)}
    //       title='Delete'
    //       style={{ border: "none", background: "none", padding: "6px" }}
    //     >
    //       <FaTrash
    //         color='red'
    //         size={13}
    //       />
    //     </button>
    //   );
    // }

    return {
      ...value,
      index: index + 1,
      action: buttons.length > 0 ? buttons : "-",
    };
  });

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        {authPermissions?.includes("Roles-Create") && (
          <Link to="add" className="text-decoration-none ">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fe fe-plus mr-2" />
              Add
            </button>
          </Link>
        )}
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const header = renderHeader();

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type="button"
        onClick={() => {
          setRoleId(rowData.id);
        }}
        title="Delete"
        style={{
          border: "none",
          background: "none",
          padding: "6px",
          textAlign: "left",
        }}
      >
        <FaTrash color="red" size={13} />
      </button>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="content">
            <div className="page-header">
              <h4 className="m-1 pl-3">Roles</h4>
            </div>
            <div className="card">
              <div className="card-body">
                <DataTable
                  value={rolesListData}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                >
                  <Column
                    field="index"
                    header="Sr. No."
                    bodyStyle={{ textAlign: "left" }}
                    style={{ width: "8%" }}
                    sortable
                  />

                  <Column field="name" header="Role" sortable />
                  <Column field="created_at" header="Created At" sortable />
                  <Column
                    field="action"
                    header="Action"
                    // headerStyle={{ width: "8%", paddingLeft: "45px" }}
                    // bodyStyle={{ textAlign: "right" }}
                    sortable
                  />
                  {/* <Column
                    field='delete_handler'
                    body={deleteBodyTemplate}
                    headerStyle={{ width: "10%" }}
                    bodyStyle={{ textAlign: "left" }}
                  /> */}
                </DataTable>
              </div>
            </div>
          </div>

          {/* Confirmation dialog for document deletion */}
          <Dialog
            visible={deleteConfirmationVisible}
            onHide={() => {
              setDeleteConfirmationVisible(false);
              setRoleId(null);
            }}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="p-button-text mr-2"
                  onClick={() => {
                    setRoleId(null);
                    setDeleteConfirmationVisible(false);
                  }}
                />
                <Button
                  label="Delete"
                  className="p-button-danger"
                  onClick={() => {
                    roleDeleteHandler(roleId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this Role?</p>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Roles;
