import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const AddUnit = () => {
  const [unitName, setUnitName] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation check
    if (!unitName.trim()) {
      showErrorToast("Unit Name cannot be empty");
      return;
    }

    setIsLoading(true);

    axiosPrivate
      .post("/units", { name: unitName })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("Unit added successfully"); // Show success toast on successful addition
        navigate("/units"); // Navigate to the units listing page after successful addition
      })
      .catch((error) => {
        console.error(`Error adding unit: ${error}`);
        setIsLoading(false);
        showErrorToast("Error adding unit"); // Show error toast on failure
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add Unit</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex">
                        <div className="w-100 mr-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="Unit Name"
                            value={unitName}
                            onChange={(e) => setUnitName(e.target.value)}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <Link to="/units">
                            <button
                              type="button"
                              className="btn btn-secondary mr-3"
                            >
                              Close
                            </button>
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddUnit;
