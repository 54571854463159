import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Check } from "@mui/icons-material";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const UnitsList = () => {
  const [units, setUnits] = useState([]);
  const [editingUnitId, setEditingUnitId] = useState(null);
  const [newUnitName, setNewUnitName] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [unitId, setUnitId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/units");
      if (response.status === 200) {
        setUnits(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (unitId) => {
    // Check if newUnitName is empty
    if (!newUnitName.trim()) {
      showErrorToast("Unit Name cannot be empty");
      return;
    }

    setIsLoading(true);
    try {
      await axiosPrivate.put(`/units/${unitId}`, { name: newUnitName });
      setUnits(
        units.map((unit) =>
          unit.id === unitId ? { ...unit, name: newUnitName } : unit
        )
      );
      setEditingUnitId(null);
      showSuccessToast("Unit updated successfully");
    } catch (error) {
      showErrorToast(`Error updating unit: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (unitId) => {
    try {
      const response = await axiosPrivate.delete(`/units/${unitId}`);
      if (response.status === 200) {
        setUnits(units.filter((unit) => unit.id !== unitId));
        showSuccessToast("Unit deleted successfully");
      }
    } catch (error) {
      showErrorToast(`Error deleting unit: ${error.message}`);
    } finally {
      setUnitId(null);
    }
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <Link to="/units/add" className="text-decoration-none">
          <button type="button" className="btn btn-primary">
            <i className="fe fe-plus mr-2" />
            Add
          </button>
        </Link>
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const editBodyTemplate = (rowData) => {
    return editingUnitId === rowData.id ? (
      <InputText
        value={newUnitName}
        onChange={(e) => setNewUnitName(e.target.value)}
        autoFocus
        className="p-inputtext-sm w-100 mb-2 mt-2"
      />
    ) : (
      rowData.name
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        {editingUnitId === rowData.id ? (
          <Check
            style={{ cursor: "pointer", fontSize: "25px", color: "green" }}
            onClick={() => handleSave(rowData.id)}
          />
        ) : (
          <FaEdit
            color="green"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              setEditingUnitId(rowData.id);
              setNewUnitName(rowData.name);
            }}
          />
        )}
        <FaTrash
          color="red"
          style={{ cursor: "pointer", fontSize: "20px", marginLeft: "10px" }}
          onClick={() => {
            setUnitId(rowData.id);
            setDeleteConfirmationVisible(true);
          }}
        />
      </div>
    );
  };

  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Units</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={units}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters.global.value}
                  emptyMessage="No units found"
                >
                  <Column
                    body={serialNumberBodyTemplate}
                    header="S.No"
                    headerStyle={{ width: "10%" }}
                  />
                  <Column
                    field="name"
                    header="Unit Name"
                    body={editBodyTemplate}
                  />
                  <Column
                    field="action"
                    header="Actions"
                    body={actionBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            draggable={false}
            visible={deleteConfirmationVisible}
            onHide={() => setDeleteConfirmationVisible(false)}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setDeleteConfirmationVisible(false);
                    setUnitId(null);
                  }}
                />
                <Button
                  label="Delete"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(unitId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this unit?</p>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default UnitsList;
