import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const ProcessFluidEdit = () => {
  const { id } = useParams();
  const [fluidName, setFluidName] = useState("");
  const [fluidValue, setFluidValue] = useState("");
  const [viscosity, setViscosity] = useState("");
  const [molecularWeight, setMolecularWeight] = useState("");
  const [thermalConductivity, setThermalConductivity] = useState("");
  const [density, setDensity] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFluid = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.get(`/process-fluids/${id}`);
        if (response.status === 200) {
          const fluid = response.data.data[0];
          setFluidName(fluid.name || "");
          setFluidValue(fluid.initial_value || "");
          setViscosity(fluid.viscosity || "");
          setMolecularWeight(fluid.molecular_weight || "");
          setThermalConductivity(fluid.thermal_conductivity_in_c || "");
          setDensity(fluid.density_kg_m3 || "");
        }
      } catch (error) {
        console.error(`Error fetching process fluid: ${error}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFluid();
  }, [id, setIsLoading]);

  const handleSave = (e) => {
    e.preventDefault();
    if (
      !fluidName.trim() ||
      !fluidValue.trim() ||
      !viscosity.trim() ||
      !molecularWeight.trim() ||
      !thermalConductivity.trim() ||
      !density.trim()
    ) {
      showErrorToast("All fields are required.");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .put(`/process-fluids/${id}`, {
        name: fluidName,
        initial_value: fluidValue,
        viscosity: viscosity,
        molecular_weight: molecularWeight,
        thermal_conductivity_in_c: thermalConductivity,
        density_kg_m3: density,
      })
      .then((response) => {
        showSuccessToast("Process fluid updated successfully");
        setIsLoading(false);
        // In handleSave of ProcessFluidEdit
        navigate("/process-fluids", { state: { isUpdated: true } });
      })
      .catch((error) => {
        showErrorToast("Error updating process fluid");
        console.error(`Error updating process fluid: ${error}`);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit Process Fluid</h4>
          </div>
          <div className="container-fluid">
            <div className="">
              <div className="">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSave}>
                      <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Fluid Name"
                            value={fluidName}
                            onChange={(e) => setFluidName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Fluid Value"
                            value={fluidValue}
                            onChange={(e) => setFluidValue(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Viscosity"
                            value={viscosity}
                            onChange={(e) => setViscosity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Molecular Weight"
                            value={molecularWeight}
                            onChange={(e) => setMolecularWeight(e.target.value)}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Thermal Conductivity (in C)"
                            value={thermalConductivity}
                            onChange={(e) =>
                              setThermalConductivity(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Density (kg/m³)"
                            value={density}
                            onChange={(e) => setDensity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/process-fluids">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProcessFluidEdit;
