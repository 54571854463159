import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";

function PriceAdd() {
  const [priceName, setPriceName] = useState("");
  const [price, setPrice] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!priceName.trim() || !price.trim()) {
      showErrorToast("Item Name and Item price cannot be empty");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .post("/bom-prices", { name: priceName, price: price })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("Item added successfully");
        navigate("/price");
      })
      .catch((error) => {
        console.error(`Error adding Item: ${error}`);
        showErrorToast("Error adding Item");
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add Price</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <div className="d-flex mb-30">
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Item Name"
                              value={priceName}
                              onChange={(e) => setPriceName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Item Price"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Link to="/price">
                            <button
                              type="button"
                              className="btn btn-secondary mr-3"
                            >
                              Close
                            </button>
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PriceAdd;
