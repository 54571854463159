import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HeaterBundleWithoutVessel from "./HeaterBundleWithoutVessel";
import HeaterBundleWithVessel from "./HeaterBundleWithVessel";

function Screen7({ formik }) {
  const [template, setTemplate] = useState("");
  const [data, setData] = useState({ name: "John Doe" });
  const templateDir = "../../assets/heaterbundle.html";

  const tableParentDiv = {
    display: "flex",
    margin: "10px 0px",
    gap: "10px",
  };

  const tableClass = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const trClass = {
    height: "22px",
  };

  const tdClass = {
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await fetch(templateDir);
      const text = await response.text();
      setTemplate(text);
    };
    fetchTemplate();
  }, []);

  const replaceVariables = (html, data) => {
    const regex = /\{\{([^}]+)\}\}/g;
    return html.replace(regex, (match, variable) => data[variable] || "");
  };

  const printPDF = (elementId) => {
    const input = document.getElementById(elementId);
    const scale = 2 / window.devicePixelRatio;

    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  const renderedHtml = replaceVariables(template, data);

  return (
    <>
      {formik?.values?.vesselSelection === "no" ? (
        <div>
          <button
            className="btn btn-outline-danger"
            style={{ margin: "10px 70px" }}
            onClick={(e) => {
              e.preventDefault();
              printPDF("no-vessel"); // Use "no-vessel" for no vessel selection
            }}
          >
            Print PDF
          </button>
          <div id="no-vessel" style={{ color: "black", padding: "80px 70px" }}>
            <HeaterBundleWithoutVessel
              formik={formik}
              trClass={trClass}
              tdClass={tdClass}
              tableClass={tableClass}
              tableParentDiv={tableParentDiv}
            />
          </div>
        </div>
      ) : formik?.values?.vesselSelection === "yes" ? (
        <div>
          <button
            className="btn btn-outline-danger"
            style={{ margin: "10px 70px" }}
            onClick={(e) => {
              e.preventDefault();
              printPDF("yes-vessel"); // Use "yes-vessel" for yes vessel selection
            }}
          >
            Print PDF
          </button>
          <div id="yes-vessel" style={{ color: "black", padding: "80px 70px" }}>
            <HeaterBundleWithVessel
              formik={formik}
              trClass={trClass}
              tdClass={tdClass}
              tableClass={tableClass}
              tableParentDiv={tableParentDiv}
            />
          </div>
          <hr />
        </div>
      ) : (
        <h1>Please Select first </h1>
      )}
    </>
  );
}

export default Screen7;
