import Select from "react-select";
import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";

function Screen5({ formik, isPageComplete, handleFlangeThickness }) {
  const [totalCount, setTotalCount] = useState(4);
  const [actualCount, setActualCount] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [materialCode, setMaterialCode] = useState([]);
  const [materialCodeMapping, setMaterialCodeMapping] = useState({});
  const [vesselMaterialCode, setVesselMaterialCode] = useState({});
  const [reducers, setReducers] = useState({});
  const [loadingMaterials, setLoadingMaterials] = useState(true);

  const handleInputValue = (value, count) => {
    if (value === "" || value === undefined) {
      return count > 0 ? count - 1 : count;
    } else {
      return count + 1;
    }
  };

  const fetchMaterials = async () => {
    try {
      const response = await axiosPrivate.get("/material-grade");
      if (response.status === 200) {
        const data = response?.data?.data;
        if (data.length > 0) {
          const materialCodeMapping = data.reduce((acc, item) => {
            if (!acc[item.materials]) {
              acc[item.materials] = new Set();
            }
            acc[item.materials].add(item.materials_code);
            return acc;
          }, {});
          const uniqueMaterials = Array.from(
            new Set(data.map((item) => item.materials))
          );
          if (uniqueMaterials.length > 0) {
          const materialsOptions = uniqueMaterials.map((material) => ({
            value: material,
            label: material,
          }));
          setMaterials(materialsOptions);
          setMaterialCodeMapping(materialCodeMapping);
          }
        } else {
          setMaterials([]);
          setMaterialCodeMapping([]);
        }
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setLoadingMaterials(false);
    }
  };

  // Fetch vessel material codes and reducers based on form values
  const fetchVesselMaterialCodeAndReducers = async () => {
    try {
      const data = {
        particulars: formik?.values?.vesselMaterial,
      };

      const response = await axiosPrivate.post(
        "/get/material-designations",
        data
      );
      if (response.status === 200) {
        const responseData = response.data.data;
        setVesselMaterialCode(() => {
          return responseData
            .filter((item) => item.forgings !== "" && item.forgings !== "-")
            .map((item) => {
              return { value: item.forgings, label: item.forgings };
            });
        });

        setReducers(() => {
          return responseData
            .filter((item) => item.reducers !== "" && item.reducers !== "-")
            .map((item) => {
              return { value: item.reducers, label: item.reducers };
            });
        });
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  // Handle material selection change
  const handleMaterialSelect = (selectedMaterial) => {
    const materialCodes = Array.from(
      materialCodeMapping[selectedMaterial.value] || []
    ).map((materialCode) => ({
      value: materialCode,
      label: materialCode,
    }));
    setMaterialCode(materialCodes);
  };

  // Effect to fetch materials when the component mounts
  useEffect(() => {
    fetchMaterials();
  }, []);

  // Effect to calculate actual count
  useEffect(() => {
    if (formik.values.vesselSelection === "yes") {
      let count = 0;
      count = handleInputValue(formik?.values?.vesselrangetype, count);
      count = handleInputValue(formik?.values?.vesselMaterial, count);
      count = handleInputValue(formik?.values?.vesselFlangePipeSize, count);
      count = handleInputValue(formik?.values?.standard, count);
      setActualCount(count);
    } else {
      setActualCount(0); // Reset count when vesselSelection is "no"
    }
  }, [
    formik.values.vesselSelection,
    formik.values.vesselrangetype,
    formik.values.vesselMaterial,
    formik.values.vesselFlangePipeSize,
  ]);

  // Effect to fetch vessel material codes and reducers when conditions are met
  useEffect(() => {
    if (formik.values.vesselSelection === "yes" && !loadingMaterials) {
      if (formik.values.vesselMaterial) {
        fetchVesselMaterialCodeAndReducers();
      }
    }
  }, [formik.values.vesselMaterial, loadingMaterials]);

  const vesselRangeTypeOptions = [
    { value: "sorf", label: "SORF" },
    { value: "wnrf", label: "WNRF" },
    { value: "rtjf", label: "RTJF" },
    { value: "lap_joint", label: "Lap Joint" },
    { value: "threaded", label: "Threaded" },
  ];

  const vesselFlangeSizeOptions = [
    { value: "1/2", label: "1/2" },
    { value: "3/4", label: "3/4" },
    { value: "1", label: "1" },
    { value: "1 1/4", label: "1 1/4" },
    { value: "1 1/2", label: "1 1/2" },
    { value: "2", label: "2" },
    { value: "2 1/2", label: "2 1/2" },
    { value: "3", label: "3" },
    { value: "3 1/2", label: "3 1/2" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "12", label: "12" },
    { value: "14", label: "14" },
    { value: "16", label: "16" },
    { value: "18", label: "18" },
    { value: "20", label: "20" },
    { value: "24", label: "24" },
    { value: "26", label: "26" },
    { value: "28", label: "28" },
    { value: "30", label: "30" },
    { value: "32", label: "32" },
    { value: "34", label: "34" },
    { value: "36", label: "36" },
    { value: "38", label: "38" },
    { value: "40", label: "40" },
  ];

  useEffect(() => {
    if (formik.values.vesselSelection === "yes") {
      isPageComplete(5, totalCount, actualCount);
    } else {
      isPageComplete(5, 3, 3);
    }
  }, [actualCount, totalCount, formik.values.vesselSelection]);

  useEffect(() => {
    formik.setFieldValue("vesselFlangePipeSize", formik.values.flangeSize);
    formik.setFieldValue("FlangeRating", formik.values.FlangeClass);
  }, []);

  return (
    <div>
      <form>
        <div className="w-100 row px-4 col-md-12 col-sm-12">
          <div
            className="w-100 px-4 row justify-content-center mb-30"
            style={{ gap: "20px" }}
          >
            <label htmlFor="" style={{ fontSize: "20px", fontWeight: "bold" }}>
              Vessel Selection
            </label>
            <div className="d-flex align-items-center" style={{ gap: "20px" }}>
              <input
                type="radio"
                id="vesselYes"
                name="vesselSelection"
                checked={formik.values.vesselSelection === "yes"}
                onChange={() => {
                  formik.setFieldValue("vesselSelection", "yes");
                  handleFlangeThickness();
                }}
                value={formik.values.vesselSelection}
              />
              <label htmlFor="vesselYes">Yes</label>
              <input
                type="radio"
                id="vesselNo"
                name="vesselSelection"
                checked={formik.values.vesselSelection === "no"}
                onChange={() => {
                  formik.setFieldValue("vesselSelection", "no");
                  formik.setFieldValue("maxactivecoldlength", 200);
                }}
                value={formik.values.vesselSelection}
              />
              <label htmlFor="vesselNo">No</label>
            </div>
          </div>

          {formik.values.vesselSelection === "yes" && (
            <>
              <div className="d-flex w-100 row">
                <div className="col-md-3 mb-15">
                  <label htmlFor="">Vessel Flange Size </label>
                  <Select
                    name="flangeSize"
                    id="flangeSize"
                    options={vesselFlangeSizeOptions}
                    isClearable
                    value={
                      formik?.values?.flangeSize
                        ? {
                            value: formik?.values?.flangeSize,
                            label: formik?.values?.flangeSize,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue("flangeSize", option.value)
                    }
                    isDisabled
                    placeholder="Select from Flange Selection"
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="MaterialClass">Vessel Flange Rating</label>
                  <Select
                    name="FlangeRating"
                    id="FlangeRating"
                    options={[
                      { value: "150", label: "150" },
                      { value: "300", label: "300" },
                      { value: "450", label: "450" },
                      { value: "600", label: "600" },
                      { value: "900", label: "900" },
                      { value: "1200", label: "1200" },
                      { value: "1500", label: "1500" },
                      { value: "2500", label: "2500" },
                    ]}
                    isDisabled
                    placeholder="Select Flange Rating"
                    value={
                      formik?.values?.FlangeRating
                        ? {
                            value: formik?.values?.FlangeRating,
                            label: formik?.values?.FlangeRating,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="vesselrangetype">Vessel Flange Type</label>
                  <Select
                    name="vesselrangetype"
                    id="vesselrangetype"
                    options={vesselRangeTypeOptions}
                    placeholder="Range Type"
                    value={
                      formik?.values?.vesselrangetype
                        ? vesselRangeTypeOptions.find(
                            (option) =>
                              option.value === formik.values.vesselrangetype
                          )
                        : null
                    }
                    onChange={(option) => {
                      formik?.setFieldValue("vesselrangetype", option?.value);
                      formik?.setFieldValue(
                        "nozzle[0].flangeType",
                        option?.value
                      );
                      formik?.setFieldValue(
                        "nozzle[1].flangeType",
                        option?.value
                      );
                      formik?.setFieldValue(
                        "nozzle[2].flangeType",
                        option?.value
                      );
                      formik?.setFieldValue(
                        "nozzle[3].flangeType",
                        option?.value
                      );
                      formik?.setFieldValue(
                        "nozzle[4].flangeType",
                        option?.value
                      );
                      handleFlangeThickness();
                    }}
                    isClearable
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="sadlemoc">Sadle Moc</label>
                  <Select
                    name="sadlemoc"
                    id="sadlemoc"
                    options={[
                      { value: "CS", label: "CS" },
                      { value: "SS304", label: "SS304" },
                      { value: "SS316", label: "SS316" },
                      { value: "SS316L", label: "SS316L" },
                      { value: "SS321", label: "SS321" },
                      { value: "INCOLOY800", label: "INCOLOY800" },
                      { value: "INCOLOY825", label: "INCOLOY825" },
                      { value: "INCOLOY840", label: "INCOLOY840" },
                    ]}
                    placeholder="Select Sadle Moc"
                    value={
                      formik.values.sadlemoc
                        ? {
                            value: formik.values.sadlemoc,
                            label: formik.values.sadlemoc,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue("sadlemoc", option.value)
                    }
                  />
                </div>
              </div>
              <div className="d-flex w-100 row">
                <div className="col-md-3 mb-15">
                  <label htmlFor="vesselMaterial"> Vessel Material </label>
                  <Select
                    id="vesselMaterial"
                    name="vesselMaterial"
                    placeholder=" Vessel Material"
                    options={materials}
                    value={
                      formik?.values?.vesselMaterial
                        ? {
                            value: formik?.values?.vesselMaterial,
                            label: formik?.values?.vesselMaterial,
                          }
                        : null
                    }
                    onChange={(option) => {
                      if (option?.value) {
                        formik.setFieldValue("vesselMaterial", option?.value);
                        formik.setFieldValue(
                          "nozzle[0].material",
                          option?.value
                        );
                        formik.setFieldValue(
                          "nozzle[1].material",
                          option?.value
                        );
                        formik.setFieldValue(
                          "nozzle[2].material",
                          option?.value
                        );
                        formik.setFieldValue(
                          "nozzle[3].material",
                          option?.value
                        );
                        formik.setFieldValue(
                          "nozzle[4].material",
                          option?.value
                        );

                        handleMaterialSelect(option);
                      }
                      handleMaterialSelect(option);
                    }}
                    isClearable
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="col-md-3 mb-15">
                  <label htmlFor="vesselMaterialCode">
                    Vessel Material Code
                  </label>
                  <Select
                    id="vesselMaterialCode"
                    name="vesselMaterialCode"
                    placeholder=" Vessel Material Code"
                    options={materialCode}
                    value={
                      formik?.values?.vesselMaterialCode
                        ? {
                            value: formik?.values?.vesselMaterialCode,
                            label: formik?.values?.vesselMaterialCode,
                          }
                        : null
                    }
                    onChange={(option) => {
                      formik.setFieldValue("vesselMaterialCode", option?.value);
                      formik.setFieldValue(
                        "nozzle[0].materialCode",
                        option?.value
                      );
                      formik.setFieldValue(
                        "nozzle[1].materialCode",
                        option?.value
                      );
                      formik.setFieldValue(
                        "nozzle[2].materialCode",
                        option?.value
                      );
                      formik.setFieldValue(
                        "nozzle[3].materialCode",
                        option?.value
                      );
                      formik.setFieldValue(
                        "nozzle[4].materialCode",
                        option?.value
                      );
                    }}
                    isClearable
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="col-md-3 mb-15">
                  <label htmlFor="reducers">Reducers/Dish End</label>
                  <Select
                    name="reducers"
                    id="reducers"
                    options={reducers}
                    placeholder="Select Reducers/Dish End"
                    value={
                      formik?.values?.reducers
                        ? {
                            value: formik?.values?.reducers,
                            label: formik?.values?.reducers,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue("reducers", option?.value)
                    }
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="radiography">Radiography</label>
                  <Select
                    name="radiography"
                    id="radiography"
                    options={[
                      { value: "Full 100%", label: "Full 100%" },
                      { value: "Spot 10%", label: "Spot 10%" },
                      { value: "None", label: "None" },
                    ]}
                    placeholder="Select Radiography"
                    value={
                      formik.values.radiography
                        ? {
                            value: formik.values.radiography,
                            label: formik.values.radiography,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik.setFieldValue("radiography", option.value)
                    }
                  />
                </div>
              </div>
              <div className="d-flex w-100 row">
                <div className="col-md-3 mb-15">
                  <label htmlFor="standard">Standard</label>
                  <Select
                    id="standard"
                    name="standard"
                    placeholder="Standard"
                    options={[
                      {
                        value: "ASME Sec. VIII Div. 1",
                        label: "ASME Sec. VIII Div. 1",
                      },
                      {
                        value: "ASME Sec. VIII Div. 2",
                        label: "ASME Sec. VIII Div. 2",
                      },
                    ]}
                    value={
                      formik?.values?.standard
                        ? {
                            value: formik?.values?.standard,
                            label: formik?.values?.standard,
                          }
                        : null
                    }
                    onChange={(option) =>
                      formik?.setFieldValue("standard", option?.label)
                    }
                    isClearable
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="orientation">Orientation (Drawing)</label>
                  <Select
                    name="installationPosition"
                    id="installationPosition"
                    placeholder="Orientation"
                    isDisabled
                    value={
                      formik.values.installationPosition
                        ? {
                            value: formik.values.installationPosition,
                            label: formik.values.installationPosition,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-md-3 mb-15">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="vesselFlangePipeSize">Pipe Size</label>
                      <input
                        type="text"
                        id="vesselFlangePipeSize"
                        readOnly
                        name="vesselFlangePipeSize"
                        onChange={formik.handleChange}
                        value={formik.values.vesselFlangePipeSize}
                        className="form-control"
                        placeholder="Enter Vessel Flange Pipe Size"
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="Sch">Schedule</label>
                      <input
                        type="text"
                        id="Sch"
                        readOnly
                        name="Sch"
                        onChange={formik.handleChange}
                        value={formik?.values?.schedule}
                        className="form-control"
                        placeholder="Enter Schedule"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-15">
                  <label htmlFor="KettleSize">Kettle Size</label>
                  <input
                    type="text"
                    id="KettleSize"
                    name="KettleSize"
                    onChange={formik.handleChange}
                    value={formik.values.KettleSize}
                    className="form-control"
                    placeholder="Enter Kettle Size"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </form>
      <hr />
    </div>
  );
}

export default Screen5;
