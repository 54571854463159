const vesselSelection = {
  cp_controlPanel: "yes",
  cp_areaClassification: "",
  // cp_Power:"",
  // cp_voltage:"",
  // cp_frequency:"",
  cp_breakingCapacity: "",
  cp_ipRating: "",
  cp_noOfBanks: "",
  cp_thyristorPanelSelection: "",
  cp_thyristorPanel: "",
  cp_enclosureType: "",
  cp_noOfSaftyController: "",
  cp_inputTypeOfController: "",
};

export default vesselSelection;
