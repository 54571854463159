import { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
// import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import "./Screen2styles.css";
function Screen2({ formik, isPageComplete }) {
  const [totalCount, setTotalCount] = useState(12);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };
  useEffect(() => {
    let count = 0;
    // let total_count = totalCount;
    count = handleInputValue(formik?.values?.processFluid, count);
    count = handleInputValue(formik?.values?.specificHeatKJ, count);
    count = handleInputValue(formik?.values?.tempChange, count);
    count = handleInputValue(formik?.values?.massFlowRate, count);
    count = handleInputValue(formik?.values?.startingTemp, count);
    count = handleInputValue(formik?.values?.outletTemp, count);
    count = handleInputValue(formik?.values?.heatDutyKW, count);
    // count = handleInputValue(formik?.values?.areaClassification, count);
    // count = handleInputValue(formik?.values?.ipRating, count);
    count = handleInputValue(formik?.values?.multistackvessel, count);
    count = handleInputValue(formik?.values?.connection, count);
    count = handleInputValue(formik?.values?.inletPressure, count);
    count = handleInputValue(formik?.values?.outletPressure, count);
    count = handleInputValue(formik?.values?.allowedPressureDrop, count);
    // count = handleInputValue(formik?.values?.sensorSelection, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(2, totalCount, actualCount);
  }, [actualCount, totalCount]);

  const [selectedUnit, setSelectedUnit] = useState("°C");
  const [equallyDivide, setEquallyDivide] = useState(false);

  const [originalTemps, setOriginalTemps] = useState({
    startingTemp: "",
    outletTemp: "",
  });
  const [processFluids, setProcessFluids] = useState([]);
  const [units, setUnits] = useState([]);
  // const [ipRatingOptions, setIpRatingOptions] = useState([]);
  const [selectedTemperatureType, setSelectedTemperatureType] = useState(
    formik?.values?.temperatureType || ""
  );
  // const [selectedIPRating, setSelectedIPRating] = useState("");
  const [selectedInstrument, setSelectedInstrument] = useState(
    formik.values.instrument || ""
  );
  // const [bankHtml, setBankHtml] = useState("");
  const [heaterTerminalBox, setHeaterTerminalBox] = useState(
    formik.values.heaterterminalbox || ""
  );
  const [selectedArea, setSelectedArea] = useState(
    formik.values.areaClassification || ""
  );
  const [sensorVisibility, setSensorVisibility] = useState([true, true, true]);

  const handleSensorChange = (index, value) => {
    const sensorTypes = ["Skin Sensor", "Tube Sheet Sensor", "Process Sensor"];
    formik.setFieldValue(`sensor[${index}].selected`, value);
    validateSensors(sensorTypes[index], value);
    const updatedVisibility = [...sensorVisibility];
    updatedVisibility[index] = value === "yes";
    setSensorVisibility(updatedVisibility);
    formik.setFieldValue(`sensor[${index}].selected`, value);
  };

  const validateSensors = (sensorType, value) => {
    const areaClassification = formik.values?.areaClassification;
    if (areaClassification === "hazardous" && value === "no") {
      showErrorToast(
        `Sensors are selected to "no" for Hazardous Area: ${sensorType}`
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("process-fluids"); // Adjust the URL as needed
      if (response.status === 200) {
        const fluids = response.data?.data;
        setProcessFluids(fluids);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const fetchUnits = async () => {
    try {
      const response = await axiosPrivate.get("units");
      if (response.status === 200) {
        setUnits(response.data?.data);
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  useEffect(() => {
    const heaterterminalboxtempcode = formik.values.heaterterminalboxtempcode;
    let distance;

    switch (heaterterminalboxtempcode) {
      case "T1":
      case "T2":
        distance = 100;
        break;
      case "T3":
        distance = 125;
        break;
      case "T4":
        distance = 150;
        break;
      case "T5":
        distance = 300;
        break;
      case "T6":
        distance = 325;
        break;
      default:
        distance = formik.values.heaterterminalboxtempcode; // or any default value you prefer
    }

    formik.setFieldValue("distbwheaterflangetoterminalbox", distance);
  }, []);

  useEffect(() => {
    const power = formik.values.heatDutyKW * 1000;
    formik.setFieldValue("power", power || "");
    formik.setFieldValue("outletPressureUnit", formik.values.inletPressureUnit);
    formik.setFieldValue(
      "allowedPressureDropUnit",
      formik.values.inletPressureUnit
    );
    formik.setFieldValue("inletPressureUnit", formik.values.inletPressureUnit);
    fetchData();
    fetchUnits();
    if (formik.values.areaClassification) {
      setSelectedArea(formik.values.areaClassification);
    }
  }, [formik.values.areaClassification, formik.values.inletPressureUnit]);

  const handleInstrumentBoxChange = (e) => {
    const newInstrument = e.target.value;
    formik?.setFieldValue("instrument", newInstrument);
    setSelectedInstrument(newInstrument);
  };

  const sameAsCheckbox = (e) => {
    if (e.target.checked === true) {
      formik?.setFieldValue(
        "instrumentjunctionboxmaterial",
        formik.values.heaterterminalboxmaterial
      );
      formik?.setFieldValue(
        "gasgroup",
        formik.values.heaterterminalboxgasgroup
      );
      formik?.setFieldValue("zone", formik.values.heaterterminalboxzone);
      formik?.setFieldValue(
        "certificationrequired",
        formik.values.heaterterminalboxcertificationrequired
      );
      formik?.setFieldValue(
        "tempCode",
        formik.values.heaterterminalboxtempcode
      );
      formik?.setFieldValue("ijb", formik.values.heaterterminalboxijb);
      formik?.setFieldValue(
        "ipRating",
        formik.values.heaterterminalboxiprating
      );
    } else if (e.target.checked === false) {
      formik?.setFieldValue("instrumentjunctionboxmaterial", "");
      formik?.setFieldValue("gasgroup", "");
      formik?.setFieldValue("zone", "");
      formik?.setFieldValue("certificationrequired", "");
      formik?.setFieldValue("tempCode", "");
      formik?.setFieldValue("ijb", "");
      formik?.setFieldValue("ipRating", "");
    }
  };

  const generateTableHtml = () => {
    const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
    const totalBank = parseInt(formik.values.bank) || 0;
    const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
    const bundleHeatLoad = installedCapacity / effectiveVessel;
    const initialData = [];
  
    for (let i = 1; i <= effectiveVessel; i++) {
      const bundleRow = { bundle: [] };
      for (let j = 1; j <= totalBank; j++) {
        if (formik.values.divideEqually) {
          const bankHeatLoad = bundleHeatLoad / totalBank;
          bundleRow.bundle.push({
            bank: bankHeatLoad.toFixed(2),
          });
        } else {
          bundleRow.bundle.push({
            bank: "",
          });
        }
      }
      initialData.push(bundleRow);
    }
  
    formik.setFieldValue("bundleTableData", initialData);
  };

  const handleTerminalBox = (e) => {
    const newTerminalBox = e.target.value;
    formik?.setFieldValue("heaterterminalbox", newTerminalBox);
    setHeaterTerminalBox(newTerminalBox);
  };
  const handleTemperatureType = (e) => {
    const newTemperatureType = e.target.value;
    formik?.setFieldValue("temperatureType", newTemperatureType);
    setSelectedTemperatureType(newTemperatureType);
  };

  useEffect(() => {
    if (formik.values.specificHeatKJ) {
      const specificHeatBTU = (
        parseFloat(formik.values.specificHeatKJ) * 0.2388
      ).toFixed(4);
      formik?.setFieldValue("specificHeatBTU", specificHeatBTU);
    } else {
      formik.setFieldValue("specificHeatBTU", "");
    }
  }, [formik.values.specificHeatKJ]);

  const handleFluidChange = (option) => {
    formik?.setFieldValue("processFluid", option?.value);

    const selectedFluid = processFluids?.find(
      (fluid) => fluid?.name === option?.value
    );

    if (selectedFluid) {
      formik &&
        formik?.setFieldValue("specificHeatKJ", selectedFluid?.initial_value);
      const specificHeatBTU = (
        parseFloat(formik?.values?.specificHeatKJ) * 0.2388
      ).toFixed(4);
      formik?.setFieldValue("specificHeatBTU", specificHeatBTU);

      formik?.setFieldValue("viscosity", selectedFluid?.viscosity);
      formik?.setFieldValue("molecularweight", selectedFluid?.molecular_weight);
      formik?.setFieldValue("density", selectedFluid?.density_kg_m3);
      formik?.setFieldValue("wattdensity", selectedFluid?.density_kg_m3);

      formik?.setFieldValue(
        "thermalConductivity",
        selectedFluid?.thermal_conductivity_in_c
      );
    } else if (selectedFluid === undefined) {
      formik?.setFieldValue("specificHeatKJ", "");
      formik?.setFieldValue("specificHeatBTU", "");
      formik?.setFieldValue("viscosity", "");
      formik?.setFieldValue("molecularweight", "");
      formik?.setFieldValue("density", "");
      formik?.setFieldValue("wattdensity", "");
      formik?.setFieldValue("thermalConductivity", "");
    }
  };

  const sensors = [
    {
      label: "Skin Sensor",
      options: [{ label: "Skin Sensor", value: "Skin Sensor" }],
    },
    {
      label: "Tube Sheet Sensor",
      options: [{ label: "Tube Sheet Sensor", value: "Tube Sheet Sensor" }],
    },
    {
      label: "Process Sensor",
      options: [{ label: "Process Sensor", value: "Process Sensor" }],
    },
  ];

  const sensorsubtypeoptions = [
    { label: "RTD", value: "RTD" },
    { label: "Thermostat", value: "Thermostat" },
    { label: "K-type Thermocouple", value: "K-type Thermocouple" },
    { label: "J-type Thermocouple", value: "J-type Thermocouple" },
  ];

  const sensorrangeoptions = [
    { label: "Simplex", value: "Simplex" },
    { label: "Duplex", value: "Duplex" },
  ];

  const headMaterialOptions = [
    { label: "SS304", value: "SS304" },
    { label: "SS316", value: "SS316" },
    { label: "SS316L", value: "SS316L" },
    {
      label: "Aluminium Die Casted (Epoxy Coated)",
      value: "Aluminium Die Casted (Epoxy Coated)",
    },
  ];

  const sensorMaterialOptions = [
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "INCOLOY800", label: "INCOLOY800" },
    { value: "INCOLOY825", label: "INCOLOY825" },
    { value: "INCOLOY840", label: "INCOLOY840" },
  ];

  const terminalboxmaterial = [
    { value: "CS", label: "CS" },
    { value: "SS304", label: "SS304" },
    { value: "SS316", label: "SS316" },
    { value: "SS316L", label: "SS316L" },
    { value: "SS321", label: "SS321" },
    { value: "INCOLOY800", label: "INCOLOY800" },
    { value: "INCOLOY825", label: "INCOLOY825" },
    { value: "INCOLOY840", label: "INCOLOY840" },
    { value: "ALUMINIUM", label: "ALUMINIUM" },
  ];

  const anySpecificationOption = [
    { value: "U STAMP", label: "U STAMP" },
    { value: "U2 STAMP", label: "U2 STAMP" },
    { value: "CE + PD", label: "CE + PD" },
    { value: "CE (Electric)", label: "CE (Electric)" },
    { value: "DOSH", label: "DOSH" },
    { value: "MIGAS", label: "MIGAS" },
    { value: "GOST", label: "GOST" },
    { value: "CCOE", label: "CCOE" },
    { value: "UL", label: "UL" },
    { value: "CCC", label: "CCC" },
    { value: "SELO", label: "SELO" },
    { value: "NEMO", label: "NEMO" },
    { value: "CSA", label: "CSA" },
    { value: "BIS", label: "BIS" },
    { value: "IBR", label: "IBR" },
    { value: "Not Applicable", label: "Not Applicable" },
  ];

  const handleHeatDutyChange = (e) => {
    e.preventDefault();

    // Parsing input values and handling NaN cases with default empty strings
    const massFlowRate = parseFloat(formik?.values.massFlowRate) || "";
    const tempChange = parseFloat(formik?.values.tempChange) || "";
    const specificHeatKJ = parseFloat(formik?.values.specificHeatKJ) || "";
    const timeDuration = parseFloat(formik?.values.timeDuration) || 3600;
    const unit = formik?.values.unit;

    let tempChangeC;

    if (unit === "°F" && tempChange !== "") {
      tempChangeC = (tempChange * 5) / 9;
    } else {
      tempChangeC = tempChange;
    }

    if (massFlowRate !== "" && tempChangeC !== "" && specificHeatKJ !== "") {
      const heatDutyKW =
        (massFlowRate * tempChangeC * specificHeatKJ) / timeDuration;
      formik?.setFieldValue("heatDutyKW", heatDutyKW.toFixed(2));
      const heatDutyBTU = heatDutyKW * 3412.142;
      formik?.setFieldValue("heatDutyBTU", heatDutyBTU.toFixed(2));
    } else {
      // Set heat duty values to empty strings if inputs are invalid
      formik?.setFieldValue("heatDutyKW", "");
      formik?.setFieldValue("heatDutyBTU", "");
    }
  };

  const handlePressureDropChange = (e) => {
    e?.preventDefault();
    const inletPressure = parseFloat(formik?.values.inletPressure);
    const outletPressure = parseFloat(formik?.values.outletPressure);
    formik.setFieldValue("outletPressureUnit", formik.values.inletPressureUnit);
    formik.setFieldValue(
      "allowedPressureDropUnit",
      formik.values.inletPressureUnit
    );
    if (!isNaN(inletPressure) || !isNaN(outletPressure)) {
      const pressureDrop = inletPressure - outletPressure;
      formik &&
        formik?.setFieldValue("allowedPressureDrop", pressureDrop.toFixed(2));
    } else {
      formik?.setFieldValue("allowedPressureDrop", "");
    }
  };

  useEffect(() => {
    handlePressureDropChange();
  }, [formik.values.inletPressure, formik.values.outletPressure]);

  const handleCapacityChange = (e) => {
    e.preventDefault();
    const heatDutyKw = parseFloat(formik.values.heatDutyKW);
    const designmargin = parseFloat(formik.values.designmargin);
    const totalBank = parseInt(formik.values.bank);
    const multiStackVessel = parseInt(formik.values.multistackvessel);

    if (!isNaN(heatDutyKw)) {
      if (formik.values.connection === "Three Phase Star") {
        const starvoltage = (formik.values.voltage / 1.732).toFixed(2);
        formik.setFieldValue("voltage_calculation", starvoltage);
      } else {
        formik.setFieldValue("voltage_calculation", "");
      }

      let capacity = heatDutyKw;
      if (!isNaN(designmargin)) {
        capacity += heatDutyKw * (designmargin / 100);
      }
      formik.setFieldValue("calculatedcapacity", capacity.toFixed(2));
      const effectiveVessel = multiStackVessel > 0 ? multiStackVessel : 1;
      const bundleHeatLoad = formik.values?.installedCapacity / effectiveVessel;
      formik.setFieldValue("bundleheatload", bundleHeatLoad.toFixed(2));

      if (!isNaN(totalBank) && totalBank > 0) {
        // Handle table generation logic with respect to the checkbox
        if (formik.values.divideEqually) {
          generateTableHtml(totalBank, effectiveVessel, bundleHeatLoad);
        } else {
          generateTableHtml(totalBank, effectiveVessel, bundleHeatLoad);
          // Clear the table if divideEqually is false
          formik.setFieldValue(
            "bundleTableData[${rowIndex}].bundle[${colIndex}].bank",
            []
          );
          // formik.setFieldValue("bundleTableData", []);  
        }
      }
    } else {
      formik.setFieldValue("installedCapacity", "");
      formik.setFieldValue("calculatedcapacity", "");
      formik.setFieldValue("bundleheatload", "");
      formik.setFieldValue("bundleTableData", []);
    }
    formik.setFieldValue(
      "sensor[1].quantity",
      formik?.values?.multistackvessel
    );
  };

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];

    // Update the specific cell
    updatedTableData[rowIndex].bundle[colIndex].bank = parseFloat(value) || 0;

    // If equallyDivide is true, recalculate the values
    if (formik.values.equallyDivide) {
      const totalBundles = updatedTableData.length;
      const equalValue = (bundleHeatLoad / (totalBank * totalBundles)).toFixed(
        2
      );

      updatedTableData.forEach((row) => {
        row.bundle.forEach((col) => {
          col.bank = parseFloat(equalValue) || 0;
        });
        // Update totals
        row.rowTotal = row.bundle.reduce(
          (sum, col) => sum + parseFloat(col.bank) || 0,
          0
        );
        row.remainingHeatLoad = bundleHeatLoad - row.rowTotal;
      });
    } else {
      // If equallyDivide is false, just update totals
      updatedTableData.forEach((row) => {
        let rowTotal = 0;
        for (const data of row.bundle) {
          rowTotal += parseFloat(data.bank) || 0;
        }
        row.rowTotal = rowTotal;
        row.remainingHeatLoad = bundleHeatLoad - rowTotal;
      });
    }

    formik.setFieldValue("bundleTableData", updatedTableData);
  };

  const bundleHeatLoad = parseFloat(formik.values.bundleheatload) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;

  const convertCtoF = (celsius) => {
    return celsius === "" ? "" : ((celsius * 9) / 5 + 32).toFixed(2);
  };

  const convertFtoC = (fahrenheit) => {
    return fahrenheit === "" ? "" : (((fahrenheit - 32) * 5) / 9).toFixed(2);
  };

  const handleDeltaTempChange = () => {
    const startingTemp = parseFloat(formik.values.startingTemp);
    const outletTemp = parseFloat(formik.values.outletTemp);

    if (!isNaN(startingTemp) && !isNaN(outletTemp)) {
      const deltaTemp = outletTemp - startingTemp;
      formik.setFieldValue("tempChange", deltaTemp.toFixed(2));
    } else {
      formik.setFieldValue("tempChange", "");
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setSelectedUnit(newUnit);

    if (newUnit === "°C") {
      formik.setFieldValue(
        "startingTemp",
        convertFtoC(originalTemps.startingTemp)
      );
      formik.setFieldValue("outletTemp", convertFtoC(originalTemps.outletTemp));
    } else {
      formik.setFieldValue(
        "startingTemp",
        convertCtoF(originalTemps.startingTemp)
      );
      formik.setFieldValue("outletTemp", convertCtoF(originalTemps.outletTemp));
    }

    formik.setFieldValue("unit", newUnit);
    handleDeltaTempChange();
  };

  useEffect(() => {
    handleDeltaTempChange();
  }, [formik.values.startingTemp, formik.values.outletTemp]);

  useEffect(() => {
    setOriginalTemps({
      startingTemp: formik.values.startingTemp,
      outletTemp: formik.values.outletTemp,
    });
  }, [formik.values.startingTemp, formik.values.outletTemp]);

  const handleAreaChange = (e) => {
    const value = e.target.value;
    setSelectedArea(value);
    formik.setFieldValue("areaClassification", value);
  };

  function getIPRatingOptions(area) {
    const start = area === "safe" ? 42 : area === "hazardous" ? 54 : 0;
    const end = 67;

    return Array.from({ length: end - start + 1 }, (_, i) => {
      const ipValue = start + i;
      return {
        value: `IP${ipValue.toString().padStart(2, "0")}`,
        label: `IP${ipValue.toString().padStart(2, "0")}`,
      };
    });
  }

  const handleTempChange = (e) => {
    const outletTemp = parseInt(formik?.values?.outletTemp);
    const newDesignTemp = parseInt(
      !isNaN(parseInt(formik?.values?.outletTemp))
        ? (parseInt(formik?.values?.outletTemp) + 50).toFixed(2)
        : ""
    );
    formik?.setFieldValue("designTemperature", newDesignTemp);
  };

  const handleDesignPressure = (e) => {
    const newDesignPressure = !isNaN(parseFloat(formik?.values?.inletPressure))
      ? (parseFloat(formik?.values?.inletPressure) * 1.3).toFixed(2)
      : "";
    formik?.setFieldValue("designPressure", newDesignPressure);
    formik?.setFieldValue(
      "elementDiameter",
      formik?.values?.heatersheathtubedia
    );
  };

  const updateSensorQuantity = () => {
    const multiStackVessel = parseInt(formik.values.multistackvessel) || 0;
    const totalBank = parseInt(formik.values.bank) || 0;

    let sensorQuantity = 0;

    if (formik?.values?.areaClassification === "hazardous") {
      sensorQuantity = multiStackVessel * totalBank * 3;
    } else if (formik?.values?.areaClassification === "safe") {
      sensorQuantity = multiStackVessel * totalBank * 2;
    }

    formik.setFieldValue("sensor[0].quantity", sensorQuantity);
  };

  // Call this function whenever the relevant fields change
  useEffect(() => {
    updateSensorQuantity();
  }, [
    formik.values.areaClassification,
    formik.values.multistackvessel,
    formik.values.bank,
  ]);

  const handleEqualDivide = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue("divideEqually", isChecked);

    // Safeguard to ensure bundleTableData exists
    if (formik.values.bundleTableData) {
      formik.values.bundleTableData.forEach((row, rowIndex) => {
        if (row.bundle) {
          row.bundle.forEach((bundle, colIndex) => {
            const fieldName = `bundleTableData[${rowIndex}].bundle[${colIndex}].bank`;
            if (isChecked) {
              const dividedValue = (bundleHeatLoad / totalBank).toFixed(2);
              formik.setFieldValue(fieldName, dividedValue);
            } else {
              formik.setFieldValue(fieldName, "");
            }
          });
        }
      });
    }
  };

  return (
    <div className="">
      <div className="parentdiv">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="processfluid">Process Fluid</label>
              <CreatableSelect
                name="processFluid"
                id="processFluid"
                placeholder="Select Process Fluid"
                options={processFluids?.map((fluid) => ({
                  label: fluid?.name,
                  value: fluid?.name,
                }))}
                onChange={handleFluidChange}
                onBlur={() => formik.setFieldTouched("processFluid", true)}
                value={
                  formik?.values?.processFluid
                    ? {
                        value: formik?.values?.processFluid,
                        label: formik?.values?.processFluid,
                      }
                    : null
                }
                isClearable
              />
              {formik.touched.processFluid && formik.errors.processFluid ? (
                <div className="error">{formik.errors.processFluid}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="specificheatkj">Specific Heat (KJ/Kg K)</label>
              <input
                name="specificHeatKJ"
                type="text"
                id="specificHeatKJ"
                className="form-control"
                placeholder="Specific Heat (KJ/Kg K)"
                value={formik?.values.specificHeatKJ}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.specificHeatKJ &&
              formik &&
              formik?.errors.specificHeatKJ ? (
                <div className="error">{formik?.errors.specificHeatKJ}</div>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6">
              <label htmlFor="specificheatbtu">Specific Heat (BTU/lb F)</label>
              <input
                name="specificHeatBTU"
                id="specificHeatBTU"
                readOnly
                type="text"
                className="form-control"
                placeholder="Specific Heat (BTU/lb F)"
                value={formik?.values.specificHeatBTU}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.specificHeatBTU &&
              formik &&
              formik?.errors.specificHeatBTU ? (
                <div className="error">{formik?.errors.specificHeatBTU}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Temperature</label>
              <div className="row d-flex px-2 py-2">
                <div className="row gap-3">
                  <input
                    type="radio"
                    id="celsius"
                    name="unit"
                    value="°C"
                    checked={selectedUnit === "°C"}
                    onChange={handleUnitChange}
                    className="form-control"
                  />
                  <label htmlFor="celsius">Celsius</label>
                </div>
                <div className="row gap-3 ml-5">
                  <input
                    type="radio"
                    id="fahrenheit"
                    name="unit"
                    value="°F"
                    className="form-control"
                    checked={selectedUnit === "°F"}
                    onChange={handleUnitChange}
                  />
                  <label htmlFor="fahrenheit">Fahrenheit</label>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12 ">
              <label htmlFor="startingTemp">
                Starting Temperature({selectedUnit})
              </label>
              <input
                type="text"
                placeholder={`Starting Temperature (${selectedUnit})`}
                name="startingTemp"
                id="startingTemp"
                value={formik?.values?.startingTemp || ""}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                className="form-control"
              />
              {formik &&
              formik?.touched.startingTemp &&
              formik &&
              formik?.errors.startingTemp ? (
                <div className="error">{formik?.errors.startingTemp}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12 ">
              <label htmlFor="outletTemp">
                Outlet Temperature({selectedUnit})
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={`Outlet Temperature (${selectedUnit})`}
                name="outletTemp"
                id="outletTemp"
                value={formik?.values?.outletTemp || ""}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleTempChange(e);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleTempChange(e);
                }}
              />
              {formik &&
              formik?.touched.outletTemp &&
              formik &&
              formik?.errors.outletTemp ? (
                <div className="error">{formik?.errors.outletTemp}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="tempchange">
                Temperature Change ({selectedUnit})
              </label>
              <input
                name="tempChange"
                id="tempChange"
                type="text"
                readOnly
                className="form-control"
                placeholder="Temperature Change"
                value={formik?.values?.tempChange || ""}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.tempChange &&
              formik &&
              formik?.errors.tempChange ? (
                <div className="error">{formik?.errors.tempChange}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="massFlowRate">Mass Flow Rate (kg/h)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Mass Flow Rate (kg/h)"
                name="massFlowRate"
                id="massFlowRate"
                value={formik?.values?.massFlowRate}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik.touched.massFlowRate && formik.errors?.massFlowRate ? (
                <div className="error">{formik.errors?.massFlowRate}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="timeduration">Time Duration (seconds)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Time Duration (seconds)"
                name="timeDuration"
                id="timeDuration"
                value={formik?.values.timeDuration}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.timeDuration &&
              formik &&
              formik?.errors.timeDuration ? (
                <div className="error">{formik?.errors.timeDuration}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12 d-flex align-items-end">
              <button
                className="btn btn-primary bg-primary form-control d-flex align-items-center justify-content-center"
                onClick={handleHeatDutyChange}
              >
                <h6>Calculate</h6>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="heatdutykw">Heat Duty (KW)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Heat Duty (KW)"
                name="heatDutyKW"
                id="heatdutykw"
                value={formik?.values.heatDutyKW}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.heatDutyKW &&
              formik &&
              formik?.errors.heatDutyKW ? (
                <div className="error">{formik?.errors.heatDutyKW}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="heatdutybtu">Heat Duty (BTU/hr)</label>
              <input
                type="text"
                className="form-control"
                readOnly
                placeholder="Heat Duty (BTU/hr)"
                name="heatDutyBTU"
                id="heatdutybtu"
                value={formik?.values.heatDutyBTU}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.heatDutyBTU &&
              formik &&
              formik?.errors.heatDutyBTU ? (
                <div className="error">{formik?.errors.heatDutyBTU}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Panel Requirements</label>
              <Select
                name="panelrequirements"
                id="panelrequirements"
                placeholder="Panel Requirements"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                onChange={(option) =>
                  formik &&
                  formik?.setFieldValue("panelrequirements", option.value)
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.panelrequirements
                    ? {
                        label: formik.values.panelrequirements,
                        value: formik.values.panelrequirements,
                      }
                    : null
                }
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="">Heater Installation Position</label>
              <Select
                name="installationPosition"
                id="installationPosition"
                placeholder="Installation Position"
                options={[
                  { label: "Horizontal", value: "Horizontal" },
                  { label: "Vertical", value: "Vertical" },
                ]}
                onChange={(option) =>
                  formik?.setFieldValue("installationPosition", option.value)
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.installationPosition
                    ? {
                        label: formik.values.installationPosition,
                        value: formik.values.installationPosition,
                      }
                    : null
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="speccertification" className="">
                Any Specific Certification
              </label>
              <Select
                name="speccertification"
                id="speccertification"
                placeholder="Any Specific Certification"
                options={anySpecificationOption}
                onChange={(option) =>
                  formik?.setFieldValue("speccertification", option.value)
                }
                onBlur={formik?.handleBlur}
                value={
                  formik?.values.speccertification
                    ? {
                        label: formik?.values.speccertification,
                        value: formik?.values.speccertification,
                      }
                    : null
                }
              />
              {formik &&
              formik?.touched.speccertification &&
              formik &&
              formik?.errors.speccertification ? (
                <div className="error">{formik?.errors.speccertification}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="thermalConductivity">Thermal Conductivity</label>
              <input
                type="text"
                className="form-control"
                placeholder="Thermal Conductivity"
                name="thermalConductivity"
                id="thermalConductivity"
                value={formik?.values.thermalConductivity}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.thermalConductivity &&
              formik &&
              formik?.errors.thermalConductivity ? (
                <div className="error">
                  {formik?.errors.thermalConductivity}
                </div>
              ) : null}
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6">
                <label htmlFor="corrosionAllowance">Corrosion Allowance</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Corrosion Allowance"
                  name="corrosionAllowance"
                  id="corrosionAllowance"
                  value={formik?.values.corrosionAllowance}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.corrosionAllowance &&
                formik &&
                formik?.errors.corrosionAllowance ? (
                  <div className="error">
                    {formik?.errors.corrosionAllowance}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12">
                <label htmlFor="viscosity">Viscosity</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Viscosity"
                  name="viscosity"
                  id="viscosity"
                  value={formik?.values.viscosity}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.viscosity &&
                formik &&
                formik?.errors.viscosity ? (
                  <div className="error">{formik?.errors.viscosity}</div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <label htmlFor="mdmt">MDMT</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Minimum Design Metal Temperature"
                  name="mdmt"
                  id="mdmt"
                  value={formik?.values.mdmt}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.mdmt &&
                formik &&
                formik?.errors.mdmt ? (
                  <div className="error">{formik?.errors.mdmt}</div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12">
                <label htmlFor="">Density</label>
                <input
                  type="text"
                  name="density"
                  id="density"
                  className="form-control"
                  placeholder="Density"
                  value={formik?.values.density}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.density &&
                formik &&
                formik?.errors.density ? (
                  <div className="error">{formik?.errors.density}</div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-15">
              {/* <div className="col-md-6 col-sm-12">
                <label htmlFor="areaClassification" className="">
                  Area Classification
                </label>
                <div className="row d-flex px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="safe"
                      name="areaClassification"
                      value="safe"
                      checked={selectedArea === "safe"}
                      onChange={handleAreaChange}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="safe">Safe Area</label>
                  </div>
                  <div className="row ml-2">
                    <input
                      type="radio"
                      id="hazardous"
                      name="areaClassification"
                      value="hazardous"
                      checked={selectedArea === "hazardous"}
                      onChange={handleAreaChange}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="hazardous">Hazardous Area</label>
                  </div>
                </div>
              </div> */}
              <div className="col-md-6 col-sm-12 mb-15">
                <label htmlFor="molecularweight">
                  Ambient Temperature (Deg °C )
                </label>
                <input
                  type="text"
                  name="ambienttemperature"
                  id="ambienttemperature"
                  className="form-control"
                  placeholder="Ambient Temperature"
                  value={formik?.values.ambienttemperature}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.ambienttemperature &&
                formik &&
                formik?.errors.ambienttemperature ? (
                  <div className="error">
                    {formik?.errors.ambienttemperature}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-sm-12 mb-15">
                <label htmlFor="molecularweight">Molecular Weight</label>
                <input
                  type="text"
                  name="molecularweight"
                  id="molecularweight"
                  className="form-control"
                  placeholder="Molecular Weight"
                  value={formik?.values.molecularweight}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                />
                {formik &&
                formik?.touched.molecularweight &&
                formik &&
                formik?.errors.molecularweight ? (
                  <div className="error">{formik?.errors.molecularweight}</div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <label htmlFor="areaClassification" className="">
                  Area Classification
                </label>
                <div className="row d-flex px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="safe"
                      name="areaClassification"
                      value="safe"
                      checked={selectedArea === "safe"}
                      onChange={(e) => {
                        handleAreaChange(e);
                      }}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="safe">Safe Area</label>
                  </div>
                  <div className="row ml-2">
                    <input
                      type="radio"
                      id="hazardous"
                      name="areaClassification"
                      value="hazardous"
                      checked={selectedArea === "hazardous"}
                      onChange={(e) => {
                        handleAreaChange(e);
                      }}
                      onBlur={formik?.handleBlur}
                    />
                    <label htmlFor="hazardous">Hazardous Area</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-15">
              <div className="col-md-6 col-sm-12">
                <div>
                  <label htmlFor="heaterterminalbox" className="">
                    Heater Terminal Box
                  </label>
                  <div className="row d-flex px-2 py-2">
                    <div className="row">
                      <input
                        type="radio"
                        id="heaterterminalbox"
                        name="heaterterminalbox"
                        value="yes"
                        checked={heaterTerminalBox === "yes"}
                        onChange={handleTerminalBox}
                        onBlur={formik?.handleBlur}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="row ml-4">
                      <input
                        type="radio"
                        id="heaterterminalbox"
                        name="heaterterminalbox"
                        value="no"
                        checked={heaterTerminalBox === "no"}
                        onChange={handleTerminalBox}
                        onBlur={formik?.handleBlur}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
                {/* Heater Terminal Box Ip Rating  */}
                {(selectedArea === "safe" || selectedArea === "hazardous") &&
                  heaterTerminalBox === "yes" && (
                    <div className="mb-15">
                      <label htmlFor="heaterterminalboxiprating">
                        Heater Terminal Box IP Rating
                      </label>
                      <Select
                        name="heaterterminalboxiprating"
                        placeholder="Heater Terminal Box IP"
                        id="heaterterminalboxiprating"
                        options={getIPRatingOptions(
                          formik.values.areaClassification
                        )}
                        onChange={(option) =>
                          formik?.setFieldValue(
                            "heaterterminalboxiprating",
                            option.value
                          )
                        }
                        value={
                          formik.values.heaterterminalboxiprating
                            ? {
                                label: formik.values.heaterterminalboxiprating,
                                value: formik.values.heaterterminalboxiprating,
                              }
                            : null
                        }
                        onBlur={formik?.handleBlur}
                      />
                    </div>
                  )}
                {selectedArea === "hazardous" &&
                  heaterTerminalBox === "yes" && (
                    <div>
                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxijb">
                          {" "}
                          Heater Terminal Box
                        </label>
                        <Select
                          placeholder="Select HTB"
                          name="heaterterminalboxijb"
                          id="heaterterminalboxijb"
                          options={[
                            { label: "Ex'd", value: "Ex'd" },
                            { label: "Ex'e", value: "Ex'e" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "heaterterminalboxijb",
                              option.value
                            )
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik.values.heaterterminalboxijb
                              ? {
                                  label: formik.values.heaterterminalboxijb,
                                  value: formik.values.heaterterminalboxijb,
                                }
                              : null
                          }
                        />
                        {formik &&
                        formik?.touched.ijb &&
                        formik &&
                        formik?.errors.ijb ? (
                          <div className="error">{formik?.errors.ijb}</div>
                        ) : null}
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxtempcode">
                          Heater Terminal Box Temp. Code
                        </label>
                        <Select
                          name="heaterterminalboxtempcode"
                          placeholder="Select Temp. Code"
                          id="heaterterminalboxtempcode"
                          options={[
                            { label: "T1", value: "T1" },
                            { label: "T2", value: "T2" },
                            { label: "T3", value: "T3" },
                            { label: "T4", value: "T4" },
                            { label: "T5", value: "T5" },
                            { label: "T6", value: "T6" },
                          ]}
                          onChange={(option) => {
                            formik &&
                              formik.setFieldValue(
                                "heaterterminalboxtempcode",
                                option.value
                              );
                          }}
                          value={
                            formik.values.heaterterminalboxtempcode
                              ? {
                                  label:
                                    formik.values.heaterterminalboxtempcode,
                                  value:
                                    formik.values.heaterterminalboxtempcode,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxcertificationrequired">
                          Certification Required
                        </label>
                        <Select
                          name="heaterterminalboxcertificationrequired"
                          placeholder="Select Certification"
                          id="heaterterminalboxcertificationrequired"
                          options={[
                            { label: "ATEX", value: "ATEX" },
                            { label: "IECX", value: "IECX" },
                            { label: "PESO", value: "PESO" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "heaterterminalboxcertificationrequired",
                              option.value
                            )
                          }
                          value={
                            formik.values.heaterterminalboxcertificationrequired
                              ? {
                                  label:
                                    formik.values
                                      .heaterterminalboxcertificationrequired,
                                  value:
                                    formik.values
                                      .heaterterminalboxcertificationrequired,
                                }
                              : null
                          }
                        />
                      </div>

                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxzone">Zone</label>
                        <Select
                          name="heaterterminalboxzone"
                          placeholder="Select Zone"
                          id="heaterterminalboxzone"
                          options={[
                            { label: "Zone 1", value: "Zone 1" },
                            { label: "Zone 2", value: "Zone 2" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "heaterterminalboxzone",
                              option.value
                            )
                          }
                          value={
                            formik.values.heaterterminalboxzone
                              ? {
                                  label: formik.values.heaterterminalboxzone,
                                  value: formik.values.heaterterminalboxzone,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="heaterterminalboxgasgroup">
                          Gas Group
                        </label>
                        <Select
                          name="heaterterminalboxgasgroup"
                          placeholder="Select Gas Group"
                          id="heaterterminalboxgasgroup"
                          options={[
                            { label: "II A", value: "IIA" },
                            { label: "II B", value: "II B" },
                            { label: "II C", value: "II C" },
                            { label: "II A/B", value: "II A/B" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "heaterterminalboxgasgroup",
                              option.value
                            )
                          }
                          value={
                            formik.values.heaterterminalboxgasgroup
                              ? {
                                  label:
                                    formik.values.heaterterminalboxgasgroup,
                                  value:
                                    formik.values.heaterterminalboxgasgroup,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>

                      <div>
                        <label htmlFor="heaterterminalboxmaterial">
                          Heater Terminal Box Material
                        </label>
                        <Select
                          name="heaterterminalboxmaterial"
                          placeholder="Select Material"
                          id="heaterterminalboxmaterial"
                          options={terminalboxmaterial}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "heaterterminalboxmaterial",
                              option.value
                            )
                          }
                          value={
                            formik.values.heaterterminalboxmaterial
                              ? {
                                  label:
                                    formik.values.heaterterminalboxmaterial,
                                  value:
                                    formik.values.heaterterminalboxmaterial,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div className="col">
                {/* Area Classfication  */}

                <div className="">
                  <label htmlFor="seperateInstrumentbox" className="">
                    Instrument Junction Box
                  </label>

                  <div className="row px-2 py-2 d-flex align-items-center justify-content-start">
                    <div className="row mt-1">
                      <input
                        type="radio"
                        id="instrumentyes"
                        name="instrument"
                        value="yes"
                        checked={selectedInstrument === "yes"}
                        onChange={handleInstrumentBoxChange}
                        onBlur={formik?.handleblur}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="row ml-3 mt-1">
                      <input
                        type="radio"
                        id="instrumentno"
                        name="instrument"
                        value="no"
                        checked={selectedInstrument === "no"}
                        onChange={handleInstrumentBoxChange}
                        onBlur={formik?.handleblur}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                    <br />
                    {selectedInstrument === "yes" && (
                      <div className="ml-3 row form-check d-flex align-items-center">
                        <input
                          type="checkbox"
                          id="same_as"
                          className="same_as form-check-input my-0 py-0 "
                          onChange={sameAsCheckbox}
                        />
                        <label htmlFor="same_as" className="form-check-label">
                          Same as Heater Terminal Box
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                {/* IP Rating  */}
                {(selectedArea === "safe" || selectedArea === "hazardous") &&
                  selectedInstrument === "yes" && (
                    <div className="mb-15">
                      <label htmlFor="iprating">
                        Instrument Junction Box IP
                      </label>
                      <Select
                        name="ipRating"
                        placeholder="Instrument Junction Box IP"
                        id="ipRating"
                        options={getIPRatingOptions(
                          formik.values.areaClassification
                        )}
                        onChange={(option) =>
                          formik?.setFieldValue("ipRating", option.value)
                        }
                        value={
                          formik.values.ipRating
                            ? {
                                label: formik.values.ipRating,
                                value: formik.values.ipRating,
                              }
                            : null
                        }
                        onBlur={formik?.handleBlur}
                      />
                    </div>
                  )}
                {/* IJB  */}
                {selectedArea === "hazardous" &&
                  selectedInstrument === "yes" && (
                    <div>
                      <div className="mb-15">
                        <label htmlFor="material">IJB</label>
                        <Select
                          placeholder="Select IJB"
                          name="ijb"
                          id="ijb"
                          options={[
                            { label: "Ex'd", value: "Ex'd" },
                            { label: "Ex'e", value: "Ex'e" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue("ijb", option.value)
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik.values.ijb
                              ? {
                                  label: formik.values.ijb,
                                  value: formik.values.ijb,
                                }
                              : null
                          }
                        />
                        {formik &&
                        formik?.touched.ijb &&
                        formik &&
                        formik?.errors.ijb ? (
                          <div className="error">{formik?.errors.ijb}</div>
                        ) : null}
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempcode">Temp. Code</label>
                        <Select
                          name="tempCode"
                          placeholder="Select Temp. Code"
                          id="tempCode"
                          options={[
                            { label: "T1", value: "T1" },
                            { label: "T2", value: "T2" },
                            { label: "T3", value: "T3" },
                            { label: "T4", value: "T4" },
                            { label: "T5", value: "T5" },
                            { label: "T6", value: "T6" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue("tempCode", option.value)
                          }
                          value={
                            formik.values.tempCode
                              ? {
                                  label: formik.values.tempCode,
                                  value: formik.values.tempCode,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="certificationrequired">
                          Certification Required
                        </label>
                        <Select
                          name="certificationrequired"
                          placeholder="Select Certification"
                          id="certificationrequired"
                          options={[
                            { label: "ATEX", value: "ATEX" },
                            { label: "IECX", value: "IECX" },
                            { label: "PESO", value: "PESO" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "certificationrequired",
                              option.value
                            )
                          }
                          value={
                            formik.values.certificationrequired
                              ? {
                                  label: formik.values.certificationrequired,
                                  value: formik.values.certificationrequired,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="zone">Zone</label>
                        <Select
                          name="zone"
                          placeholder="Select Zone"
                          id="zone"
                          options={[
                            { label: "Zone 1", value: "Zone 1" },
                            { label: "Zone 2", value: "Zone 2" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue("zone", option.value)
                          }
                          value={
                            formik.values.zone
                              ? {
                                  label: formik.values.zone,
                                  value: formik.values.zone,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="gasgroup">Gas Group</label>
                        <Select
                          name="gasgroup"
                          placeholder="Select Gas Group"
                          id="gasgroup"
                          options={[
                            { label: "II A", value: "IIA" },
                            { label: "II B", value: "II B" },
                            { label: "II C", value: "II C" },
                            { label: "II A/B", value: "II A/B" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue("gasgroup", option.value)
                          }
                          value={
                            formik.values.gasgroup
                              ? {
                                  label: formik.values.gasgroup,
                                  value: formik.values.gasgroup,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>

                      <div>
                        <label htmlFor="instrumentjunctionboxmaterial">
                          Instrument Junction Box Material
                        </label>
                        <Select
                          name="instrumentjunctionboxmaterial"
                          placeholder="Select Material"
                          id="instrumentjunctionboxmaterial"
                          options={terminalboxmaterial}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "instrumentjunctionboxmaterial",
                              option.value
                            )
                          }
                          value={
                            formik.values.instrumentjunctionboxmaterial
                              ? {
                                  label:
                                    formik.values.instrumentjunctionboxmaterial,
                                  value:
                                    formik.values.instrumentjunctionboxmaterial,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-md-12">
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="multistackvessel">
                No. of Multi Stack Vessel
              </label>
              <Select
                placeholder="No. of Multi Stack Vessel"
                name="multistackvessel"
                id="multistackvessel"
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                ]}
                onChange={(option) =>
                  formik &&
                  formik?.setFieldValue("multistackvessel", option.value)
                }
                value={
                  formik.values.multistackvessel
                    ? {
                        label: formik.values.multistackvessel,
                        value: formik.values.multistackvessel,
                      }
                    : null
                }
              />
              {formik &&
              formik?.touched.multistackvessel &&
              formik &&
              formik?.errors.multistackvessel ? (
                <div className="error">{formik?.errors.multistackvessel}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label htmlFor="designmargin">Design Margin(%)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Design Margin (%)"
                name="designmargin"
                id="designmargin"
                value={formik?.values.designmargin}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (isNaN(value)) value = "";
                  else if (value < 0) value = 0;
                  else if (value > 100) value = 100;
                  formik.setFieldValue("designmargin", value);
                }}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.designmargin &&
              formik &&
              formik?.errors.designmargin ? (
                <div className="error">{formik?.errors.designmargin}</div>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="voltage">Voltage(V)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Voltage(V)"
                name="voltage"
                id="voltage"
                value={formik?.values?.voltage}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.voltage &&
              formik &&
              formik?.errors.voltage ? (
                <div className="error">{formik?.errors.voltage}</div>
              ) : null}
            </div>

            <div className="col-md-3 col-sm-12">
              <label htmlFor="voltage">Voltage(V) (TPS)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Three Phase Star"
                name="voltage_calculation"
                id="voltage_calculation"
                value={formik?.values?.voltage_calculation}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                readOnly
              />
              {formik &&
              formik?.touched.voltage &&
              formik &&
              formik?.errors.voltage ? (
                <div className="error">{formik?.errors.voltage}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="inbuildspare">Inbuild Spare (%)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Inbuild Spare (%)"
                name="inbuildspare"
                id="inbuildspare"
                value={formik?.values.inbuildspare}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (isNaN(value)) value = "";
                  else if (value < 0) value = 0;
                  else if (value > 100) value = 100;
                  formik.setFieldValue("inbuildspare", value);
                }}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.inbuildspare &&
              formik &&
              formik?.errors.inbuildspare ? (
                <div className="error">{formik?.errors.inbuildspare}</div>
              ) : null}
            </div>
          </div>
          {/* right div 2nd column */}
          <div className="d-flex justify-content-between mb-15 pb-1">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="connection">Connection</label>
              <Select
                name="connection"
                id="connection"
                placeholder="Select Connection"
                options={[
                  { label: "Single Phase", value: "Single Phase" },
                  {
                    label: "Three Phase Delta",
                    value: "Three Phase Delta",
                  },
                  { label: "Three Phase Star", value: "Three Phase Star" },
                ]}
                onChange={(option) =>
                  formik && formik?.setFieldValue("connection", option.value)
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.connection
                    ? {
                        label: formik.values.connection,
                        value: formik.values.connection,
                      }
                    : null
                }
              />
              {formik &&
              formik?.touched.connection &&
              formik &&
              formik?.errors.connection ? (
                <div className="error">{formik?.errors.connection}</div>
              ) : null}
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="frequency">Frequency</label>
              <Select
                name="frequency"
                id="frequency"
                placeholder="Select Frequency"
                options={[
                  { label: "50", value: "50" },
                  { label: "60", value: "60" },
                ]}
                onChange={(option) =>
                  formik && formik?.setFieldValue("frequency", option.value)
                }
                onBlur={formik?.handleBlur}
                value={
                  formik.values.frequency
                    ? {
                        label: formik.values.frequency,
                        value: formik.values.frequency,
                      }
                    : null
                }
              />
              {formik &&
              formik?.touched.frequency &&
              formik &&
              formik?.errors.frequency ? (
                <div className="error">{formik?.errors.frequency}</div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="bank">Total Bank</label>
              <Select
                placeholder="Total No. of bank"
                name="bank"
                id="bank"
                options={[
                  { label: "1", value: 1 },
                  { label: "2", value: 2 },
                  { label: "3", value: 3 },
                  { label: "4", value: 4 },
                  { label: "5", value: 5 },
                  { label: "6", value: 6 },
                  { label: "7", value: 7 },
                  { label: "8", value: 8 },
                  { label: "9", value: 9 },
                  { label: "10", value: 10 },
                ]}
                onChange={(option) =>
                  formik && formik?.setFieldValue("bank", option.value)
                }
                value={
                  formik.values.bank
                    ? {
                        label: formik.values.bank,
                        value: formik.values.bank,
                      }
                    : null
                }
              />
              {formik &&
              formik?.touched.bank &&
              formik &&
              formik?.errors.bank ? (
                <div className="error">{formik?.errors.bank}</div>
              ) : null}
            </div>
            <div className="col-md-6 mt-1">
              <div>
                <input
                  type="checkbox"
                  id="equalDivide"
                  name="divideEqually"
                  className="equalDivide mr-2"
                  onChange={handleEqualDivide}
                  checked={formik?.values.divideEqually}
                  value={
                    formik?.values.divideEqually ? "divideEqually" : "notDivide"
                  }
                />
                <label className="mb-1">Divide bundle Heat Load equally?</label>
              </div>
              <button
                className="btn btn-primary bg-primary form-control"
                onClick={handleCapacityChange}
              >
                <h6>Calculate</h6>
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-15">
            <div className="col-md-3 col-sm-12">
              <label htmlFor="calculatedcapacity">Calculated Capacity</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calculated Capacity"
                readOnly
                name="calculatedcapacity"
                id="calculatedcapacity"
                value={formik?.values.calculatedcapacity}
                onChange={(e) => e.preventDefault}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.cal &&
              formik &&
              formik?.errors.calculatedcapacity ? (
                <div className="error">{formik?.errors.calculatedcapacity}</div>
              ) : null}
            </div>
            <div className="col-md-3 col-sm-12">
              <label htmlFor="installedcapacity">Installed Capacity</label>
              <input
                type="text"
                className="form-control"
                placeholder={formik?.values.installedCapacity}
                name="installedCapacity"
                id="installedCapacity"
                value={formik?.values.installedCapacity}
                onChange={(e) => {
                  e.preventDefault();
                  formik.setFieldValue("installedCapacity", e.target.value);
                }
                }
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.installedCapacity &&
              formik &&
              formik?.errors.installedCapacity ? (
                <div className="error">{formik?.errors.installedCapacity}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              <label htmlFor="bundleheatload">Ind Bundle Heat Load</label>
              <input
                type="text"
                className="form-control"
                placeholder=" Ind Bundle Heat Load"
                readOnly
                name="bundleheatload"
                id="bundleheatload"
                value={formik?.values.bundleheatload}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik &&
              formik?.touched.bundleheatload &&
              formik &&
              formik?.errors.bundleheatload ? (
                <div className="error">{formik?.errors.bundleheatload}</div>
              ) : null}
            </div>
          </div>

          {/* Sensor Selection  */}

          {/* <div
            dangerouslySetInnerHTML={{ __html: bankHtml }}
            className="d-flex col-md-12 mt-15 mb-30"
          ></div> */}

          <div className="d-flex col-md-12 mt-15 mb-30">
            <table className="table border border-secondary">
              <thead>
                <tr style={{ border: "1px solid #ccc", padding: "8px" }}>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    <strong>Bundle</strong>
                  </td>
                  {Array.from({ length: totalBank }, (_, i) => (
                    <td
                      key={i}
                      style={{ border: "1px solid #ccc", padding: "8px" }}
                    >
                      <strong>Bank {i + 1}</strong>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {formik.values.bundleTableData.map((row, rowIndex) => {
                  const totalHeatLoad = formik.values.divideEqually
                    ? bundleHeatLoad
                    : row.bundle.reduce(
                        (sum, col) => sum + parseFloat(col.bank || 0),
                        0
                      );
                  const remainingHeatLoad = bundleHeatLoad - totalHeatLoad;

                  return (
                    <tr
                      key={rowIndex}
                      style={{ border: "1px solid #ccc", padding: "8px" }}
                    >
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        Bundle {rowIndex + 1}
                        <br /> Total&nbsp;/&nbsp;Remaining <br />
                        {bundleHeatLoad.toFixed(2)}&nbsp;/&nbsp;
                        {remainingHeatLoad.toFixed(2)}
                        {remainingHeatLoad < 0 ? (
                          <div className="error">Limit exceeded</div>
                        ) : null}
                      </td>
                      {row.bundle.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                        >
                          <input
                            style={{
                              width: "100%",
                              minWidth: "30px",
                              boxSizing: "border-box",
                              padding: "5px",
                            }}
                            name={`bundleTableData[${rowIndex}].bundle[${colIndex}].bank`}
                            id={`bundleTableData${rowIndex}.bundle${colIndex}.bank`}
                            type="number"
                            className="form-control"
                            value={
                              formik.values.divideEqually
                                ? (bundleHeatLoad / totalBank).toFixed(2)
                                : formik.values.bundleTableData?.[rowIndex]
                                    ?.bundle?.[colIndex]?.bank || ""
                            }
                            onChange={(e) =>
                              !formik.values.divideEqually &&
                              handleInputChange(e, rowIndex, colIndex)
                            }
                            readOnly={formik.values.divideEqually}
                            disabled={formik.values.divideEqually}
                          />
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between">
            <div className="col-md-6 col-sm-12">
              <label htmlFor="inletPressure">Inlet Pressure</label>
              <div className="mb-15 d-flex row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="inletPressure"
                    id="inletPressure"
                    placeholder="Inlet Pressure"
                    value={formik?.values.inletPressure}
                    onChange={(e) => {
                      formik?.handleChange(e);
                      handleDesignPressure(e);
                    }}
                    onBlur={(e) => {
                      formik?.handleBlur(e);
                      handleDesignPressure(e);
                    }}
                  />
                  {formik &&
                  formik?.touched.inletPressure &&
                  formik &&
                  formik?.errors.inletPressure ? (
                    <div className="error">{formik?.errors.inletPressure}</div>
                  ) : null}
                </div>
                <Select
                  name="inletPressureUnit"
                  id="inletPressureUnit"
                  placeholder="Unit"
                  options={units.map((unit) => ({
                    label: unit.name,
                    value: unit.name,
                  }))}
                  value={
                    formik.values.inletPressureUnit
                      ? {
                          label: formik.values.inletPressureUnit,
                          value: formik.values.inletPressureUnit,
                        }
                      : null
                  }
                  onChange={(option) =>
                    formik?.setFieldValue("inletPressureUnit", option.value)
                  }
                  onBlur={formik?.handleBlur}
                  className="col"
                />
                {/* {formik &&
                      formik?.touched.inletPressure &&
                      formik &&
                      formik?.errors.inletPressure ? (
                        <div className="error">
                          {formik?.errors.inletPressure}  
                        </div>
                      ) : null} */}
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="allowedPressureDrop">Allowed Pressure Drop</label>
              <div className="row d-flex">
                <div className="col">
                  <input
                    type="text"
                    className="form-control mb-15"
                    readOnly
                    name="allowedPressureDrop"
                    id="allowedPressureDrop"
                    placeholder="Allowed Pressure"
                    value={formik?.values.allowedPressureDrop}
                    onChange={formik?.handleChange}
                  />
                  {formik &&
                  formik?.touched.allowedPressureDrop &&
                  formik &&
                  formik?.errors.allowedPressureDrop ? (
                    <div className="error">
                      {formik?.errors.allowedPressureDrop}
                    </div>
                  ) : null}
                </div>
                <Select
                  name="allowedPressureDropUnit"
                  id="allowedPressureDropUnit"
                  placeholder="Unit"
                  value={
                    formik.values.allowedPressureDropUnit
                      ? {
                          label: formik.values.allowedPressureDropUnit,
                          value: formik.values.allowedPressureDropUnit,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                  className="col"
                  isDisabled
                />
                {/* {formik &&
                    formik?.touched.allowedPressureDropUnit &&
                    formik &&
                    formik?.errors.allowedPressureDropUnit ? (
                      <div className="error">
                        {formik?.errors.allowedPressureDropUnit}
                      </div>
                    ) : null} */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-md-6">
              <label htmlFor="outletPressure">Outlet Pressure</label>
              <div className="row d-flex">
                <div className="mb-15 col">
                  <input
                    type="text"
                    className="form-control"
                    name="outletPressure"
                    id="outletPressure"
                    placeholder="Outlet Pressure"
                    value={formik?.values.outletPressure}
                    onChange={formik?.handleChange}
                  />
                  {formik &&
                  formik?.touched.outletPressure &&
                  formik &&
                  formik?.errors.outletPressure ? (
                    <div className="error">{formik?.errors.outletPressure}</div>
                  ) : null}
                </div>
                <Select
                  placeholder="Unit"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderColor:
                        formik &&
                        formik?.touched.outletPressure &&
                        formik &&
                        formik?.errors.outletPressure
                          ? "red"
                          : provided.borderColor,
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                  }}
                  value={
                    formik.values.outletPressureUnit
                      ? {
                          label: formik.values.outletPressureUnit,
                          value: formik.values.outletPressureUnit,
                        }
                      : null
                  }
                  onBlur={formik?.handleBlur}
                  isDisabled
                  className="col"
                />
                {/* {formik &&
            formik?.touched.outletPressure &&
            formik &&
            formik?.errors.outletPressure ? (
              <div className="error">
                {formik?.errors.outletPressure}
              </div>
            ) : null} */}
              </div>
            </div>
          </div>
          {selectedArea === "safe" && (
            <div className="col-md-6" style={{ gap: "20px" }}>
              <div className="mb-15">
                <b>
                  <label htmlFor="sensorselection">Sensor Selection</label>
                </b>

                <div className="d-flex row px-2 py-2">
                  <div className="row">
                    <input
                      type="radio"
                      id="sensorYes"
                      name="sensorSelection"
                      value="yes"
                      checked={formik?.values.sensorSelection === "yes"}
                      onChange={(e) =>
                        formik?.setFieldValue("sensorSelection", "yes")
                      }
                    />
                    <label htmlFor="sensorYes" className="">
                      Yes
                    </label>
                  </div>
                  <div className="row ml-5">
                    <input
                      type="radio"
                      id="sensorNo"
                      name="sensorSelection"
                      value="no"
                      className=""
                      checked={formik?.values.sensorSelection === "no"}
                      onChange={() =>
                        formik?.setFieldValue("sensorSelection", "no")
                      }
                    />
                    <label htmlFor="sensorNo" className="">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedArea === "hazardous" ||
          (selectedArea === "safe" &&
            formik?.values.sensorSelection === "yes") ? (
            <>
              {sensors.map((sensor, index) => (
                <div key={index}>
                  <div className="d-flex align-items-center">
                    <div className="col-md-3">
                      <b>
                        <label>{sensor.label}</label>
                      </b>
                    </div>
                    <div className="row d-flex px-2 py-2 ml-5">
                      <div className="row">
                        <input
                          type="radio"
                          id={`sensorselected-yes-${index}`}
                          name={`sensor[${index}].selected`}
                          value="yes"
                          checked={
                            formik.values.sensor[index].selected === "yes"
                          }
                          onChange={(e) =>
                            handleSensorChange(index, e.target.value)
                          }
                        />
                        <label htmlFor={`sensorselected-yes-${index}`}>
                          Yes
                        </label>
                      </div>
                      <div className="row ml-5">
                        <input
                          type="radio"
                          id={`sensorselected-no-${index}`}
                          name={`sensor[${index}].selected`}
                          value="no"
                          checked={
                            formik.values.sensor[index].selected === "no"
                          }
                          onChange={(e) =>
                            handleSensorChange(index, e.target.value)
                          }
                        />
                        <label htmlFor={`sensorselected-no-${index}`}>No</label>
                      </div>
                    </div>
                  </div>

                  {sensorVisibility[index] && (
                    <div className="d-flex justify-content-between">
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor="">Sensor Sub Type</label>
                          <Select
                            options={sensorsubtypeoptions}
                            name={`sensor[${index}].sensorsubtype`}
                            placeholder="Sensor Sub Type"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].sensorsubtype`,
                                option.value
                              )
                            }
                            value={
                              formik.values.sensor[index].sensorsubtype
                                ? {
                                    label:
                                      formik.values.sensor[index].sensorsubtype,
                                    value:
                                      formik.values.sensor[index].sensorsubtype,
                                  }
                                : null
                            }
                          />
                        </div>
                        <div className="col mb-15">
                          <label htmlFor="">Sensor Type</label>
                          <Select
                            options={sensorrangeoptions}
                            name={`sensor[${index}].sensorrange`}
                            placeholder="Sensor Type"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].sensorrange`,
                                option.value
                              )
                            }
                            value={
                              formik.values.sensor[index].sensorrange
                                ? {
                                    label:
                                      formik.values.sensor[index].sensorrange,
                                    value:
                                      formik.values.sensor[index].sensorrange,
                                  }
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor={`quantity-${index}`}>Quantity</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Quantity"
                            name={`sensor[${index}].quantity`}
                            id={`quantity-${index}`}
                            value={formik.values.sensor[index].quantity || ""}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor={`material-${index}`}>Material</label>
                          <CreatableSelect
                            id="sensor[${index}].material"
                            name={`sensor[${index}].material`}
                            options={sensorMaterialOptions}
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].material`,
                                option.value
                              )
                            }
                            value={
                              formik.values.sensor[index].material
                                ? {
                                    label: formik.values.sensor[index].material,
                                    value: formik.values.sensor[index].material,
                                  }
                                : null
                            }
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col mb-15">
                          <label htmlFor={`sensor[${index}].headMaterial`}>
                            Head Material
                          </label>
                          <Select
                            options={headMaterialOptions}
                            name={`sensor[${index}].headMaterial`}
                            placeholder="Head Material"
                            onChange={(option) =>
                              formik.setFieldValue(
                                `sensor[${index}].headMaterial`,
                                option.value
                              )
                            }
                            value={
                              formik.values.sensor[index].headMaterial
                                ? {
                                    label:
                                      formik.values.sensor[index].headMaterial,
                                    value:
                                      formik.values.sensor[index].headMaterial,
                                  }
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <div className="col-md-12 p-3 mb-15">
                <div>
                  <label htmlFor="">Temperature Transmitter type</label>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      value="Display"
                      checked={selectedTemperatureType === "Display"}
                      onChange={(e) => {
                        handleTemperatureType(e);
                        formik.setFieldValue(
                          "temptransmitter",
                          "4-20 mA LCD Display Type Enabled Heart protocol Smarttype sifi-2 Enabled"
                        );
                      }}
                    />
                    <label htmlFor="digital">Display</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      checked={selectedTemperatureType === "Not Display"}
                      value="Not Display"
                      onChange={(e) => {
                        handleTemperatureType(e);
                        formik.setFieldValue("temptransmitter", "");
                      }}
                    />
                    <label htmlFor="analog">Non Display</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="temperatureType"
                      name="temperatureType"
                      value="N"
                      checked={selectedTemperatureType === "N"}
                      onChange={handleTemperatureType}
                    />
                    <label htmlFor="none">None</label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-15">
                  {(selectedTemperatureType === "Display" ||
                    selectedTemperatureType === "Not Display") && (
                    <div className="col-md-6">
                      <label htmlFor="temperatureTransmitter">
                        Temperature Transmitter
                      </label>
                      {selectedTemperatureType === "Display" ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Temperature Transmitter"
                          name="temptransmitter"
                          id="temptransmitter"
                          readOnly
                          value={formik.values.temptransmitter}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      ) : (
                        <Select
                          name="temptransmitter"
                          placeholder="Temperature Transmitter"
                          id="temptransmitter"
                          options={[
                            {
                              label: "Diedrail Mountain",
                              value: "Diedrail Mountain",
                            },
                            { label: "Head Mountain", value: "Head Mountain" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue(
                              "temptransmitter",
                              option.value
                            )
                          }
                          value={
                            formik.values.temptransmitter
                              ? {
                                  label: formik.values.temptransmitter,
                                  value: formik.values.temptransmitter,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      )}
                      {formik.touched.temptransmitter &&
                        formik.errors.temptransmitter && (
                          <div className="error">
                            {formik.errors.temptransmitter}
                          </div>
                        )}
                    </div>
                  )}

                  {selectedTemperatureType === "Not Display" ? (
                    <div className="col-md-6">
                      <label htmlFor="certificationType">
                        Certification Type
                      </label>
                      <input
                        className="mb-15 form-control"
                        name="certificationType"
                        id="certificationType"
                        placeholder="Certification Type"
                        value={`${formik.values.certificationrequired} - ${formik.values.ijb} - ${formik.values.zone} - ${formik.values.gasgroup} - ${formik.values.tempCode} - ${formik.values.ipRating}`}
                        onChange={(option) =>
                          formik &&
                          formik?.setFieldValue("certificationType", option)
                        }
                        onBlur={formik?.handleBlur}
                        disabled
                      />
                    </div>
                  ) : null}
                  {selectedTemperatureType === "Display" && (
                    <div className="col-md-6">
                      <div className="mb-15">
                        <label htmlFor="tempTransIp">IP</label>
                        <Select
                          name="tempTransIp"
                          placeholder=" IP"
                          id="tempTransIp"
                          options={getIPRatingOptions(
                            formik.values.areaClassification
                          )}
                          onChange={(option) =>
                            formik?.setFieldValue("tempTransIp", option.value)
                          }
                          value={
                            formik.values.tempTransIp
                              ? {
                                  label: formik.values.tempTransIp,
                                  value: formik.values.tempTransIp,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransIJB">TT</label>
                        <Select
                          placeholder="Select TT"
                          name="tempTransIJB"
                          id="tempTransIJB"
                          options={[
                            { label: "Ex'd", value: "Ex'd" },
                            { label: "Ex'ia", value: "Ex'ia" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue("tempTransIJB", option.value)
                          }
                          onBlur={formik?.handleBlur}
                          value={
                            formik.values.tempTransIJB
                              ? {
                                  label: formik.values.tempTransIJB,
                                  value: formik.values.tempTransIJB,
                                }
                              : null
                          }
                        />
                        {formik &&
                        formik?.touched.ijb &&
                        formik &&
                        formik?.errors.ijb ? (
                          <div className="error">
                            {formik?.errors.tempTransIJB}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransTempCode">Temp. Code</label>
                        <Select
                          name="tempTransTempCode"
                          placeholder="Select Temp. Code"
                          id="tempTransTempCode"
                          options={[
                            { label: "T1", value: "T1" },
                            { label: "T2", value: "T2" },
                            { label: "T3", value: "T3" },
                            { label: "T4", value: "T4" },
                            { label: "T5", value: "T5" },
                            { label: "T6", value: "T6" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransTempCode",
                              option.value
                            )
                          }
                          value={
                            formik.values.tempTransTempCode
                              ? {
                                  label: formik.values.tempTransTempCode,
                                  value: formik.values.tempTransTempCode,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransCertificationRequired">
                          Certification Required
                        </label>
                        <Select
                          name="tempTransCertificationRequired"
                          placeholder="Select Certification"
                          id="tempTransCertificationRequired"
                          options={[
                            { label: "ATEX", value: "ATEX" },
                            { label: "IECX", value: "IECX" },
                            { label: "PESO", value: "PESO" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransCertificationRequired",
                              option.value
                            )
                          }
                          value={
                            formik.values.tempTransCertificationRequired
                              ? {
                                  label:
                                    formik.values
                                      .tempTransCertificationRequired,
                                  value:
                                    formik.values
                                      .tempTransCertificationRequired,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransZone">Zone</label>
                        <Select
                          name="tempTransZone"
                          placeholder="Select Zone"
                          id="tempTransZone"
                          options={[
                            { label: "Zone 1", value: "Zone 1" },
                            { label: "Zone 2", value: "Zone 2" },
                          ]}
                          onChange={(option) =>
                            formik?.setFieldValue("tempTransZone", option.value)
                          }
                          value={
                            formik.values.tempTransZone
                              ? {
                                  label: formik.values.tempTransZone,
                                  value: formik.values.tempTransZone,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>
                      <div className="mb-15">
                        <label htmlFor="tempTransGasGroup">Gas Group</label>
                        <Select
                          name="tempTransGasGroup"
                          placeholder="Select Gas Group"
                          id="tempTransGasGroup"
                          options={[
                            { label: "II A", value: "II A" },
                            { label: "II B", value: "II B" },
                            { label: "II C", value: "II C" },
                            { label: "II A/B", value: "II A/B" },
                          ]}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransGasGroup",
                              option.value
                            )
                          }
                          value={
                            formik.values.tempTransGasGroup
                              ? {
                                  label: formik.values.tempTransGasGroup,
                                  value: formik.values.tempTransGasGroup,
                                }
                              : null
                          }
                          onBlur={formik?.handleblur}
                        />
                      </div>

                      <div>
                        <label htmlFor="tempTransBoxMaterial">
                          Enclosure Material
                        </label>
                        <Select
                          name="tempTransBoxMaterial"
                          placeholder="Select Material"
                          id="tempTransBoxMaterial"
                          options={headMaterialOptions}
                          onChange={(option) =>
                            formik &&
                            formik?.setFieldValue(
                              "tempTransBoxMaterial",
                              option.value
                            )
                          }
                          value={
                            formik.values.tempTransBoxMaterial
                              ? {
                                  label: formik.values.tempTransBoxMaterial,
                                  value: formik.values.tempTransBoxMaterial,
                                }
                              : null
                          }
                          onBlur={formik?.handleBlur}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Screen2;
