import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BOM from "./BOM";

function Screen9({ formik }) {
  const trClass = {
    borderCollapse: "collapse",
    height: "22px",
  };

  const tdClass = {
    borderCollapse: "collapse",
    border: "1px solid black",
    height: "22px",
    padding: "0 4px",
    minWidth: "20px",
  };

  const tableClass = {
    width: "100%",
    border: "1px solid black",
    borderCollapse: "collapse",
  };

  const tableParentDiv = {
    display: "flex",
    margin: "10px",
    gap: "10px",
  };

  const printPDF = (elementId) => {
    const input = document.getElementById(elementId);
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("download.pdf");
    });
  };

  const powerelement = () => {
    const totalnumberofelements = formik?.values?.totalnumberofelements || 0;
    const power = formik?.values?.power || 0;
    const powerperelement = totalnumberofelements
      ? Math.round(power / totalnumberofelements)
      : 0;
    formik.setFieldValue("powerperelement", powerperelement);
  };

  const calculateHeatingElementAddValue = (
    thickness,
    distance,
    totalnumberofelements
  ) => {
    return Math.round(
      ((5 * ((thickness + 30 + distance) / 5) * 2 * totalnumberofelements) /
        1000 /
        totalnumberofelements /
        2) *
        1000
    );
  };

  const setHeatingElementValues = (values) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const heatingElement = () => {
    const {
      fixingofheatingelements,
      totalnumberofelements,
      heatingElementThickness,
      distbwheaterflangetoterminalbox,
      heatersheathtubedia,
    } = formik.values;
    const totalElements = parseInt(totalnumberofelements) || 0;
    const thickness = parseFloat(heatingElementThickness) || 0;
    const distance = parseInt(distbwheaterflangetoterminalbox) || 0;

    const commonValues = {
      Sleeves: {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      "Tig + Sleeves": {
        heatingElementType: parseFloat(heatersheathtubedia) + 0.5,
        heatingElementValue: "ID",
        heatingElementUnit: parseFloat(heatersheathtubedia) + 0.5 + 4,
        heatingElememtAddUnit: "OD",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "Drilling & Sleeves Welding",
      },
      Ferrule: {
        heatingElementType: "FOR",
        heatingElementValue: heatersheathtubedia,
        heatingElementUnit: "MM",
        heatingElememtAddUnit: "DIA",
        heatingElementAddValue: "HEATING ELEMENT",
        heatingElementAddValueUnit: "TING ELEMENT",
        heatingElementTubeSheet: "False",
      },
      Bush: {
        heatingElementType: "Bush",
        heatingElementValue: " '' ",
        heatingElementUnit: " ",
        heatingElememtAddUnit: "BUSH",
        heatingElementAddValue: calculateHeatingElementAddValue(
          thickness,
          distance,
          totalElements
        ),
        heatingElementAddValueUnit: "MM LONG",
        heatingElementTubeSheet: "False",
      },
      Direct: {
        heatingElementType: heatersheathtubedia,
        heatingElementValue: "DIA",
        heatingElementUnit: "FUSED",
        heatingElememtAddUnit: "DIRECT",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Grooving for Direct Fusing",
      },
      Nut: {
        heatingElementType: "2.25",
        heatingElementValue: "Nut",
        heatingElementUnit: " ",
        heatingElememtAddUnit: " ",
        heatingElementAddValue: " ",
        heatingElementAddValueUnit: " ",
        heatingElementTubeSheet: "Drilling & Collar Welding",
      },
    };

    const defaultValues = {
      heatingElementType: " ",
      heatingElementValue: " ",
      heatingElementUnit: " ",
      heatingElememtAddUnit: " ",
      heatingElementAddValue: " ",
      heatingElementAddValueUnit: " ",
      heatingElementTubeSheet: "False",
    };

    setHeatingElementValues(
      commonValues[fixingofheatingelements] || defaultValues
    );
  };

  useEffect(() => {
    powerelement();
    heatingElement();
  }, [formik.values.power, formik.values.totalnumberofelements]);

  return (
    <div>
      <button
        className="btn btn-outline-danger"
        style={{ margin: "10px 70px " }}
        onClick={(e) => {
          e.preventDefault();
          printPDF("yes-vessel");
        }}
      >
        Print PDF
      </button>
      <div id="yes-vessel" style={{ color: "black", padding: " 80px 70px" }}>
        <BOM
          formik={formik}
          trClass={trClass}
          tdClass={tdClass}
          tableClass={tableClass}
          tableParentDiv={tableParentDiv}
        />
      </div>
      <hr />
    </div>
  );
}

export default Screen9;
