import { Padding } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useStepContext } from "@mui/material";

function BOM({ formik, tableParentDiv, tableClass, tdClass, trClass }) {
  const bold = {
    fontWeight: "bold",
  };

  const [Bom_s_no_01_net_wt, setBom_S_no_01_net_wt] = useState("abc");

  let B10 = formik?.values?.fixingofheatingelements;
  let R10 = formik?.values?.heatingElementUnit;
  let O10 = formik?.values?.heatingElementType;
  let V10 = formik?.values?.heatingElementAddValue;
  let AF10 = formik?.values?.totalnumberofelements * 2;

  function s_no_01_net_wt(B10, R10, O10, V10, AF10) {
    if (B10 === "Sleeves" || B10 === "Tig + Sleeves") {
      const result =
        (((((R10 * R10 - O10 * O10) * 3.14) / 4) * V10 * 8) / 1000000) * AF10;
      return Math.round(result * 100) / 100;
    } else {
      return "N/A";
    }
  }

  const [tube_size_nt_wt, set_tube_size_nt_wt] = useState(0);
  const [shell_ring_nt_wt, set_shell_ring_nt_wt] = useState(0);
  const [shell_vessel_nt_wt, set_shell_vessel_nt_wt] = useState(0);

  function fetchPineSize() {
    axiosPrivate
      .post("/get/flange-weight", {
        flange_rating: formik.values.FlangeClass,
        nps: formik.values.flangeSize.toString(),
      })
      .then((response) => {
        set_tube_size_nt_wt(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function fetchShellRingNetWt() {
    axiosPrivate
      .post("/get/flange-weight-in-kg", {
        flange_type: formik?.values?.vesselrangetype,
        pipe_size: formik?.values?.flangeSize,
        flange_rating: formik?.values?.FlangeClass.toString(),
      })
      .then((response) => {
        set_shell_ring_nt_wt(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function fetchShellVesselNetWt() {
    axiosPrivate
      .post("/get/pipe-weight", {
        sch: parseInt(formik?.values?.schedule),
        nps: parseFloat(formik.values.flangeSize),
      })
      .then((response) => {
        set_shell_vessel_nt_wt(response.data.data);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  useEffect(() => {
    const data = s_no_01_net_wt(B10, R10, O10, V10, AF10);
    setBom_S_no_01_net_wt(data);
    fetchPineSize();
    fetchShellRingNetWt();
    fetchShellVesselNetWt();
  }, []);

  const updateTableRows = () => {
    const checkup = formik?.values?.vesselSelection === "yes";
    let visibleRowCount = 0;

    for (let i = 0; i < 43; i++) {
      const row = document.getElementById(`row${i + 1}`);

      if (!row) continue;

      if (checkup) {
        row.style.display = "";
      } else {
        if (i >= 7 && i < 17) {
          row.style.display = "none";
        } else {
          row.style.display = "";
        }
      }

      if (row.style.display !== "none") {
        visibleRowCount++;
        row.innerHTML = visibleRowCount;
      }
    }
  };

  useEffect(() => {
    updateTableRows();
  }, [formik?.values?.vesselSelection]);
  return (
    <div style={{ margin: "10px 0" }}>
      <table style={tableClass}>
        <tr style={trClass}>
          <th colSpan="9" style={{ ...tdClass, fontSize: "20px" }}>
            <div align="center">HEATING UNIT BILL OF MATERIAL </div>
          </th>
          <th colSpan="7" style={{ ...tdClass, fontSize: "20px" }}>
            <div align="center">RATES OF MATERIAL TAKEN FOR COSTING </div>
          </th>
          <th width="13%" style={tdClass}>
            &nbsp;
          </th>
        </tr>
        <tr style={{ ...trClass }}>
          <th width="4%" rowSpan="4" style={{ ...tdClass }}>
            &nbsp;&nbsp;
          </th>
          <td
            colSpan="8"
            style={{ ...tdClass, ...bold, borderBottom: "none" }}
            align="center"
          >
            <span style={{ padding: "0px 20px" }}>
              {formik?.values?.heatDutyKW || "N/A"} KW
            </span>
            <span style={{ padding: "0px 20px" }}>
              {formik?.values?.voltage || "N/A"} V
            </span>
            <span style={{ padding: "0px 20px" }}>HEATING UNIT</span>
          </td>
          <td width="10%" rowSpan="4" align="right" style={tdClass}>
            <div>Powder</div>
            <div>Tube</div>
            <div>FABRICATION</div>
            <div>FABRICATION</div>
            <div>{formik?.values?.fixingofheatingelements || "N/A"}</div>
            <div>2.5" NUT</div>
          </td>
          <td width="3%" rowSpan="4" style={tdClass}></td>
          <td width="4%" rowSpan="4" style={tdClass}>
            <div>Mgo</div>
            <div>{formik?.values?.sheathmaterial || "N/A"}</div>
            <div>CS</div>
            <div>CS</div>
            <div>{formik?.values?.materialofsleevesbush || "N/A"}</div>
            <div>CS</div>
          </td>
          <td width="6%" rowSpan="4" style={tdClass}>
            <div>&nbsp;</div>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>{formik?.values?.heatersheathtubedia || "N/A"}</div>
            <div>2.25"</div>
          </td>
          <td width="4%" rowSpan="4" style={{ ...tdClass, minWidth: "70px" }}>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
            <div>@&#39; RS</div>
          </td>
          <td width="3%" rowSpan="4" style={tdClass}>
            <div>400</div>
            <div>350</div>
            <div>700</div>
            <div>600</div>
            <div>550</div>
            <div>550</div>
          </td>
          <td width="7%" rowSpan="4" style={tdClass}>
            <div>/KG</div>
            <div>/M</div>
            <div>/KG</div>
            <div>/KG</div>
            <div>/EACH</div>
            <div>/EACH</div>
          </td>
          <td rowSpan="4" style={tdClass}>
            <div style={{ borderBottom: "1px solid black" }}>Powder</div>
            <div style={{ borderBottom: "1px solid black" }}>Tube</div>
            <div style={{ borderBottom: "1px solid black" }}>FABRICATION</div>
            <div style={{ borderBottom: "1px solid black" }}>FABRICATION</div>
            <div style={{ borderBottom: "1px solid black" }}>
              {formik?.values?.fixingofheatingelements || "N/A"}
            </div>
            <div>2.5" NUT</div>
          </td>
          {/* Rest of the rows remain unchanged */}
        </tr>
        <tr style={trClass}>
          <th
            colSpan="8"
            style={{ ...tdClass, borderTop: "none", borderBottom: "none" }}
          >
            <span style={{ paddingRight: "15px" }}>Connection Type -</span>
            <span style={{ padding: "0px 12px" }}>
              {formik?.values?.connection || "N/A"}
            </span>
            <span style={{ padding: "0px 12px" }}>HOT'U'</span>
            <span style={{ padding: "0px 12px" }}>
              {formik?.values?.maxactivehotlength || "N/A"}
            </span>
            <span style={{ padding: "0px 12px" }}>MM</span>
          </th>
        </tr>
        <tr style={trClass}>
          <td
            rowSpan="2"
            colSpan="6"
            style={{
              ...tdClass,
              ...bold,
              borderTop: "none",
              borderRight: "none",
            }}
            align="center"
          >
            <div style={{ borderBottom: "2px solid black", width: "70%" }}>
              <span style={{ padding: "0 4px" }}>
                {formik?.values?.totalnumberofelements || "N/A"} X R
              </span>
              <span style={{ padding: "0 4px" }}>12440</span>
              <span style={{ padding: "0 4px" }}>465</span>
              <span style={{ padding: "0 4px" }}>11510</span>
              <span style={{ padding: "0 4px" }}>
                {formik?.values?.sheathmaterial || "N/A"}
              </span>
            </div>
            <div>
              <span>{formik?.values?.voltage || "N/A"} V </span>
              <span>{formik?.values?.powerperelement || "N/A"} W </span>
            </div>
          </td>
          <td
            width="10%"
            rowSpan="2"
            style={{
              ...tdClass,
              borderRight: "none",
              borderLeft: "none",
              borderTop: "none",
            }}
          >
            {formik?.values?.heatersheathtubedia || "N/A"} MM
          </td>
          <td
            style={{ ...tdClass, borderLeft: "none", borderTop: "none" }}
            rowSpan="2"
          >
            DIA
          </td>
        </tr>
        <tr style={{ ...trClass }}></tr>

        <tr style={trClass}>
          <td style={tdClass}>
            <div align="center">
              <strong>S.NO</strong>
            </div>
          </td>
          <td colSpan="4" style={tdClass}>
            <strong>NAME OF ITEM</strong>
          </td>
          <td colSpan="4" style={tdClass}>
            <strong>SPECIFICATION</strong>
          </td>
          <td style={tdClass}>
            <strong>MATERIAL</strong>
          </td>
          <td style={tdClass}>
            <strong>QTY</strong>
          </td>
          <td style={tdClass}>
            <strong>NET WT.</strong>
          </td>
          <td style={tdClass}>
            <strong>G WT.</strong>
          </td>
          <td style={tdClass}>
            <strong>UNIT</strong>
          </td>
          <td colSpan="2" style={tdClass}>
            <strong>PRICE(RS)</strong>
          </td>
          <td style={tdClass}>
            <strong>ITEM NAME</strong>
          </td>
        </tr>
        {/* --1 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row1"></td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.fixingofheatingelements || "N/A"} (I.S L68)
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.heatingElementType || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElementValue || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElementUnit || "N/A"} &nbsp;&nbsp;
            {formik?.values?.heatingElememtAddUnit} &nbsp;&nbsp;
            {formik?.values?.heatingElementAddValue || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.materialofsleevesbush || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "Nut"
              ? formik?.values?.nutsize
              : formik?.values?.totalnumberofelements * 2}
          </td>
          <td style={tdClass} align="center">
            {Bom_s_no_01_net_wt}
          </td>
          <td style={tdClass} align="center">
            {Bom_s_no_01_net_wt}
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "Sleeves"
              ? "KG"
              : "No."}
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>
            {formik?.values?.fixingofheatingelements || "N/A"}
          </td>
        </tr>
        {/* --2 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row2"></td>
          <td colSpan="4" style={tdClass}>
            HEATING ELEMENT (FIXING INCLUDED)
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.powerperelement || "N/A"} &amp;&amp;
            {formik?.values?.heatersheathtubedia} &amp;&amp;
            {formik?.values?.sheathmaterial || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">{formik?.values?.sheathmaterial || "N/A"}</div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "Nut"
              ? formik?.values?.nutsize * 3
              : formik?.values?.totalnumberofelements}
          </td>
          <td style={tdClass} align="center">
            N/A
          </td>
          <td style={tdClass} align="center">
            N/A
          </td>
          <td style={tdClass} align="center">
            RS
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>HEATING ELEMENT (FIXING INCLUDED) </td>
        </tr>
        {/* --3 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row3"></td>
          <td colSpan="4" style={tdClass}>
            TUBE SHEET (HEATER FLANGE)
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.tubesheetsize || "N/A"} &amp;&amp; " BLIND, AS PER
            ANSI 16.5, &amp;&amp; {formik?.values?.FlangeClass || "N/A"} lb
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.FlangeMaterialCode || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {formik.values.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {tube_size_nt_wt || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {(tube_size_nt_wt * 1.4).toFixed(2) || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>TUBE SHEET (HEATER FLANGE)</td>
        </tr>
        {/* --4 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row4"></td>

          <td colSpan="4" style={tdClass}>
            TUBESHEET DRILLING, SLEEVE TIG WELDING, DIRECTWELD GROOVING/COLLAR
            FOR NUT
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.heatingElementTubeSheet}
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.FlangeMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.fixingofheatingelements === "Nut"
              ? formik?.values?.nutsize
              : formik?.values?.totalnumberofelements * 2}
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            &nbsp; No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>
            TUBESHEET DRILLING, SLEEVE TIG WELDING, DIRECTWELD GROOVING/COLLAR
            FOR NUT
          </td>
        </tr>
        {/* --5 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row5"></td>

          <td colSpan="4" style={tdClass}>
            SHELL RING
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;{formik?.values?.tubesheetsize || "N/A"} " BLIND, AS PER ANSI
            16.5, &amp;&amp; {formik?.values?.FlangeClass || "N/A"} lb
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.FlangeMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik.values.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {shell_ring_nt_wt || "N/A"}
          </td>

          <td style={tdClass} align="center">
            {(shell_ring_nt_wt * 1.4).toFixed(2) || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>SHELL RING</td>
        </tr>
        {/* --6 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row6"></td>

          <td colSpan="4" style={tdClass}>
            SHELL / VESSEL
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;{formik?.values?.tubesheetsize || "N/A"} " NB THK.
            {formik?.values?.shellThickness || "N/A"} MM
            {formik?.values?.schedule || "N/A"} SCH
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik?.values?.vesselMaterialCode || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik.values.multistackvessel || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {shell_vessel_nt_wt || "N/A"}
          </td>
          <td style={tdClass} align="center">
            {shell_vessel_nt_wt || "N/A"}
          </td>
          <td style={tdClass} align="center">
            KG
          </td>

          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>SHELL / VESSEL</td>
        </tr>
        {/* --7 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row7"></td>

          <td colSpan="4" style={tdClass}>
            DISH END
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;FOR A &nbsp; &nbsp; {formik?.values?.tubesheetsize || "N/A"} "
            &nbsp;DIA SHELL
          </td>
          <td style={tdClass}>
            <div align="center">{formik?.values?.reducers || "N/A"}</div>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>

          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>DISH END</td>
        </tr>

        {formik?.values?.vesselSelection === "yes" && (
          <>
            {/* --8 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row8"
                className="conditionalRow"
              ></td>

              <td colSpan="4" style={tdClass}>
                INLET NOZZEL
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp;{formik?.values?.nozzle[0]?.size || "N/A"} &nbsp;" NB, SCH
                &nbsp;&nbsp;&nbsp;{" "}
                {formik?.values?.nozzle[0]?.schedules || "N/A"}
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.vesselMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[0].inletNozzle === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>

              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>INLET NOZZEL</td>
            </tr>
            {/* --9 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row9"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                INLET FLANGES
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[0]?.size || "N/A"} &nbsp; "
                &nbsp;
                {formik?.values?.nozzle[0]?.size > 24
                  ? "AS PER ANSI 16.47"
                  : "AS PER ANSI 16.5"}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {formik?.values?.nozzle[0]?.flangeRating || "N/A"} &nbsp; lb
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.FlangeMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[0].inletNozzle === true
                  ? formik?.values?.nozzle[0].counterFlange === true
                    ? "2"
                    : "1"
                  : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>

              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>INLET FLANGES</td>
            </tr>
            {/* --10 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row10"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                OUTLET NOZZEL
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[1]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;&nbsp;&nbsp;{" "}
                {formik?.values?.nozzle[1]?.schedules || "N/A"}
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.vesselMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[1].outletNozzle === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>

              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>OUTLET NOZZEL</td>
            </tr>
            {/* --11 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row11"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                OUTLET FLANGES
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[1]?.size || "N/A"} &nbsp; "
                &nbsp;
                {formik?.values?.nozzle[1]?.size > 24
                  ? "AS PER ANSI 16.47"
                  : "AS PER ANSI 16.5"}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {formik?.values?.nozzle[1]?.flangeRating || "N/A"} &nbsp; lb
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.FlangeMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[1].outletNozzle === true
                  ? formik?.values?.nozzle[1].counterFlange === true
                    ? "2"
                    : "1"
                  : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>OUTLET FLANGES</td>
            </tr>
            {/* --12 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row12"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                DRAIN NOZZLE
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[3]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;&nbsp;&nbsp;{" "}
                {formik?.values?.nozzle[3]?.schedules || "N/A"}
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.vesselMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[3].drain === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>{" "}
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>DRAIN NOZZLE</td>
            </tr>
            {/* --13 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row13"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                DRAIN NOZZLE FLANGE
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[3]?.size || "N/A"} &nbsp; "
                &nbsp;
                {formik?.values?.nozzle[3]?.size > 24
                  ? "AS PER ANSI 16.47"
                  : "AS PER ANSI 16.5"}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {formik?.values?.nozzle[3]?.flangeRating || "N/A"} &nbsp; lb
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.FlangeMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[3].drain === true
                  ? formik?.values?.nozzle[3].counterFlange === true
                    ? "2"
                    : "1"
                  : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>DRAIN NOZZLE FLANGE</td>
            </tr>
            {/* --14 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row14"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                VENT NOZZLE
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[2]?.size || "N/A"} &nbsp;" NB,
                SCH &nbsp;&nbsp;&nbsp;{" "}
                {formik?.values?.nozzle[2]?.schedules || "N/A"}
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.vesselMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[2].vent === true ? "1" : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>VENT NOZZLE</td>
            </tr>
            {/* --15 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row15"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                VENT NOZZLE FLANGE
              </td>
              <td colSpan="4" style={tdClass}>
                &nbsp; {formik?.values?.nozzle[2]?.size || "N/A"} &nbsp; "
                &nbsp;
                {formik?.values?.nozzle[2]?.size > 24
                  ? "AS PER ANSI 16.47"
                  : "AS PER ANSI 16.5"}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {formik?.values?.nozzle[2]?.flangeRating || "N/A"} &nbsp; lb
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.FlangeMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass} align="center">
                {formik?.values?.nozzle[2].vent === true
                  ? formik?.values?.nozzle[2].counterFlange === true
                    ? "2"
                    : "1"
                  : "N/A"}
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>VENT NOZZLE FLANGE</td>
            </tr>
            {/* --16 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row16"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                DUMMY NOZZLES
              </td>
              <td colSpan="4" style={tdClass}>
                Size &amp;&amp; SCH (due)
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.vesselMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>DUMMY NOZZLES</td>
            </tr>
            {/* --17 */}
            <tr style={trClass}>
              <td
                style={tdClass}
                align="center"
                id="row17"
                className="conditionalRow"
              ></td>
              <td colSpan="4" style={tdClass}>
                DUMMY NOZZLE FLANGES
              </td>
              <td colSpan="4" style={tdClass}>
                size &amp;&amp; IF ( O26 &gt; 24 , "AS PER ANSI 16.47" , "AS PER
                ANSI 16.5" ) &amp;&amp; rating
              </td>
              <td style={tdClass}>
                <div align="center">
                  {formik?.values?.FlangeMaterialCode || "N/A"}
                </div>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass}>
                <span className="text-danger">XXX</span>
              </td>
              <td style={tdClass} align="center">
                KG
              </td>
              <td colSpan="2" style={tdClass}>
                <div align="right">
                  <span className="text-danger">XXX</span>
                </div>
              </td>
              <td style={tdClass}>DUMMY NOZZLE FLANGES</td>
            </tr>
          </>
        )}
        {/* --18 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row18"></td>
          <td colSpan="4" style={tdClass}>
            BAFFLE
          </td>
          <td colSpan="4" style={tdClass}>
            {Math.round(formik?.values?.baffleOD || "N/A")}&nbsp;MM
            &nbsp;DIA,&nbsp;PLATE &nbsp;
            {formik?.values?.baffleplatethickness || "N/A"} MM THICK &nbsp;
            {formik?.values?.bafflematerial || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center"> {formik?.values?.bafflematerial || "N/A"}</div>
          </td>
          <td style={tdClass} align="center">
            {formik?.values?.numberofbaffles || "N/A"}
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>BAFFLE</td>
        </tr>
        {/* --19 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row19"></td>

          <td colSpan="4" style={tdClass}>
            TIE ROD WITH SLEEVES
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.tieroddia || "N/A"}&nbsp;MM DIA, &nbsp;&nbsp;&nbsp;
            {formik?.values?.bafflematerial || "N/A"}
          </td>
          <td style={tdClass}>
            <p align="center">{formik?.values?.tiematerial || "N/A"}</p>
          </td>
          <td style={tdClass} align="center">
            {formik.touched.tierodquantity || "N/A"}
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>TIE ROD WITH SLEEVES</td>
        </tr>
        {/* --20 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row20"></td>

          <td colSpan="4" style={tdClass}>
            LUGS SUPPORT
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.installationPosition || "N/A"}
          </td>
          <td style={tdClass}>
            <div align="center">{formik.values.sadlemoc || "N/A "}</div>
          </td>
          <td style={tdClass} align="center">
            {formik.values.multistackvessel || "N/A"}
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>LUGS SUPPORT</td>
        </tr>
        {/* --21 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row21"></td>

          <td colSpan="4" style={tdClass}>
            JUNCTION BOX
          </td>
          <td colSpan="4" style={tdClass}>
            I.S G84
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik.values.heaterterminalboxmaterial || "N/A"}
            </div>
          </td>
          <td style={tdClass} align="center">
            {formik.values.multistackvessel || "N/A"}
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>JUNCTION BOX </td>
        </tr>
        {/* --22 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row22"></td>

          <td colSpan="4" style={tdClass}>
            TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP
          </td>
          <td colSpan="4" style={tdClass}>
            = IF ( 'Input Sheet'!G84 = "FLP" , "20 MM THICK BASE PLATE WITH
            SLEEVE WELDING" , "150 MM LONG 3 MM THICK TERMINAL COVER" )
          </td>
          <td style={tdClass}>
            <div align="center">
              {formik.values.heaterterminalboxmaterial || "N/A"}
            </div>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            KG
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>
            TER. BOX BASE PLATE IF FLP or TER BOX IF NON FLP
          </td>
        </tr>
        {/* --23 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row23"></td>
          <td colSpan="4" style={tdClass}>
            CABLE GLANDS
          </td>
          <td colSpan="4" style={tdClass}>
            I.S G100 &amp;&amp; I.S G101
          </td>
          <td style={tdClass}>
            <div align="center">I.S 101</div>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            -
          </td>{" "}
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            No.
          </td>{" "}
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>CABLE GLANDS</td>
        </tr>
        {/* --24 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row24"></td>

          <td colSpan="4" style={tdClass}>
            THERMOWELL
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;
          </td>
          <td style={tdClass}>
            <div align="center">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>THERMOWELL</td>
        </tr>
        {/* --25 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row25"></td>

          <td colSpan="4" style={tdClass}>
            SAFETY THERMOCOUPLE
          </td>
          <td colSpan="4" style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>SAFETY THERMOCOUPLE</td>
        </tr>
        {/* --26 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row26"></td>

          <td colSpan="4" style={tdClass}>
            PROCESS THERMOCOUPLE
          </td>
          <td colSpan="4" style={tdClass}>
            Thermowell material I.S G90
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass}>
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>PROCESS THERMOCOUPLE</td>
        </tr>
        {/* --27 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row27"></td>

          <td colSpan="4" style={tdClass}>
            CONNECTION
          </td>
          <td colSpan="4" style={tdClass}>
            {formik?.values?.connection || "N/A"} &nbsp; &nbsp;
            {formik?.values?.bank || "N/A"}
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>CONNECTION</td>
        </tr>
        {/* --28 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row28"></td>

          <td colSpan="4" style={tdClass}>
            HARDWARE
          </td>
          <td colSpan="4" style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            No.
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>HARDWARE</td>
        </tr>
        {/* --29 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row29"></td>

          <td colSpan="4" style={tdClass}>
            GASKETS
          </td>
          <td colSpan="4" style={tdClass}>
            I.S G97 Gasket type
          </td>
          <td style={tdClass}>
            <div align="center">I.S G97</div>
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>GASKETS</td>
        </tr>
        {/* --30 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row30"></td>

          <td colSpan="4" style={tdClass}>
            INSULATION
          </td>
          <td colSpan="4" style={tdClass}>
            = IF ( &apos;Input Sheet&apos;!G88 = 0 , &quot;N/A&quot; ,
            &apos;Input Sheet&apos;!G88 )
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            M3
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>INSULATION</td>
        </tr>
        {/* --31 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row31"></td>
          <td colSpan="4" style={tdClass}>
            RADIOGRAPHY
          </td>
          <td colSpan="4" style={tdClass}>
            I.S G98
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>{" "}
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            %
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>RADIOGRAPHY</td>
        </tr>
        {/* --32 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row32"></td>

          <td colSpan="4" style={tdClass}>
            PAINTING
          </td>
          <td colSpan="4" style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            %
          </td>
          <td colSpan="2" style={tdClass} align="center">
            -
          </td>
          <td style={tdClass}>PAINTING</td>
        </tr>
        {/* --33 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row33"></td>

          <td colSpan="4" style={tdClass}>
            MISC 1
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 1</td>
        </tr>
        {/* --34 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row34"></td>

          <td colSpan="4" style={tdClass}>
            <p>MISC 2</p>
          </td>
          <td colSpan="4" style={tdClass}>
            &nbsp;
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 2</td>
        </tr>
        {/* --35 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row36"></td>

          <td colSpan="4" style={tdClass}>
            MISC 3
          </td>
          <td colSpan="4" style={tdClass}>
            MISC (DESIGN CALN, SPECIAL TESTINGS ETC.)
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 3</td>
        </tr>
        {/* --36 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row36"></td>
          <td colSpan="4" style={tdClass}>
            <p>MISC 4</p>
          </td>
          <td colSpan="4" style={tdClass}>
            INSPECTION
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 4</td>
        </tr>
        {/* --37 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row37"></td>

          <td colSpan="4" style={tdClass}>
            MISC 5
          </td>
          <td colSpan="4" style={tdClass}>
            INSTRUMENTS (TEMP, FLOW SENSORS ETC.)
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 5</td>
        </tr>
        {/* --38 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row38"></td>

          <td colSpan="4" style={tdClass}>
            <p>MISC 6</p>
          </td>
          <td colSpan="4" style={tdClass}>
            CONTROL PANEL
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 6</td>
        </tr>
        {/* --39 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row39"></td>

          <td colSpan="4" style={tdClass}>
            MISC 7
          </td>
          <td colSpan="4" style={tdClass}>
            E &amp; C
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 7</td>
        </tr>
        {/* --40 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row40"></td>

          <td colSpan="4" style={tdClass}>
            MISC 8
          </td>
          <td colSpan="4" style={tdClass}>
            SPARES-HEATER
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 8</td>
        </tr>
        {/* --41 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row41"></td>

          <td colSpan="4" style={tdClass}>
            MISC 9
          </td>
          <td colSpan="4" style={tdClass}>
            SPARES-CONTROL PANEL
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 9</td>
        </tr>
        {/* --42 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row42"></td>

          <td colSpan="4" style={tdClass}>
            MISC 10
          </td>
          <td colSpan="4" style={tdClass}>
            LOCAL PUSH BUTTON STATION
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass} align="right">
            <span className="text-danger">XXX</span>
          </td>
          <td style={tdClass}>MISC 10</td>
        </tr>
        {/* --43 */}
        <tr style={trClass}>
          <td style={tdClass} align="center" id="row43"></td>

          <td colSpan="4" style={tdClass}>
            MISC 11
          </td>
          <td colSpan="4" style={tdClass}>
            OTHERS
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td style={tdClass} align="center">
            -
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>MISC 11</td>
        </tr>

        <tr style={trClass}>
          <td colSpan="5" rowSpan="2" style={tdClass} align="center">
            <strong>TOTAL MATERIAL COST </strong>
          </td>
          <td colSpan="2" rowSpan="2" style={tdClass}>
            Prepared By
          </td>
          <td colSpan="2" rowSpan="2" style={tdClass}>
            Approved By
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT MATERIAL COST
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= SUM ( AJ10:AJ52 ) </div>
          </td>
          <td style={tdClass}>UNIT MATERIAL COST</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" style={tdClass}>
            BUNDLE MATERIAL COST
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">
              <span className="text-danger">XXX</span>
            </div>
          </td>
          <td style={tdClass}>BUNDLE MATERIAL COST</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">MANUFACTURING COST @</div>
          </td>
          <td width="6%" colSpan="1" rowSpan="2" style={tdClass}>
            &nbsp;
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ53 * J55 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT COGS
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ53 + V55 </div>
          </td>
          <td style={tdClass}>UNIT COGS</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ54 * J55 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE COGS
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ54 + V56 </div>
          </td>
          <td style={tdClass}>BUNDLE COGS</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">MARGIN @ </div>
          </td>
          <td rowSpan="2" style={tdClass}>
            35%
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">( AJ55 / ( 1 - J57 ) ) - AJ55 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT SELLING PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ55 + V57 </div>
          </td>
          <td style={tdClass}>UNIT SELLING PRICE</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= ( AJ56 / ( 1 - J57 ) ) - AJ56 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE SELLING PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ56 + V58 </div>
          </td>
          <td style={tdClass}>BUNDLE SELLING PRICE</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="4" rowSpan="2" style={tdClass}>
            <div align="center">CONTINGENCY @ </div>
          </td>
          <td rowSpan="2" style={tdClass}>
            3%
          </td>
          <td colSpan="2" style={tdClass}>
            UNIT
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ57 * J59 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            UNIT QUOTED PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ57 + V59 </div>
          </td>
          <td style={tdClass}>UNIT QUOTED PRICE</td>
        </tr>
        <tr style={trClass}>
          <td colSpan="2" style={tdClass}>
            BUNDLE
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ58 * J59 </div>
          </td>
          <td colSpan="4" style={tdClass}>
            BUNDLE QUOTED PRICE
          </td>
          <td style={tdClass}>
            <div align="center">Rs</div>
          </td>
          <td colSpan="2" style={tdClass}>
            <div align="right">= AJ58 + V60 </div>
          </td>
          <td style={tdClass}>BUNDLE QUOTED PRICE</td>
        </tr>
        {/* Continue with other rows as before, wrapping deeply nested parts in new components as needed */}
      </table>
    </div>
  );
}

export default BOM;
