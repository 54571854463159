import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Check } from "@mui/icons-material";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ProcessFluidsList = () => {
  const [processFluids, setProcessFluids] = useState([]);
  const [editingFluidId, setEditingFluidId] = useState(null);
  const [newFluidName, setNewFluidName] = useState("");
  const [newFluidValue, setNewFluidValue] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();
  const [fluidId, setFluidId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("/process-fluids");
      if (response.status === 200) {
        setProcessFluids(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [navigate]);

  // const handleSave = async (fluidId) => {
  //   setIsLoading(true);
  //   try {
  //     await axiosPrivate.put(`/process-fluids/${fluidId}`, { name: newFluidName, initial_value: newFluidValue });
  //     setProcessFluids(processFluids.map((fluid) => (fluid.id === fluidId ? { ...fluid, name: newFluidName, initial_value: newFluidValue } : fluid)));
  //     setEditingFluidId(null);
  //     showSuccessToast("Process fluid updated successfully");
  //   } catch (error) {
  //     showErrorToast(`Error updating process fluid: ${error.message}`);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleDelete = async (fluidId) => {
    try {
      const response = await axiosPrivate.delete(`/process-fluids/${fluidId}`);
      if (response.status === 200) {
        setProcessFluids(processFluids.filter((fluid) => fluid.id !== fluidId));
        showSuccessToast("Process fluid deleted successfully");
      }
    } catch (error) {
      showErrorToast(`Error deleting process fluid: ${error.message}`);
    } finally {
      setFluidId(null);
    }
  };

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <Link to="/process-fluids/add" className="text-decoration-none">
          <button type="button" className="btn btn-primary">
            <i className="fe fe-plus mr-2" />
            Add
          </button>
        </Link>
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Global Search"
        />
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return editingFluidId === rowData.id ? (
      <InputText
        value={newFluidName}
        onChange={(e) => setNewFluidName(e.target.value)}
        autoFocus
        className="p-inputtext-sm w-100 mb-2 mt-2"
      />
    ) : (
      rowData.name
    );
  };

  const valueBodyTemplate = (rowData) => {
    return editingFluidId === rowData.id ? (
      <InputText
        value={newFluidValue}
        onChange={(e) => setNewFluidValue(e.target.value)}
        className="p-inputtext-sm w-100 mb-2 mt-2"
      />
    ) : (
      rowData.initial_value
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        {editingFluidId === rowData.id ? (
          <Check
            style={{ cursor: "pointer", fontSize: "25px", color: "green" }}
            // onClick={() => handleSave(rowData.id)}
          />
        ) : (
          <>
            <Link to={`/process-fluids/edit/${rowData.id}`}>
              <FaEdit
                color="green"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
            </Link>
            <FaTrash
              color="red"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginLeft: "10px",
              }}
              onClick={() => {
                setFluidId(rowData.id);
                setDeleteConfirmationVisible(true);
              }}
            />
          </>
        )}
      </div>
    );
  };

  // Function to render serial number
  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Process Fluids</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive m-2">
                <DataTable
                  value={processFluids}
                  dataKey="id"
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={filters.global.value}
                  emptyMessage="No process fluids found"
                >
                  <Column
                    body={serialNumberBodyTemplate}
                    header="S.No"
                    headerStyle={{ width: "10%" }}
                  />
                  <Column
                    field="name"
                    header="Fluid Name"
                    body={nameBodyTemplate}
                  />
                  <Column
                    field="initial_value"
                    header="Initial Value"
                    body={valueBodyTemplate}
                  />
                  <Column
                    field="action"
                    header="Actions"
                    body={actionBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            draggable={false}
            visible={deleteConfirmationVisible}
            onHide={() => setDeleteConfirmationVisible(false)}
            header="Confirmation"
            footer={
              <>
                <Button
                  label="Cancel"
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    setDeleteConfirmationVisible(false);
                    setFluidId(null);
                  }}
                />
                <Button
                  label="Delete"
                  className="btn btn-primary"
                  onClick={() => {
                    handleDelete(fluidId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this process fluid?</p>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ProcessFluidsList;
