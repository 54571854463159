import React, { useEffect } from "react";
import Select from "react-select";

const Screen8 = ({ formik }) => {
  return (
    <div>
      <div className="w-100 px-4 row ">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Flange Cost
        </label>
        <div className="mt-0 form d-block">
          <div className="">
            <div className="row w-100 mt-10 d-flex w-100">
              <div className="col-md-3">
                <label htmlFor="flangepipesize">Flange Pipe Size</label>
                <input
                  name="flangeSize"
                  id="flangeSize"
                  type="text"
                  className="form-control"
                  placeholder="Flange Pipe Size"
                  value={formik?.values?.flangeSize}
                  readOnly
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="flangePrice">Price</label>
                <input
                  name="flangePrice"
                  id="flangePrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.flangePrice || 0).toFixed(
                    2
                  )}
                  placeholder="0.00"
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="flangeTotalCost">Total Cost</label>
                <input
                  name="flangeTotalCost"
                  id="flangeTotalCost"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.flangeTotalCost || 0
                  ).toFixed(2)}
                  placeholder="Total Cost"
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="flangeWeight">Flange Weight</label>
                <input
                  name="flangeWeight"
                  id="flangeWeight"
                  type="text"
                  className="form-control"
                  placeholder="Flange Weight"
                  onChange={formik?.handleChange}
                  value={formik?.values?.flangeWeight}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 px-4 row">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Vessel Cost
        </label>
        <div className="mt-0 form d-block">
          <div className="">
            <div className="row w-100 d-flex mt-10">
              <div className="col-md-3 mt-15">
                <label htmlFor="vesselWeight">Vessel Weight</label>
                <input
                  name="vesselWeight"
                  id="vesselWeight"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values?.vesselWeight}
                  placeholder="0.00"
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="vesselMaterialCC">Vessel Material</label>
                <input
                  name="vesselMaterial"
                  id="vesselMaterial"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={formik?.values?.vesselMaterial}
                  placeholder="Vessel Material"
                  readOnly
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="vesselPrice">Price</label>
                <input
                  name="vesselPrice"
                  id="vesselPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(formik?.values?.vesselPrice || 0).toFixed(
                    2
                  )}
                  placeholder="0.00"
                />
              </div>
              <div className="col-md-3 mt-15">
                <label htmlFor="vesselTotalPrice">Total Cost</label>
                <input
                  name="vesselTotalPrice"
                  id="vesselTotalPrice"
                  type="text"
                  className="form-control"
                  onChange={formik?.handleChange}
                  value={parseFloat(
                    formik?.values?.vesselTotalPrice || 0
                  ).toFixed(2)}
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 px-4 row">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Sensor Cost
        </label>
        <div className="col-md-12">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr>
              <td style={{ padding: "10px" }}>
                <div className="row mt-4">
                  <label htmlFor="" style={{ fontWeight: "bold" }}></label>
                </div>
                <div className="mt-15">
                  <label htmlFor="skinSensor">Skin Sensor</label>
                  <input
                    name="sensor[0].sensorsubtype"
                    id="sensor[0].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="Skin Sensor"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[0].sensorsubtype}
                  />
                </div>
                <div className="mt-15">
                  <label htmlFor="tubeSensor">Tube Sensor</label>
                  <input
                    name="sensor[1].sensorsubtype"
                    id="sensor[1].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="Tube Sensor"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[1].sensorsubtype}
                  />
                </div>
                <div className="mt-15">
                  <label htmlFor="proceessSensor">Process Sensor</label>
                  <input
                    name="sensor[2].sensorsubtype"
                    id="sensor[2].sensorsubtype"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[2].sensorsubtype}
                  />
                </div>
              </td>
              <td style={{ padding: "10px" }}>
                <div className="row">
                  <label htmlFor="">Quantity</label>
                </div>
                <div className="mt-45">
                  <input
                    name="skinSensorQuantity"
                    id="skinSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[0]?.quantity}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="tubeSensorQuantity"
                    id="tubeSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[1]?.quantity}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="proceessSensorQuantity"
                    id="proceessSensorQuantity"
                    type="text"
                    className="form-control"
                    placeholder="0"
                    // onChange={formik?.handleChange}
                    // value={formik?.values?.sensor[2]?.quantity}
                  />
                </div>
              </td>
              <td style={{ padding: "10px" }}>
                <div className="row ">
                  <label htmlFor="">Total Cost</label>
                </div>
                <div className="mt-45">
                  <input
                    name="skinSensorTotalCost"
                    id="skinSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.skinSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="tubeSensorTotalCost"
                    id="tubeSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.tubeSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
                <div className="mt-45">
                  <input
                    name="proceessSensorTotalCost"
                    id="proceessSensorTotalCost"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.proceessSensorTotalCost || 0
                    ).toFixed(2)}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* Baffle Cost */}
      <div className="row w-100 mt-10 d-flex">
        <div className="w-100 px-4 row mt-50">
          <label htmlFor="" style={{ fontWeight: "bold" }}>
            Baffle Cost
          </label>
        </div>
        <div className="col-md-3 mt-15">
          <label htmlFor="baffleWeight">Baffle Weight</label>
          <input
            name="baffleWeight"
            id="baffleWeight"
            type="text"
            className="form-control"
            onChange={formik?.handleChange}
            value={parseFloat(formik?.values.baffleWeight || 0).toFixed(2)}
            placeholder="Baffle Weight"
          />
        </div>
        <div className="col-md-3 mt-15">
          <label htmlFor="bafflePrice">Baffle Price</label>
          <input
            name="bafflePrice"
            id="bafflePrice"
            type="text"
            className="form-control"
            onChange={formik?.handleChange}
            value={parseFloat(formik?.values.bafflePrice || 0).toFixed(2)}
            placeholder="Baffle Price"
          />
        </div>
        <div className="col-md-3 mt-15">
          <label htmlFor="totalCost">Total Cost</label>
          <input
            name="baffleTotalCost"
            id="baffleTotalCost"
            type="text"
            className="form-control"
            onChange={formik?.handleChange}
            value={parseFloat(formik?.values.baffleTotalCost || 0).toFixed(2)}
            placeholder="Total Cost"
          />
        </div>
      </div>
      <div className="row d-flex w-100">
        <div className="col-md-12">
          <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>
            Nozzle Flange Cost
          </h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px" }}></th>
                <th style={{ padding: "10px" }}>Pipe Size</th>
                <th style={{ padding: "10px" }}>Price</th>
                <th style={{ padding: "10px" }}>Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Inlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletNozzlePrice"
                    id="inletNozzlePrice"
                    type="text"
                    className="form-control w-60"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.inletNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Outlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual pipe size value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletNozzlePrice"
                    id="outletNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.outletNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Drain</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual pipe size value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainNozzlePrice"
                    id="drainNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.drainNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Vent</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual pipe size value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventNozzlePrice"
                    id="ventNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.ventNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Process</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual pipe size value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processNozzlePrice"
                    id="processNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.processNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Dummy</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual pipe size value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyNozzlePrice"
                    id="dummyNozzlePrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.dummyNozzlePrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row d-flex w-100">
        <div className="col-md-12">
          <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>
            Nozzle Pipe Size
          </h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px" }}></th>
                <th style={{ padding: "10px" }}>Length</th>
                <th style={{ padding: "10px" }}>Price</th>
                <th style={{ padding: "10px" }}>Total Cost</th>
                <th style={{ padding: "10px" }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Inlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletLength"
                    id="inletLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.inletLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="inletPrice"
                    id="inletPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.inletPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Outlet</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletLength"
                    id="outletLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.outletLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="outletPrice"
                    id="outletPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.outletPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Drain</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainLength"
                    id="drainLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.drainLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="drainPrice"
                    id="drainPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.drainPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Vent</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventLength"
                    id="ventLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.ventLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="ventPrice"
                    id="ventPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.ventPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Process</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processLength"
                    id="processLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.processLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="processPrice"
                    id="processPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(
                      formik?.values?.processPrice || 0
                    ).toFixed(2)}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  <p>Dummy</p>
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyLength"
                    id="dummyLength"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={formik?.values?.dummyLength}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <input
                    name="dummyPrice"
                    id="dummyPrice"
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    onChange={formik?.handleChange}
                    value={parseFloat(formik?.values?.dummyPrice || 0).toFixed(
                      2
                    )}
                  />
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p>
                  {/* Replace "0.00" with actual total cost value */}
                </td>
                <td style={{ padding: "10px" }}>
                  <p>0.00</p> {/* Replace "0.00" with actual weight value */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-100 px-4 row mt-30 ">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Tube Cost
        </label>
      </div>

      <div className="mt-0 form d-block">
        <div className="">
          <div className="row w-100 mt-10 d-flex w-100">
            <div className="col-md-3 mt-15">
              <label htmlFor="flangepipesize">Heating Element Price </label>
              <input
                name="heating_element_price"
                id="heating_element_price"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(
                  formik?.values?.heating_element_price || 0
                ).toFixed(2)}
                placeholder="Heating Element Price"
              />
            </div>
            <div className="col-md-3 mt-15">
              <label htmlFor="MGOPrice">MGO Price</label>
              <input
                name="MGOPrice"
                id="priMGOPricee"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(formik?.values?.MGOPrice || 0).toFixed(2)}
                placeholder="0.00"
              />
            </div>
            <div className="col-md-3 mt-15">
              <label htmlFor="ElementPrice">Element Price</label>
              <input
                name="ElementPrice"
                id="ElementPrice"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(formik?.values?.ElementPrice || 0).toFixed(2)}
                placeholder="Element Price"
              />
            </div>
            <div className="col-md-3 mt-15">
              <label htmlFor="HeatingElementTotalCost">Total Cost</label>
              <input
                name="HeatingElementTotalCost"
                id="HeatingElementTotalCost"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(
                  formik?.values?.HeatingElementTotalCost || 0
                ).toFixed(2)}
                placeholder="Total Cost"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 px-4 row mt-30 ">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Heating Element Fixture Cost
        </label>
      </div>

      <div className="mt-0 form d-block">
        <div className="">
          <div className="row w-100 mt-10 d-flex w-100">
            <div className="col-md-3 mt-15">
              <label htmlFor="HeatingElementFixturePrice">Price </label>
              <input
                name="HeatingElementFixturePrice"
                id="HeatingElementFixturePrice"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(
                  formik?.values?.HeatingElementFixturePrice || 0
                ).toFixed(2)}
                placeholder="Price"
              />
            </div>
            <div className="col-md-3 mt-15">
              <label htmlFor="HeatingElementFixtureTotalCost">Total Cost</label>
              <input
                name="HeatingElementFixtureTotalCost"
                id="HeatingElementFixtureTotalCost"
                type="text"
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(
                  formik?.values?.HeatingElementFixtureTotalCost || 0
                ).toFixed(2)}
                placeholder="Total Cost"
              />
            </div>
            <div className="col-md-3 mt-15">
              <label htmlFor="TerminalBoxPrice">Terminal Box Price</label>
              <Select
                placeholder="Select Size"
                name="TerminalBoxPrice"
                id="TerminalBoxPrice"
                styles={{ width: "50%" }}
                options={[{ value: "0.00", label: "0.00" }]}
                onChange={formik?.handleChange}
                value={formik?.values?.TerminalBoxPrice}
              />
            </div>
            <div className="col-md-3 mt-40">
              <input
                name="TerminalBoxPrice"
                id="TerminalBoxPrice"
                type="text"
                styles={{ width: "50%" }}
                className="form-control"
                onChange={formik?.handleChange}
                value={parseFloat(
                  formik?.values?.TerminalBoxPrice || 0
                ).toFixed(2)}
                placeholder="0.00"
              />
            </div>
            <div className="col-md-12 mt-15">
              <label htmlFor="atex">ATEX</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    id="atexYes"
                    name="atex"
                    value="yes"
                    className="form-check-input"
                    style={{ marginBottom: "10px", marginLeft: "100px" }}
                    onChange={formik?.handleChange}
                    checked={formik?.values?.atex === "yes"}
                  />
                  <label
                    htmlFor="atexYes"
                    className="form-check-label"
                    style={{ marginLeft: "5px" }}
                  >
                    Yes
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    id="atexNo"
                    name="atex"
                    value="no"
                    className="form-check-input"
                    style={{ marginBottom: "10px", marginLeft: "100px" }}
                    onChange={formik?.handleChange}
                    checked={formik?.values?.atex === "no"}
                  />
                  <label
                    htmlFor="atexNo"
                    className="form-check-label"
                    style={{ marginLeft: "5px" }}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 px-4 row mt-30 ">
        <label htmlFor="" style={{ fontWeight: "bold" }}>
          Micsellaneous Cost
        </label>
      </div>
      <div className="mt-0 form d-block">
        <div className="row w-100 mt-10 d-flex w-100">
          <div className="col-md-3 mt-15">
            <label htmlFor="connectionPrice">
              Connection Price Single/Three(star/delta)
            </label>
            <input
              name="connectionPrice"
              id="connectionPrice"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={parseFloat(formik?.values?.connectionPrice || 0).toFixed(
                2
              )}
              placeholder="0.00"
            />
          </div>
          <div className="col-md-3 mt-15">
            <label htmlFor="lugSupport">Lug Support</label>
            <input
              name="lugSupport"
              id="lugSupport"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={parseFloat(formik?.values?.lugSupport || 0).toFixed(2)}
              placeholder="0.00"
            />
          </div>

          <div className="col-md-3 mt-15">
            <label htmlFor="insulation">Insulation</label>
            <input
              name="insulation"
              id="insulation"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={parseFloat(formik?.values?.insulation || 0).toFixed(2)}
              placeholder="0.00"
            />
          </div>
          <div className="col-md-3 mt-15">
            <label htmlFor="gasKits">Gas Kits</label>
            <input
              name="gasKits"
              id="gasKits"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={parseFloat(formik?.values?.gasKits || 0).toFixed(2)}
              placeholder="0.00"
            />
          </div>
        </div>
        <div className="row w-100 mt-10 d-flex w-100">
          <div className="col-md-3 mt-15">
            <label htmlFor="paint">Paint</label>
            <input
              name="paint"
              id="paint"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              value={parseFloat(formik?.values?.paint || 0).toFixed(2)}
              placeholder="0.00"
            />
          </div>
          <div className="col-md-3 mt-15">
            <label htmlFor="other">Other</label>
            <input
              name="other"
              id="other"
              type="text"
              className="form-control"
              onChange={formik?.handleChange}
              defaultValue={parseFloat(formik?.values?.other || 0).toFixed(2)}
              placeholder="0.00"
            />
          </div>
          <div className="col-md-3 mt-15">
            <label htmlFor="" style={{ color: "white" }}>
              calculate
            </label>
            <button className="btn btn-primary bg-primary form-control">
              <h6>Calculate</h6>
            </button>
          </div>
        </div>
      </div>

      <div className="cost-container">
        <label htmlFor="totalEstimatedCost" className="cost-label">
          TOTAL ESTIMATED COST
        </label>
        <h3 className="cost-value">0.00</h3>
      </div>
      <hr />
    </div>
  );
};

export default Screen8;
