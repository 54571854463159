const materialSelection = {
  heatersheathtubedia: "",
  sheathmaterial: "",
  sheathtubethickness: "",
  sheathtubetype: "",
  distbwheaterflangetoterminalbox: "",
  maxactivehotlength: "",
  maxactivecoldlength: "",
  wattdensity: "",
  totalsurfacearea: "",
  heatTransferCoefficient: "",
  distancebwionozzels: "",
  materialofsleevesbush: "",
  numberofbaffles: "",
  baffleplatethickness: "",
  baffletype: "",
  bafflematerial: "",
  heatersparecapacity: "",
  material: "",
  bafflecut: "",
  tieroddia: "",
  tiematerial: "",
  bundleimmersionlength: "",
  fixingofheatingelements: "",
  individualelementmaxwattage: "",
  developedlength: "",
  totalnumberofelements: "",
  numberofelementsconnected: "",
  numberofelementsspare: "",
  wattdensityactual: "",
  individualelementrating: "",
  nutAssembly: "",
  tierodquantity: "",
  nutsize: "",
  //Bom Extra Fields
  heatingElementType: "",
  heatingElementValue: "",
  heatingElementUnit: "",
  heatingElememtAddUnit: "",
  heatingElementAddValue: "",
  heatingElementAddValueUnit: "",
  flangeThickness: "",
  heatingElementThickness: "",
  heatingElementTubeSheet: "",
  sheathtemp: "",
  approxvessellength: "",
};

export default materialSelection;
