const vesselSelection = {
  vesselSelection: "yes",
  vesselrangetype: "",
  vesselMaterial: "",
  vesselFlangePipeSize: "",
  sadlemoc: "",
  flangeSize: "",
  standard: "",
  KettleSize: "",
  FlangeRating: "",
  reducers: "",
  radiography: "",
  vesselMaterialCode: "",
  installationPosition: "",
};

export default vesselSelection;
