import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const SchedulesAdd = () => {
  const [ScheduleName, setScheduleName] = useState("");
  const [schedule, setSchedule] = useState("");
  const [flangesize, setFlangeSize] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!ScheduleName.trim() || !schedule.trim()) {
      showErrorToast("Schedule Name and Schedule cannot be empty");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .post("/schedules", {
        name: ScheduleName,
        sch: schedule,
        flange_size: flangesize,
      })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("Schedule added successfully"); // Show success toast on successful addition
        navigate("/schedules"); // Navigate to the Schedules listing page after successful addition
      })
      .catch((error) => {
        console.error(`Error adding Schedule: ${error}`);
        showErrorToast("Error adding Schedule"); // Show error toast on failure
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add Schedules</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <div className="d-flex mb-30">
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Schedule Name"
                              value={ScheduleName}
                              onChange={(e) => setScheduleName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Schedule"
                              value={schedule}
                              onChange={(e) => setSchedule(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="Flange Size"
                              value={flangesize}
                              onChange={(e) => setFlangeSize(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Link to="/schedules">
                            <button
                              type="button"
                              className="btn btn-secondary mr-3"
                            >
                              Close
                            </button>
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SchedulesAdd;
