const technicalCalculation = {
  ipRatingOptionsSafe: "",
  ipRatingOptionsHazardous: "",
  processFluid: "",
  divideEqually: false,
  power: "",
  powerperelement: "",
  specificHeatKJ: "",
  specificHeatBTU: "",
  unit: "C",
  startingTemp: "",
  outletTemp: "",
  tempChange: "",
  massFlowRate: "",
  timeDuration: "",
  heatDutyKW: "",
  heatDutyBTU: "",
  designmargin: "",
  inbuildspare: "",
  voltage: "",
  voltage_calculation: "",
  installedCapacity: "",
  ambienttemperature: "",
  calculatedcapacity: "",
  multistackvessel: "",
  connection: "",
  bundleheatload: "",
  designpressure: "",
  inletPressure: "",
  outletPressure: "",
  allowedPressureDrop: "",
  outletUnit: "",
  inletUnit: "",
  allowedPressureDropUnit: "",
  material: "",
  ijb: "",
  viscosity: "",
  thermalConductivity: "",
  mdmt: "",
  density: "",
  molecularweight: "",
  corrosionAllowance: "",
  sensorSelection: "",
  sensor: [
    {
      selected: "yes",
      sensortype: "",
      sensorsubtype: "",
      sensorrange: "",
      quantity: "",
      material: "",
      headMaterial: "",
    },
    {
      selected: "yes",
      sensortype: "",
      sensorsubtype: "",
      sensorrange: "",
      quantity: "",
      material: "",
      headMaterial: "",
    },
    {
      selected: "yes",
      sensortype: "",
      sensorsubtype: "",
      sensorrange: "",
      quantity: "",
      material: "",
      headMaterial: "",
    },
  ],
  temptransmitter: "",
  temperatureType: "",
  bank: "",
  certificationType: "",
  ipRating: "",
  heaterterminalboxiprating: "",
  heaterterminalboxcertificationrequired: "",
  installationPosition: "",
  instrument: "",
  panelrequirements: "",
  speccertification: "",
  tempCode: "",
  certificationrequired: "",
  zone: "",
  gasgroup: "",
  areaClassification: "",
  heaterterminalbox: "",
  heaterterminalboxijb: "",
  heaterterminalboxtempcode: "",
  heaterterminalboxzone: "",
  heaterterminalboxgasgroup: "",
  bundleTableData: [],
  frequency: "",
  heaterterminalboxmaterial: "",
  instrumentjunctionboxmaterial: "",

  tempTransIp: "",
  tempTransIJB: "",
  tempTransTempCode: "",
  tempTransCertificationRequired: "",
  tempTransZone: "",
  tempTransGasGroup: "",
  tempTransBoxMaterial: "",
};

export default technicalCalculation;
