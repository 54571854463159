import { Rowing } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import BomFormula from "./BomFormula";

function Screen10({ formik, isPageComplete }) {
  const [selectedArea, setSelectedArea] = useState(
    formik.values.cp_areaClassification || ""
  );

  const ipRatingMultipleOptions = Array.from({ length: 69 }, (_, index) => ({
    label: `IP-${index.toString().padStart(2, "0")}`,
    value: index,
  }));

  const installedCapacity = parseFloat(formik.values.installedCapacity) || 0;
  const totalBank = parseInt(formik.values.bank) || 0;
  const effectiveVessel = parseInt(formik.values.multistackvessel) || 1;
  const bundleHeatLoad = installedCapacity / effectiveVessel;

  const handleInputChange = (e, rowIndex, colIndex) => {
    const { value } = e.target;
    const updatedTableData = [...formik.values.bundleTableData];
    updatedTableData[rowIndex].bundle[colIndex].bank = value;

    const total =
      bundleHeatLoad - updatedTableData[rowIndex].bundle[colIndex].bank;
    const bank = formik.values.bank - 1;
    const eachData = total / bank;
    updatedTableData[rowIndex].bundle.map((data, index) => {
      if (index !== colIndex) {
        data.bank = eachData;
      }
    });

    formik.setFieldValue("bundleTableData", updatedTableData);
  };

  function getIPRatingOptions(area) {
    const start = area === "Safe Area" ? 42 : area === "Hazardous Area" ? 54 : 0;
    const end = 65;

    return Array.from({ length: end - start + 1 }, (_, i) => {
      const ipValue = start + i;
      return {
        value: `IP${ipValue.toString().padStart(2, "0")}`,
        label: `IP${ipValue.toString().padStart(2, "0")}`,
      };
    });
  }

  return (
    <div>
      <form>
        <div
          className="w-100 px-4 row justify-content-center mb-30"
          style={{ gap: "20px" }}
        >
          <label htmlFor="" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Control Panel
          </label>
          <div className="d-flex align-items-center" style={{ gap: "20px" }}>
            <input
              type="radio"
              id="cp_controlPanel"
              name="cp_controlPanel"
              checked={formik?.values?.cp_controlPanel === "yes"}
              onChange={() => formik.setFieldValue("cp_controlPanel", "yes")}
              value={formik?.values?.cp_controlPanel}
            />
            <label htmlFor="vesselYes">Yes</label>
            <input
              type="radio"
              id="cp_controlPanel"
              name="cp_controlPanel"
              checked={formik?.values?.cp_controlPanel == "no"}
              onChange={() => formik.setFieldValue("cp_controlPanel", "no")}
              value={formik?.values?.cp_controlPanel}
            />
            <label htmlFor="vesselNo">No</label>
          </div>
        </div>
        {formik?.values?.cp_controlPanel === "yes" && (
          <>
            <div className="w-100 px-4 row justify-content-center">
              <div className="row w-100">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex justify-content-between ">
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_areaClassification">
                        Area Classification
                      </label>
                      <Select
                        name="cp_areaClassification"
                        id="cp_areaClassification"
                        options={[
                          { value: "Safe Area", label: "Safe Area" },
                          { value: "Hazardous Area", label: "Hazardous Area" },
                        ]}
                        placeholder="Select area classification"
                        value={
                          formik?.values?.cp_areaClassification
                            ? {
                                value: formik?.values?.cp_areaClassification,
                                label: formik?.values?.cp_areaClassification,
                              }
                            : null
                        }
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_areaClassification",
                            option.value
                          )
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_Power">Power (Kw Unit)</label>
                      <input
                        type="text"
                        id="cp_Power"
                        name="cp_Power"
                        onChange={formik.handleChange}
                        value={formik.values.heatDutyKW}
                        className="form-control"
                        placeholder="Enter power"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between ">
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_breakingCapacity">
                        Breaking Capacity
                      </label>
                      <Select
                        name="cp_breakingCapacity"
                        id="cp_breakingCapacity"
                        options={[
                          { value: "25k", label: "25k" },
                          { value: "36k", label: "36k" },
                          { value: "50k", label: "50k" },
                          { value: "65k", label: "65k" },
                        ]}
                        placeholder="Select Breaking Capacity"
                        value={
                          formik?.values?.cp_breakingCapacity
                            ? {
                                value: formik?.values?.cp_breakingCapacity,
                                label: formik?.values?.cp_breakingCapacity,
                              }
                            : null
                        }
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_breakingCapacity",
                            option.value
                          )
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_ipRating">IP Rating</label>
                      <Select
                        name="cp_ipRating"
                        placeholder="Select IP Rating"
                        id="cp_ipRating"
                        options={getIPRatingOptions(
                          formik.values.cp_areaClassification
                        )}
                        onChange={(option) =>
                          formik?.setFieldValue("cp_ipRating", option.value)
                        }
                        value={
                          formik.values.cp_ipRating
                            ? {
                                label: formik.values.cp_ipRating,
                                value: formik.values.cp_ipRating,
                              }
                            : null
                        }
                        onBlur={formik?.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="col-12 mb-30">
                      <label htmlFor="cp_thyristorPanelSelection">
                        Thyristor Panel
                      </label>
                      <div className="d-flex">
                        <div className="row d-flex px-2 py-2 mr-2">
                          <input
                            type="radio"
                            id="cp_thyristorPanelSelection"
                            name="cp_thyristorPanelSelection"
                            className="row gap-3 ml-0"
                            checked={
                              formik?.values?.cp_thyristorPanelSelection ===
                              "yes"
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                "cp_thyristorPanelSelection",
                                "yes"
                              )
                            }
                            value={formik?.values?.cp_thyristorPanelSelection}
                          />
                          <label htmlFor="cp_thyristorPanelSelection">
                            Yes
                          </label>
                          <input
                            type="radio"
                            id="cp_thyristorPanelSelection"
                            name="cp_thyristorPanelSelection"
                            className="row gap-3 ml-5"
                            checked={
                              formik?.values?.cp_thyristorPanelSelection ==
                              " Contractor Control"
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                "cp_thyristorPanelSelection",
                                " Contractor Control"
                              )
                            }
                            value={formik?.values?.cp_thyristorPanelSelection}
                          />
                          <label htmlFor="cp_thyristorPanelSelection">No</label>
                        </div>
                        {formik?.values?.cp_thyristorPanelSelection ===
                          "yes" && (
                          <div className="row d-flex px-2 py-2">
                            <input
                              type="radio"
                              id="with_pid_controller"
                              name="cp_thyristorPanel"
                              className="row gap-3 ml-0"
                              checked={
                                formik?.values?.cp_thyristorPanel ===
                                "With PID Controller"
                              }
                              onChange={() =>
                                formik.setFieldValue(
                                  "cp_thyristorPanel",
                                  "With PID Controller "
                                )
                              }
                              value={formik?.values?.cp_thyristorPanel}
                            />
                            <label htmlFor="cp_thyristorPanel">
                              With PID Controller
                            </label>

                            <input
                              type="radio"
                              id="without_pid_controller"
                              name="cp_thyristorPanel"
                              className="row gap-3 ml-5"
                              checked={
                                formik?.values?.cp_thyristorPanel ==
                                "Without PID Controller"
                              }
                              onChange={() =>
                                formik.setFieldValue(
                                  "cp_thyristorPanel",
                                  "Without PID Controller"
                                )
                              }
                              value={formik?.values?.cp_thyristorPanel}
                            />
                            <label htmlFor="cp_thyristorPanel">
                              Without PID Controller
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="col-12 mb-30">
                      <label htmlFor="cp_inputTypeOfController">
                        Input Type Of Controller
                      </label>
                      <div className="row d-flex px-2 py-2 mr-2">
                        <input
                          type="radio"
                          id="cp_inputTypeOfController"
                          name="cp_inputTypeOfController"
                          className="row gap-3 ml-0"
                          checked={
                            formik?.values?.cp_inputTypeOfController == "RTD"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "cp_inputTypeOfController",
                              "RTD"
                            )
                          }
                          value={formik?.values?.cp_inputTypeOfController}
                        />
                        <label htmlFor="cp_inputTypeOfController">RTD</label>
                        <input
                          type="radio"
                          id="cp_inputTypeOfController"
                          name="cp_inputTypeOfController"
                          className="row gap-3"
                          checked={
                            formik?.values?.cp_inputTypeOfController ===
                            "MILI AMP"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "cp_inputTypeOfController",
                              "MILI AMP"
                            )
                          }
                          value={formik?.values?.cp_inputTypeOfController}
                        />
                        <label htmlFor="cp_inputTypeOfController">
                          MILI AMP
                        </label>
                        <input
                          type="radio"
                          id="cp_inputTypeOfController"
                          name="cp_inputTypeOfController"
                          className="row gap-3"
                          checked={
                            formik?.values?.cp_inputTypeOfController ===
                            "THERMOCOUPLE"
                          }
                          onChange={() =>
                            formik.setFieldValue(
                              "cp_inputTypeOfController",
                              "THERMOCOUPLE"
                            )
                          }
                          value={formik?.values?.cp_inputTypeOfController}
                        />
                        <label htmlFor="cp_inputTypeOfController">
                          THERMOCOUPLE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="d-flex justify-content-between ">
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_voltage">Voltage (Volt)</label>
                      <input
                        type="text"
                        id="cp_voltage"
                        name="cp_voltage"
                        onChange={formik.handleChange}
                        value={formik?.values?.voltage}
                        className="form-control"
                        placeholder="Enter voltage"
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_frequency">Frequency (Hz)</label>
                      <input
                        type="text"
                        id="cp_frequency"
                        name="cp_frequency"
                        onChange={formik.handleChange}
                        value={formik.values.frequency}
                        className="form-control"
                        placeholder="Enter frequency"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_enclosureType">Enclosure Type</label>
                      <Select
                        name="cp_enclosureType"
                        id="cp_enclosureType"
                        options={[
                          { value: "Compartment 1", label: "Compartment 1" },
                          { value: "Compartment 2", label: "Compartment 2" },
                          { value: "Compartment 3", label: "Compartment 3" },
                          { value: "Compartment 4", label: "Compartment 4" },
                        ]}
                        placeholder="Select Enclosure Type"
                        value={
                          formik?.values?.cp_enclosureType
                            ? {
                                value: formik?.values?.cp_enclosureType,
                                label: formik?.values?.cp_enclosureType,
                              }
                            : null
                        }
                        onChange={(option) =>
                          formik.setFieldValue("cp_enclosureType", option.value)
                        }
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-30">
                      <label htmlFor="cp_noOfSaftyController">
                        No Of Safty Controller
                      </label>
                      <Select
                        name="cp_noOfSaftyController"
                        id="cp_noOfSaftyController"
                        options={[
                          { value: "Option 1", label: "Option 1" },
                          { value: "Option 2", label: "Option 2" },
                          { value: "Option 3", label: "Option 3" },
                          { value: "Option 4", label: "Option 4" },
                        ]}
                        placeholder="Select No. of Safty Controller"
                        value={
                          formik?.values?.cp_noOfSaftyController
                            ? {
                                value: formik?.values?.cp_noOfSaftyController,
                                label: formik?.values?.cp_noOfSaftyController,
                              }
                            : null
                        }
                        onChange={(option) =>
                          formik.setFieldValue(
                            "cp_noOfSaftyController",
                            option.value
                          )
                        }
                      />
                    </div>
                  </div>
                  {formik &&
                    formik?.values?.multistackvessel &&
                    formik?.values?.bank &&
                    formik?.values?.heatDutyKW && (
                      <div className="d-flex col-md-12 mt-15 mb-10">
                        <table className="table border border-secondary">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                }}
                              >
                                <strong>Bundle</strong>
                              </th>
                              {Array.from({ length: totalBank }).map((_, i) => (
                                <th
                                  key={i}
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "8px",
                                  }}
                                >
                                  <strong>Bank {i + 1}</strong>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {formik?.values?.bundleTableData?.map(
                              (row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                    }}
                                  >
                                    Bundle {rowIndex + 1} -
                                    {bundleHeatLoad.toFixed(2)}
                                  </td>
                                  {row?.bundle.map((col, colIndex) => (
                                    <td
                                      key={colIndex}
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "100%",
                                          minWidth: "30px",
                                          boxSizing: "border-box",
                                          padding: "5px",
                                        }}
                                        name={`bundleTableData[${rowIndex}].bundle[${colIndex}].bank`}
                                        id={`bundleTableData${rowIndex}.bundle${colIndex}.bank`}
                                        type="text"
                                        className="form-control"
                                        placeholder={(
                                          bundleHeatLoad / totalBank
                                        ).toFixed(2)}
                                        value={col.bank}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            rowIndex,
                                            colIndex
                                          )
                                        }
                                        readOnly
                                        
                                      />
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        )}
      </form>
      <BomFormula formik={formik} />
      <hr />
    </div>
  );
}

export default Screen10;
