import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { Link } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const SheathMaterialsAdd = () => {
  const [SheathMaterialName, setSheathMaterialName] = useState("");
  const [sheathMaterial, setSheathMaterial] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!SheathMaterialName.trim()) {
      showErrorToast("SheathMaterial Name and SheathMaterial cannot be empty");
      return;
    }
    setIsLoading(true);

    axiosPrivate
      .post("/sheath-material", {
        name: SheathMaterialName,
      })
      .then((response) => {
        setIsLoading(false);
        showSuccessToast("SheathMaterial added successfully"); // Show success toast on successful addition
        navigate("/sheathMaterial"); // Navigate to the SheathMaterials listing page after successful addition
      })
      .catch((error) => {
        console.error(`Error adding SheathMaterial: ${error}`);
        showErrorToast("Error adding SheathMaterial"); // Show error toast on failure
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Add SheathMaterials</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <div className="d-flex mb-30">
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control mr-3"
                              placeholder="SheathMaterial Name"
                              value={SheathMaterialName}
                              onChange={(e) =>
                                setSheathMaterialName(e.target.value)
                              }
                            />
                          </div>

                        </div>
                        <div className="d-flex justify-content-end">
                          <Link to="/sheathMaterial">
                            <button
                              type="button"
                              className="btn btn-secondary mr-3"
                            >
                              Close
                            </button>
                          </Link>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SheathMaterialsAdd;
