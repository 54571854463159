import React from "react";
import ReactFlow from "react-flow-renderer";

const StagesVisualization = ({}) => {
  // Convert stagesData to React Flow elements format

  const stagesData = {
    nodes: [
      { id: "stage1", label: "Stage 1", x: 100, y: 100 },
      { id: "stage2", label: "Stage 2", x: 300, y: 100 },
      { id: "stage3", label: "Stage 3", x: 200, y: 250 },
    ],
    edges: [
      { source: "stage1", target: "stage2" },
      { source: "stage2", target: "stage3" },
      { source: "stage1", target: "stage3" },
    ],
  };

  const elements = [
    ...stagesData.nodes.map((node) => ({
      id: node.id,
      type: "default",
      data: { label: node.label },
      position: { x: node.x, y: node.y },
    })),
    ...stagesData.edges.map((edge) => ({
      id: `${edge.source}-${edge.target}`,
      source: edge.source,
      target: edge.target,
      type: "smoothstep",
      animated: true,
    })),
  ];

  return (
    <div style={{ height: "600px", width: "800px" }}>
      <ReactFlow
        elements={elements}
        style={{ width: "100%", height: "100%" }}
        zoomOnScroll={false} // Disable zooming
        panOnScroll={false} // Disable panning
        panOnScrollMode="false" // Disable panning
        onElementClick={() => {}} // Disable element click
        onElementsRemove={() => {}} // Disable element removal
      />
    </div>
  );
};

export default StagesVisualization;
