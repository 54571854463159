import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosPrivate from "../../hooks/axiosPrivate";
import axiosPrivateFolder from "../../hooks/axiosPrivateFolder";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { FaFolder, FaFile } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import "./folder.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { async } from "q";

const uploadValidationRules = {
  // files_to_upload: Yup.mixed().required("File is required"),
  files_to_upload: Yup.array() //if this is true error message will not be displayed
    .test(
      "singleDocument",
      "Only one document can be uploaded when selecting multiple production orders",
      (value, context) => {
        const { parent } = context; // Access form values
        // Check if there is only one document uploaded when selecting multiple production orders
        return !(value.length > 1 && parent?.prod_orders?.length > 1);
      }
    )
    .required("Document file(s) are required"),
};

const createFolderValidationRules = {
  folder_name: Yup.string().required("This field is required"),
};

const renameFolderValidationRules = {
  folder_name: Yup.string().required("This field is required"),
};

const folderPermissionValidationRules = {
  // permissions: Yup.string().required("This field is required"),
};

const deleteFolderValidationRules = {
  folder_id: Yup.string().required("This field is required"),
};

const Folder = () => {
  const animatedComponents = makeAnimated();

  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader();
  const [directories, setDirectories] = useState([]);
  const [headerNavigation, setheaderNavigation] = useState([]);
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modelHeading, setModelHeading] = useState("");
  const [folderAndFilePermission, setFolderAndFilePermission] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [folder_id, setFolderId] = useState();

  /* Get All File And Folder Permissions */
  const getFolderFilesPermissions = async () => {
    try {
      const url = `get/file/and/folder/permission`;
      const response = await axiosPrivate.get(url).catch((err) => {
        if (err?.response?.data?.errors?.msg) {
          showErrorToast(err.response.data.errors.msg);
        } else {
          showErrorToast(err.response.data.message);
        }
      });

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setFolderAndFilePermission(response.data.data);
        } else {
          setFolderAndFilePermission([]);
        }
      }
    } catch (error) {}
  };

  const getAllUsers = async () => {
    try {
      const url = `/get/file/and/folder/permission/users`;
      const response = await axiosPrivate.get(url).catch((err) => {
        if (err?.response?.data?.errors?.msg) {
          showErrorToast(err.response.data.errors.msg);
        } else {
          showErrorToast(err.response.data.message);
        }
      });

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setUserList(response.data.data);
        } else {
          setUserList([]);
        }
      }
    } catch (error) {}
    // setUserList
  };

  const folderDownload = async (rowDataId) => {
    try {
      const url = `download/file`;
      const body = {
        folder_id: rowDataId,
      };
      const response = await axiosPrivate.post(url, body);
      if (response.status === 200) {
        const downloadUrl = `${process.env.REACT_APP_FOLDER_URL}${response.data.data.path}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", response?.data?.data?.name); // Set the file name for the downloaded file
        document.body.appendChild(link);
        link.click();
        link.remove();

        showSuccessToast("File downloaded successfully");
      }
    } catch (error) {}
  };

  useEffect(() => {
    getFolderFilesPermissions();
    getAllUsers();
  }, []);

  /* Formik Start */
  const uploadFormik = useFormik({
    initialValues: {
      files_to_upload: [],
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(uploadValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const formData = new FormData();

      // Append each file to FormData individually
      if (values.files_to_upload.length > 0) {
        for (let i = 0; i < values.files_to_upload.length; i++) {
          formData.append(`files_to_upload`, values.files_to_upload[i]);
        }
      }

      if (headerNavigation.length > 0) {
        let filerUploadpath = "";
        let lastFolderId = "";
        for (let i = 0; i < headerNavigation.length; i++) {
          filerUploadpath =
            filerUploadpath + "/" + headerNavigation[i].folder_name;
          lastFolderId = headerNavigation[i].folder_id;
        }
        if (filerUploadpath != "" && lastFolderId != "") {
          try {
            const url = `upload/files?folderPath=${filerUploadpath}&folderId=${lastFolderId}`;
            const response = await axiosPrivateFolder
              .post(url, formData)
              .catch((err) => {
                showErrorToast(err.response.data.message);
                setVisible(false);
              });
            if (response.status === 201) {
              getFolderContentApi(lastFolderId);
              showSuccessToast("Documents uploaded Successfully");
              // setSelectedRows(null);
              setVisible(false);
            }
          } catch (error) {}
        }
      }
    },
  });
  /* Formik End */

  /* Formik Start */
  const createFolderFormik = useFormik({
    initialValues: {
      folder_name: "",
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(createFolderValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      if (headerNavigation.length > 0) {
        let filerUploadpath = "";
        let lastFolderId = "";
        for (let i = 0; i < headerNavigation.length; i++) {
          filerUploadpath =
            filerUploadpath + "/" + headerNavigation[i].folder_name;
          lastFolderId = headerNavigation[i].folder_id;
        }
        const data = {
          folder_name: values.folder_name,
          customer_id: id,
          folderId: lastFolderId,
          folderPath: filerUploadpath,
        };
        if (filerUploadpath != "" && lastFolderId != "") {
          try {
            const url = `create/new/folder`;
            const response = await axiosPrivate.post(url, data).catch((err) => {
              if (err?.response?.data?.errors?.msg) {
                showErrorToast(err.response.data.errors.msg);
              } else {
                showErrorToast(err.response.data.message);
              }
            });

            if (response.status === 201) {
              getFolderContentApi(lastFolderId);
              showSuccessToast("Documents uploaded Successfully");
              // setSelectedRows(null);
              setVisible(false);
            }
          } catch (error) {}
        }
      }
      // create/new/folder
    },
  });
  /* Formik End */

  /* Formik Start */
  const renameFolderFormik = useFormik({
    initialValues: {
      folder_name: "",
      folder_path: "",
      folder_id: "",
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(renameFolderValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      let filerUploadpath = "";
      let lastFolderId = "";
      for (let i = 0; i < headerNavigation.length; i++) {
        filerUploadpath =
          filerUploadpath + "/" + headerNavigation[i].folder_name;
        lastFolderId = headerNavigation[i].folder_id;
      }
      try {
        const url = `update/folder/name/${values.folder_id}`;
        const response = await axiosPrivate.put(url, values).catch((err) => {
          if (err?.response?.data?.errors?.msg) {
            showErrorToast(err.response.data.errors.msg);
          } else {
            showErrorToast(err.response.data.message);
          }
        });
        if (response.status === 200) {
          getFolderContentApi(lastFolderId);
          showSuccessToast("Folder renamed Successfully");
          // setSelectedRows(null);
          setVisible(false);
        }
      } catch (error) {}
    },
  });
  /* Formik End */

  /* Formik Start */
  const deleteFolderFormik = useFormik({
    initialValues: {
      folder_id: "",
      folder_name: "",
      type: "",
      file_id: "",
      file_name: "",
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(deleteFolderValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      let lastFolderId = "";
      for (let i = 0; i < headerNavigation.length; i++) {
        lastFolderId = headerNavigation[i].folder_id;
      }
      try {
        const url = `delete/folder/file/${values.folder_id}`;
        const response = await axiosPrivate.put(url, values).catch((err) => {
          if (err?.response?.data?.errors?.msg) {
            showErrorToast(err.response.data.errors.msg);
          } else {
            showErrorToast(err.response.data.message);
          }
        });
        if (response.status === 200) {
          getFolderContentApi(lastFolderId);
          showSuccessToast(response.message);
          // setSelectedRows(null);
          setVisible(false);
        }
      } catch (error) {}
    },
  });
  /* Formik End */

  /* Formik Start */
  const folderPermissionFormik = useFormik({
    initialValues: {
      permissions: [],
      folder_id: "",
      user_ids: [],
      folder_name: "",
      file_id: "",
      file_name: "",
      permission_ids: [],
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(folderPermissionValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      let object = {};
      if (values?.folder_id !== "") {
        object = {
          user_id: values.user_ids,
          permission_id: values.permission_ids,
          folder_id: values?.folder_id,
        };
      } else {
        object = {
          user_id: values.user_ids,
          permission_id: values.permission_ids,
          file_id: values.file_id,
        };
      }
      try {
        const response = await axiosPrivate.post(`set/permissions`, object);
        if (response.status === 200) {
          showSuccessToast("Permission granted ");
          setVisible(false);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          navigate("/forbidden");
        } else if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      }
    },
  });
  /* Formik End */

  /* Calling directories */
  useEffect(() => {
    fetchData();
  }, [id]);

  /* Root directories call function */
  const fetchData = async () => {
    if (id != undefined) {
      setDropDownMenu(false);
      try {
        let user = localStorage.getItem("userData");

        user = JSON.parse(user);
        setIsLoading(true);
        const response = await axiosPrivate.post(`/get/folders/structures`, {
          user_id: user.userId,
          customer_id: id,
        });
        if (response.status === 200) {
          setDirectories(response.data?.data);
          setheaderNavigation([]);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          navigate("/forbidden");
        } else if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  /* Function to get directory content and add to navigation */
  const getFolderContent = async (e, folder) => {
    e.preventDefault();
    setDropDownMenu(false);
    let newHeaderNavigation = [];
    if (headerNavigation.length <= 0) {
      newHeaderNavigation.push(folder);
      await getFolderContentApi(folder.folder_id);
      setheaderNavigation(newHeaderNavigation);
    } else {
      let checkExist = false;
      let findId = "";
      for (let i = 0; i < headerNavigation.length; i++) {
        if (headerNavigation[i].folder_id == folder.folder_id) {
          findId = i;
          checkExist = true;
          break;
        }
      }
      if (checkExist === true) {
        for (let i = 0; i <= findId; i++) {
          newHeaderNavigation.push(headerNavigation[i]);
        }
        await getFolderContentApi(folder.folder_id);
        setheaderNavigation(newHeaderNavigation);
      } else {
        newHeaderNavigation = headerNavigation;
        newHeaderNavigation.push(folder);
        await getFolderContentApi(folder.folder_id);
        setheaderNavigation(newHeaderNavigation);
      }
    }
  };

  /* Function to call api get content */
  const getFolderContentApi = async (folderId) => {
    // /get/folder/content/:customer_id/:folder_id
    if (id != undefined && folderId) {
      let user = localStorage.getItem("userData");
      user = JSON.parse(user);
      try {
        setIsLoading(true);
        const response = await axiosPrivate.post(`/get/folder/content`, {
          folder_id: folderId,
          user_id: user.userId,
        });
        if (response.status === 200) {
          setDirectories(response.data?.data);
        }
      } catch (error) {
        if (error.response?.status === 403) {
          navigate("/forbidden");
        } else if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const threeDotEvent = async (e, elementId) => {
    // document.querySelectorAll(".thee-dot-menu").forEach(function (button) {
    //   button.classList.remove("show");
    // });
    // thee-dot-menu
    var element = document.getElementById(elementId);
    if (element.classList.contains("show") === true) {
      element.classList.remove("show");
    } else {
      element.classList.add("show");
    }
  };

  // Close the dropdown if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches(".three-dot-button")) {
      var dropdowns = document.getElementsByClassName("thee-dot-menu");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dialog
            header={
              modelHeading == "Folder Permission"
                ? `Share ${folderPermissionFormik.values.folder_name} ${folderPermissionFormik.values.file_name}`
                : modelHeading
            }
            visible={visible}
            style={{ width: "35vw" }}
            onHide={() => {
              setVisible(false);
              folderPermissionFormik.resetForm();
              createFolderFormik.resetForm();
              uploadFormik.resetForm();
              renameFolderFormik.resetForm();
              deleteFolderFormik.resetForm();
            }}
          >
            <div className="d-flex justify-content-between m-2 ">
              {modelHeading === "Folder" ? (
                <>
                  <form
                    onSubmit={createFolderFormik.handleSubmit}
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="folder_name"
                        className="col-sm control-label"
                      >
                        Folder
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="folder_name"
                        onChange={async (e) => {
                          e.preventDefault();
                          createFolderFormik.setFieldValue(
                            "folder_name",
                            e.target.value
                          );
                        }}
                        placeholder="Please enter folder name"
                        value={createFolderFormik.values.folder_name}
                      />
                      {createFolderFormik.touched &&
                      createFolderFormik.errors.folder_name ? (
                        <div className="text-danger">
                          {createFolderFormik.errors.folder_name}
                        </div>
                      ) : null}
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </form>
                </>
              ) : (
                <></>
              )}
              {modelHeading === "Upload" ? (
                <>
                  <form
                    onSubmit={uploadFormik.handleSubmit}
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="files_to_upload"
                        className="col-sm control-label"
                      >
                        Files
                      </label>
                      <input
                        id="formFile"
                        type="file"
                        className="form-control"
                        name="files_to_upload"
                        onChange={async (e) => {
                          e.preventDefault();
                          const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
                          uploadFormik.setFieldValue(
                            "files_to_upload",
                            selectedFiles
                          ); // Set files as an array
                        }}
                        multiple
                      />
                      {uploadFormik.touched &&
                      uploadFormik.errors.files_to_upload ? (
                        <div className="text-danger">
                          {uploadFormik.errors.files_to_upload}
                        </div>
                      ) : null}
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Upload
                    </button>
                  </form>
                </>
              ) : (
                <></>
              )}
              {modelHeading === "Rename" ? (
                <>
                  <form
                    onSubmit={renameFolderFormik.handleSubmit}
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="folder_name"
                        className="col-sm control-label"
                      >
                        Folder
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="folder_name"
                        onChange={async (e) => {
                          e.preventDefault();
                          renameFolderFormik.setFieldValue(
                            "folder_name",
                            e.target.value
                          );
                        }}
                        placeholder="Please enter folder name"
                        value={renameFolderFormik.values.folder_name}
                      />
                      {renameFolderFormik.touched &&
                      renameFolderFormik.errors.folder_name ? (
                        <div className="text-danger">
                          {renameFolderFormik.errors.folder_name}
                        </div>
                      ) : null}
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </form>
                </>
              ) : (
                <></>
              )}

              {modelHeading === "Delete" ? (
                <>
                  <form
                    onSubmit={deleteFolderFormik.handleSubmit}
                    className="form-horizontal"
                  >
                    <div className="form-group">
                      Confimation do you want to delete{" "}
                      {deleteFolderFormik.values.folder_name}{" "}
                      {deleteFolderFormik.values.type} sure
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(false);
                      }}
                    >
                      Cancel
                    </button>
                  </form>
                </>
              ) : (
                <></>
              )}

              {modelHeading === "Folder Permission" ? (
                <>
                  <form
                    onSubmit={folderPermissionFormik.handleSubmit}
                    className="form-horizontal"
                  >
                    <div className={`form-group`}>
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={folderPermissionFormik.values.user_ids}
                        isMulti
                        options={usersList}
                        name="user_ids"
                        onChange={(e) => {
                          folderPermissionFormik.setFieldValue("user_ids", e);
                          if (e.length == 0) {
                            folderPermissionFormik.setFieldValue(
                              "permission_ids",
                              []
                            );
                          }
                        }}
                        placeholder="Select Users"
                      />
                    </div>
                    {folderPermissionFormik.values.user_ids.length > 0 ? (
                      <>
                        <div className={`form-group`}>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={folderPermissionFormik.values.permission_ids}
                            isMulti
                            options={folderAndFilePermission}
                            name="permission_ids"
                            onChange={(e) => {
                              folderPermissionFormik.setFieldValue(
                                "permission_ids",
                                e
                              );
                            }}
                            placeholder="Select Permissions"
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {folderPermissionFormik.values.user_ids.length <= 0 ? (
                      <>
                        <div>
                          <strong>People with access</strong>
                          <div className={`form-group`}></div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                    >
                      Done
                    </button>
                  </form>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* upload documents form */}
          </Dialog>
          <div className="section-body d-flex justify-content-center align-items-center mt-3">
            <div className="card">
              <div className="card-header justify-content-between">
                <div>
                  <strong style={{cursor:"pointer"}}>Drive</strong>
                  <span className="p-2">&gt;</span>
                  <span
                    style={{cursor:"pointer"}}
                    className="p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchData();
                    }}
                  >
                    Root
                  </span>
                  {headerNavigation.map((folder, index) => (
                    <React.Fragment key={folder.folder_name - index}>
                      <span className="p-2">&gt;</span>
                      <span
                        style={{cursor:"pointer"}}
                        className="cursor-pointer"
                        onClick={(e) => {
                          getFolderContent(e, {
                            folder_id: folder.folder_id,
                            folder_name: folder.folder_name,
                          });
                        }}
                      >
                        {folder.folder_name}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
                <div className="d-flex justify-content-end">
                  {headerNavigation.length > 1 ? (
                    <div className="btn-group">
                      <button
                        className="btn btn-secondary btn-lg dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={(e) => {
                          e.preventDefault();
                          createFolderFormik.resetForm();
                          uploadFormik.resetForm();
                          setDropDownMenu(!dropDownMenu);
                        }}
                      >
                        <span>New</span>
                      </button>
                      <ul
                        className={`dropdown-menu ${
                          dropDownMenu ? "show" : ""
                        }`}
                      >
                        <li>
                          <button
                            type="button"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setModelHeading("");
                              setVisible((prev) => !prev);
                              setModelHeading("Folder");
                              setDropDownMenu(false);
                            }}
                            style={{ marginRight: "2rem" }}
                          >
                            Folder
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#exampleModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setModelHeading("");
                              setVisible((prev) => !prev);
                              setModelHeading("Upload");
                              setDropDownMenu(false);
                            }}
                            style={{ marginRight: "2rem" }}
                          >
                            Upload
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <hr className="mt-4" />
              <div className="card-body">
                <div className="row clearfix d-block">
                  {/* <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "100%" }}>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {directories.map((value, index) =>
                        value.files != undefined ? (
                          <>
                            {value.files.map((valueFile, indexFile) => (
                              <>
                                <tr key={`${indexFile}`}>
                                  <td className=" align-items-center row">
                                    <div className="mr-2">
                                      <CiFileOn />
                                    </div>{" "}
                                    {valueFile.file_name}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {headerNavigation.length > 1 &&
                                    valueFile.is_deleteable === 1 ? (
                                      <div className="btn-group">
                                        <button
                                          type="button"
                                          className={`three-dot-button`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            threeDotEvent(
                                              e,
                                              `three-dot-${index}-${valueFile.file_name}`
                                            );
                                          
                                          }}
                                        >
                                          . . .
                                        </button>
                                        <ul
                                          className={`dropdown-menu thee-dot-menu`}
                                          id={`three-dot-${index}-${valueFile.file_name}`}
                                        >
                                          <li>
                                            <button
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setModelHeading("");
                                                setVisible((prev) => !prev);
                                                setModelHeading("Delete");
                                                setDropDownMenu(false);
                                                deleteFolderFormik.setFieldValue(
                                                  "folder_name",
                                                  valueFile?.file_name
                                                );
                                                deleteFolderFormik.setFieldValue(
                                                  "folder_id",
                                                  valueFile.id
                                                );
                                                deleteFolderFormik.setFieldValue(
                                                  "type",
                                                  "file"
                                                );
                                              }}
                                            >
                                              Delete
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr key={`${index}-${value.folder_name}`}>
                              <td
                                className=" align-items-center row"
                                onClick={(e) => {
                                  getFolderContent(e, {
                                    folder_id: value.id,
                                    folder_name: value.folder_name,
                                  });
                                }}
                              >
                                <div className="mr-2">
                                  <FaFolder />
                                </div>{" "}
                                {value.folder_name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {headerNavigation.length > 1 &&
                                value.is_deleteable === 1 ? (
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className={`three-dot-button`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        threeDotEvent(
                                          e,
                                          `three-dot-${index}-${value.folder_name}`
                                        );
                                      }}
                                    >
                                      . . .
                                    </button>
                                    <ul
                                      className={`dropdown-menu thee-dot-menu`}
                                      id={`three-dot-${index}-${value.folder_name}`}
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setModelHeading("");
                                            setVisible((prev) => !prev);
                                            setModelHeading("Rename");
                                            setDropDownMenu(false);
                                            renameFolderFormik.setFieldValue(
                                              "folder_name",
                                              value?.folder_name
                                            );
                                            renameFolderFormik.setFieldValue(
                                              "folder_id",
                                              value.id
                                            );
                                          }}
                                        >
                                          Rename
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setModelHeading("");
                                            setVisible((prev) => !prev);
                                            setModelHeading("Delete");
                                            setDropDownMenu(false);
                                            deleteFolderFormik.setFieldValue(
                                              "folder_name",
                                              value?.folder_name
                                            );
                                            deleteFolderFormik.setFieldValue(
                                              "folder_id",
                                              value.id
                                            );
                                            deleteFolderFormik.setFieldValue(
                                              "type",
                                              "folder"
                                            );
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className={`three-dot-button`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        threeDotEvent(
                                          e,
                                          `three-dot-${index}-${value.folder_name}`
                                        );
                                        setFolderId(value.id);
                                      }}
                                    >
                                      . . .
                                    </button>
                                    <ul
                                      className={`dropdown-menu thee-dot-menu`}
                                      id={`three-dot-${index}-${value.folder_name}`}
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setModelHeading("");
                                            setVisible((prev) => !prev);
                                            setModelHeading(
                                              "Folder Permission"
                                            );
                                            setDropDownMenu(false);
                                            folderPermissionFormik.setFieldValue(
                                              "folder_name",
                                              value?.folder_name
                                            );
                                            folderPermissionFormik.setFieldValue(
                                              "folder_id",
                                              value.id
                                            );  
                                          }}
                                        >
                                          Share
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </table> */}
                  <DataTable
                    value={directories}
                    tableStyle={{ minWidth: "100%" }}
                    style={{ minHeight: "65dvh" }}
                  >
                    <Column
                      field="name"
                      header="Name"
                      body={(rowData, rowIndex) =>
                        rowData.files ? (
                          <>
                            {rowData.files.map((file, fileIndex) => (
                              <div
                                key={`${rowIndex}-${fileIndex}`}
                                className="p-d-flex p-ai-center"
                              >
                                <div
                                  className="p-mr-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <CiFileOn />
                                  {file.file_name}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div
                            className="p-d-flex p-ai-center"
                            onClick={(e) =>
                              getFolderContent(e, {
                                folder_id: rowData.id,
                                folder_name: rowData.folder_name,
                              })
                            }
                          >
                            <div
                              className="p-mr-2"
                              style={{ cursor: "pointer" }}
                            >
                              <FaFolder />
                              <span className="pl-2"></span>
                              {rowData.folder_name}
                            </div>
                          </div>
                        )
                      }
                    />
                    <Column
                      field="action"
                      header="Action"
                      body={(rowData, rowIndex) =>
                        rowData.files ? (
                          rowData.files.map((file, fileIndex) => (
                            <div
                              key={`${rowIndex}-${fileIndex}`}
                              style={{ textAlign: "left" }}
                            >
                              {headerNavigation.length > 1 &&
                                file.is_deleteable === 1 && (
                                  <div className="btn-group">
                                    <button
                                      type="button"
                                      className="three-dot-button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        threeDotEvent(
                                          e,
                                          `three-dot-${rowIndex}-${file.file_name}`
                                        );
                                      }}
                                    >
                                      . . .
                                    </button>
                                    <ul
                                      className="dropdown-menu thee-dot-menu"
                                      id={`three-dot-${rowIndex}-${file.file_name}`}
                                    >
                                      <li>
                                        <button
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setModelHeading("");
                                            setVisible((prev) => !prev);
                                            setModelHeading(
                                              "Folder Permission"
                                            );
                                            setDropDownMenu(false);
                                            folderPermissionFormik.setFieldValue(
                                              "file_name",
                                              file?.file_name
                                            );
                                            folderPermissionFormik.setFieldValue(
                                              "file_id",
                                              file.id
                                            );
                                          }}
                                        >
                                          Share
                                        </button>
                                        <button
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setModelHeading("");
                                            setVisible((prev) => !prev);
                                            setModelHeading("Delete");
                                            setDropDownMenu(false);
                                            deleteFolderFormik.setFieldValue(
                                              "file_name",
                                              file?.file_name
                                            );
                                            deleteFolderFormik.setFieldValue(
                                              "file_id",
                                              file.id
                                            );
                                            deleteFolderFormik.setFieldValue(
                                              "type",
                                              "file"
                                            );
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                            </div>
                          ))
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            {headerNavigation.length > 1 &&
                            rowData.is_deleteable === 1 ? (
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="three-dot-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    threeDotEvent(
                                      e,
                                      `three-dot-${rowIndex}-${rowData.folder_name}`
                                    );
                                  }}
                                >
                                  . . .
                                </button>
                                <ul
                                  className="dropdown-menu thee-dot-menu"
                                  id={`three-dot-${rowIndex}-${rowData.folder_name}`}
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setModelHeading("");
                                        setVisible((prev) => !prev);
                                        setModelHeading("Rename");
                                        setDropDownMenu(false);
                                        renameFolderFormik.setFieldValue(
                                          "folder_name",
                                          rowData?.folder_name
                                        );
                                        renameFolderFormik.setFieldValue(
                                          "folder_id",
                                          rowData.id
                                        );
                                      }}
                                    >
                                      Rename
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setModelHeading("");
                                        setVisible((prev) => !prev);
                                        setModelHeading("Delete");
                                        setDropDownMenu(false);
                                        deleteFolderFormik.setFieldValue(
                                          "folder_name",
                                          rowData?.folder_name
                                        );
                                        deleteFolderFormik.setFieldValue(
                                          "folder_id",
                                          rowData.id
                                        );
                                        deleteFolderFormik.setFieldValue(
                                          "type",
                                          "folder"
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="three-dot-button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    threeDotEvent(
                                      e,
                                      `three-dot-${rowIndex}-${rowData.folder_name}`
                                    );
                                    setFolderId(rowData.id);
                                  }}
                                >
                                  . . .
                                </button>
                                <ul
                                  className="dropdown-menu thee-dot-menu"
                                  id={`three-dot-${rowIndex}-${rowData.folder_name}`}
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setModelHeading("");
                                        setVisible((prev) => !prev);
                                        setModelHeading("Folder Permission");
                                        setDropDownMenu(false);
                                        folderPermissionFormik.setFieldValue(
                                          "folder_name",
                                          rowData?.folder_name
                                        );
                                        folderPermissionFormik.setFieldValue(
                                          "folder_id",
                                          rowData.id
                                        );
                                      }}
                                    >
                                      Share
                                    </button>
                                    <button
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        folderDownload(rowData.id);
                                      }}
                                    >
                                      Download
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        )
                      }
                    />
                  </DataTable>
                </div>
              </div>
              <div className="card-footer">
                <div className="col-12 text-right">
                  <button
                    type="button"
                    id="button_1"
                    className="btn btn-secondary mx-1"
                    onClick={() => navigate("/customers")}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Folder;
