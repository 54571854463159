import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../context/Loader/Loader";
import { useLoader } from "../../context/Loader/LoaderProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Link } from "react-router-dom";

const SheathMaterialsEdit = (props) => {
  const { id } = useParams();
  const [SheathMaterialName, setSheathMaterialName] = useState("");
  const { isLoading, setIsLoading } = useLoader();
  const navigate = useNavigate();

  const fetchSheathMaterialData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(`/sheath-material/${id}`);
      if (response.status === 200) {
        const data = response.data.data[0];
        setSheathMaterialName(data?.name || "");
      }
    } catch (error) {
      showErrorToast("Error fetching sheathMaterial data");
      //   navigate('/sheathMaterial');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSheathMaterialData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!SheathMaterialName.trim()) {
      showErrorToast("SheathMaterial Name and SheathMaterial cannot be empty");
      return;
    }

    setIsLoading(true);

    try {
      await axiosPrivate.put(`sheath-material/${id}`, {
        name: SheathMaterialName,
      });
      showSuccessToast("SheathMaterial updated successfully");
      navigate("/sheathMaterial");
    } catch (error) {
      showErrorToast("Error updating sheathMaterial");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className="page-header">
            <h4 className="m-1 pl-3">Edit SheathMaterial</h4>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="d-flex mb-30">
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control mr-3"
                            placeholder="SheathMaterial Name"
                            value={SheathMaterialName}
                            onChange={(e) =>
                              setSheathMaterialName(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Link to="/sheathMaterial">
                          <button
                            type="button"
                            className="btn btn-secondary mr-3"
                          >
                            Close
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SheathMaterialsEdit;
