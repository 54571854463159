const costComponent = {
  flangepipesize: "",
  flangePrice: "",
  flangeTotalCost: "",
  flangeWeight: "",
  vesselWeight: "",
  vesselMaterialCC: "",
  vesselPrice: "",
  vesselTotalPrice: "",
  skinSensor: "",
  skinSensorQuantity: "",
  skinSensorTotalCost: "",
  tubeSensor: "",
  tubeSensorQuantity: "",
  tubeSensorTotalCost: "",
  proceessSensor: "",
  proceessSensorQuantity: "",
  proceessSensorTotalCost: "",
  baffleWeight: "",
  bafflePrice: "",
  baffleTotalCost: "",
  inletNozzlePrice: "",
  outletNozzlePrice: "",
  drainNozzlePrice: "",
  ventNozzlePrice: "",
  processNozzlePrice: "",
  dummyNozzlePrice: "",
  inletLength: "",
  inletPrice: "",
  outletLength: "",
  outletPrice: "",
  drainLength: "",
  drainPrice: "",
  ventLength: "",
  ventPrice: "",
  processLength: "",
  processPrice: "",
  dummyLength: "",
  dummyPrice: "",
  heating_element_price: "",
  MGOPrice: "",
  ElementPrice: "",
  HeatingElementTotalCost: "",
  HeatingElementFixturePrice: "",
  HeatingElementFixtureTotalCost: "",
  TerminalBoxPrice: "",
  connectionPrice: "",
  lugSupport: "",
  insulation: "",
  gasKits: "",
  paint: "",
  other: "",
  atex: "",
};

export default costComponent;
